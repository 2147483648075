import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { ReplyInterface } from 'src/app/shared/types';

@Component({
  selector: 'app-quick-reply',
  templateUrl: './quick-reply.component.html',
  styleUrls: ['./quick-reply.component.scss']
})
export class QuickReplyComponent implements OnInit {

  // replies = [
    // {
    //   "id": 0,
    //   "name": "name1",
    //   "userId": "amuraventures@gmail.com",
    //   "shortcut": "aa",
    //   "value": "stringstringstringstring stringstring stringstring stringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstring"
    // },
    // {
    //   "id": 1,
    //   "name": "name2",
    //   "userId": "amuraventures@gmail.com",
    //   "shortcut": "bb",
    //   "value": "stringstringstringstring stringstring stringstring"
    // },
    // {
    //   "id": 2,
    //   "name": "name3",
    //   "userId": "amuraventures@gmail.com",
    //   "shortcut": "cc",
    //   "value": "stringstringstringstring stringstring stringstring"
    // },
    
    // {
    //   "id": 3,
    //   "name": "name4",
    //   "userId": "amuraventures@gmail.com",
    //   "shortcut": "dd",
    //   "value": "stringstringstringstring stringstring stringstring stringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstring"
    // },
    
    // {
    //   "id": 4,
    //   "name": "name5",
    //   "userId": "amuraventures@gmail.com",
    //   "shortcut": "ee",
    //   "value": "stringstringstringstring stringstring stringstring stringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstringstringstringstringstring stringstring stringstring"
    // },
  // ]
  state: string = 'list'; //edit, create
  keyword: string = '';
  @Input() search = '';
  @Input() replies: ReplyInterface[] = [];
  @Input() deleteReply: Function = new Function();
  constructor(public app: AppService, private api: ApiService) { }

  ngOnInit(): void {

  }
  createReplyMessage() {
    this.app.quickReplyState = 'create';
  }
  cancelReplyMessage() {
    this.app.showQuickReplies = false;
    this.app.searchKeyword = '';
    this.app.quickReplyState = 'list'
  }
  addReplyMessage(message: string) {
    this.app.addReplyMessage(message);
    this.app.showQuickReplies = false;
    this.app.searchKeyword = '';
    this.app.quickReplyState = 'list'    
  }
  handleKeywordChange(event: any) {
    const val = event.target.value;
    if (this.app.quickReplyState === 'list' ) {
      this.app.searchKeyword = val;
    } else {
      this.app.newReply.shortcut = val;
    }
  }

  editReply(reply: any) {
    this.app.quickReplyState = 'edit';
    this.app.newReply = {
      id: reply.id,
      shortcut: reply.shortcut,
      message: reply.value,
      option: 'one'
    }
  }
}
