<section class="main-page" #mainPage>
    <!-- <header class="header">
      <span class="back-icon" (click)="closeMessage()">
      <app-edge-x-logo></app-edge-x-logo>
      </span>
    </header> -->
    <!-- //close button  -->
    <button mat-icon-button class="close-button" 
     (click)="closeMessage()">
    <mat-icon>close</mat-icon>
  </button>

    <div class="wrapper" style="position: relative;">
        <!-- Messages -->
        <div id="loadingDiv" class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        
        <app-message id="messagesDiv" [parentComponent]="this" [messages]="messages" [otherUser]="data" [modalNameToShow]="''" [updateMessage]="updateMessage" [groupList]="groupList" [defaultGroupId]="defaultGroupId" [contactId]="contactId">
        </app-message>

        <!-- Sidebar -->
        <div *ngIf="app.showSidebar === true">
            <app-sidebar-right [otherUser]="data"></app-sidebar-right>
        </div>
    </div>
    <!-- <app-edge-x-logo></app-edge-x-logo> -->

    <!-- <span class="x"> -->
    <!-- <span>
      <button mat-icon-button class="close-button" style="
            background: #f0f2f5;
            z-index: 999999;
            opacity: 1;
          " (click)="closeMessage()">
        <mat-icon>close</mat-icon>
      </button>
    </span> -->
</section>