import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replyfilter'
})
export class ReplyFilterPipe implements PipeTransform {
  transform(values: any[], keyword: string): any[] {
    if (!values || !keyword) {
      return values;
    }
    const filteredValues = values.filter(item => item.shortcut.toLowerCase().startsWith(keyword.toLowerCase()));
    return filteredValues.map(reply => {
        const shortcut = reply.shortcut.toLowerCase();
        const startIndex = shortcut.indexOf(keyword);
        
        if (startIndex > -1) {
          const matchedText = reply.shortcut.substr(startIndex, keyword.length);
          const boldText = `<strong>${matchedText}</strong>`;
          reply.boldText = '/ ' + reply.shortcut.replace(matchedText, boldText);
        }
        
        return reply;
      });
  }
}