import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../shared/api.service';
import { AppService } from '../app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent implements OnInit {
  buttons = [
    { class: 'button', content: '', limit: 150, priceId: 'PLAN-PRO-IA-GL-0924-EUR-Monthly' },
    { class: 'button', content: '', limit: 300, priceId: 'PLAN-PRO-IA-300-LEADS-GL-0924-EUR-Monthly' },
    { class: 'button', content: '', limit: 500, priceId: 'PLAN-PRO-IA-500-LEADS-GL-0924-EUR-Monthly' },
    { class: 'button', content: '', limit: 1000, priceId: 'PLAN-PRO-IA-1000-LEADS-GL-0924-EUR-Monthly' }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SubscriptionModalComponent>,
    private apiService: ApiService,
    public app: AppService
  ) { }

  ngOnInit(): void {
    this.updateContentBasedOnLeadsLimit();
  }

  updateContentBasedOnLeadsLimit(): void {
    const currentLimit = this.data.leadsLimit;
    const currentRole = this.app.role.toLowerCase();
    this.buttons.forEach(button => {
      if (currentRole === 'pro') {
        // For 'pro' role, we are upgrading to 'iaPRO'
        button.class = 'button upgrade';
        button.content = 'Actualizar a iaPRO';
      } else {
        if (button.limit === currentLimit) {
          button.class = 'button active';
          button.content = 'Plan actual';
        } else if (button.limit > currentLimit) {
          button.class = 'button upgrade';
          button.content = 'Subir plan';
        } else {
          button.class = 'button downgrade';
          button.content = 'Bajar plan';
        }
      }
    });
  }  

  closeModal(): void {
    this.dialogRef.close();
  }

  onChangePlanClick(index: number): void {
    const button = this.buttons[index];

    if (button.class === 'button active') {
      return; // Do nothing if it's the current plan
    }

    const usuario = this.apiService.userId;
    const idPrice = button.priceId;
    const encodedUsuario = encodeURIComponent(usuario);
    const encodedIdPrice = encodeURIComponent(idPrice);

    let url = '';
    let confirmationMessage = '';

    if (button.class.includes('upgrade')) {
      url = `https://back.generatorlanding.com/api/APIPaddle/upgradeSub/${encodedUsuario}/${encodedIdPrice}`;
      confirmationMessage = this.app.role.toLowerCase() === 'pro'
        ? 'Estás a punto de actualizar a iaPRO. El cambio se aplicará de forma inmediata. ¿Deseas continuar?'
        : 'Estás a punto de subir de plan. El cambio se aplicará de forma inmediata. ¿Deseas continuar?';
    } else if (button.class.includes('downgrade')) {
      url = `https://back.generatorlanding.com/api/APIPaddle/downgradeSub/${encodedUsuario}/${encodedIdPrice}`;
      confirmationMessage = 'Estás a punto de bajar de plan. El cambio se aplicará al final de tu ciclo de facturación actual. ¿Deseas continuar?';
    }

    Swal.fire({
      title: button.class.includes('upgrade') ? 'Confirmar actualización de plan' : 'Confirmar bajada de plan',
      text: confirmationMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = url;
      }
    });
  }
}
