<div  *ngIf="app.role.toLowerCase() =='proia'" class="sub__modal">
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="title">
      <h3>Aumenta tu plan</h3>
      <h5>Selecciona el plan que mejor se adapte, fácil y sencillo</h5>
    </div>
    <div class="items__wrapper">
      <!-- Plan 1 -->
      <div class="item item_1">
        <div class="head">
          <img src="" alt="">
          <div class="plan">
            <span>iaPRO</span>
            <span>{{ buttons[0].limit }} LEADS</span>
          </div>
        </div>
        <div class="price">
          <span>147€</span>
          <span>/mes</span>
        </div>
        <a [ngClass]="buttons[0].class" (click)="onChangePlanClick(0)">
          {{ buttons[0].content }}
        </a>
      </div>
      <!-- Plan 2 -->
      <div class="item item_2">
        <div class="head">
          <img src="" alt="">
          <div class="plan">
            <span>iaPRO</span>
            <span>{{ buttons[1].limit }} LEADS</span>
          </div>
        </div>
        <div class="price">
          <span>247€</span>
          <span>/mes</span>
        </div>
        <a [ngClass]="buttons[1].class" (click)="onChangePlanClick(1)">
          {{ buttons[1].content }}
        </a>
      </div>
      <!-- Plan 3 -->
      <div class="item item_3">
        <div class="head">
          <img src="" alt="">
          <div class="plan">
            <span>iaPRO</span>
            <span>{{ buttons[2].limit }} LEADS</span>
          </div>
        </div>
        <div class="price">
          <span>447€</span>
          <span>/mes</span>
        </div>
        <a [ngClass]="buttons[2].class" (click)="onChangePlanClick(2)">
          {{ buttons[2].content }}
        </a>
      </div>
      <!-- Plan 4 -->
      <div class="item item_4">
        <div class="head">
          <img src="" alt="">
          <div class="plan">
            <span>iaPRO</span>
            <span>{{ buttons[3].limit }} LEADS</span>
          </div>
        </div>
        <div class="price">
          <span>697€</span>
          <span>/mes</span>
        </div>
        <a [ngClass]="buttons[3].class" (click)="onChangePlanClick(3)">
          {{ buttons[3].content }}
        </a>
      </div>
    </div>
    <!-- <div class="tooltip">
      <img src="/assets/tooltip.svg" alt="tooltip">
      <span>Aquí se puede poner el texto que haga referencia a los ciclos de facturación tanto si hacen upgrade o downgrade</span>
    </div>-->
  </div>
  <div *ngIf="app.role.toLowerCase() =='pro'" class="sub__modal single-plan-modal">
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="title">
      <h3>Actualiza a iaPRO</h3>
      <h5>Descubre las ventajas de iaPRO y mejora tu experiencia</h5>
    </div>
    <div class="items__wrapper single-item">
      <!-- Plan 1 -->
      <div class="item item_1">
        <div class="head">
          <img src="" alt="">
          <div class="plan">
            <span>iaPRO</span>
            <span>{{ buttons[0].limit }} LEADS</span>
          </div>
        </div>
        <div class="price">
          <span>147€</span>
          <span>/mes</span>
        </div>
        <a [ngClass]="buttons[0].class" (click)="onChangePlanClick(0)">
          {{ buttons[0].content }}
        </a>
      </div>
    </div>
  </div>