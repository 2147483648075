<div class="columnconfig-container">
  <div class="columnconfig-title">
    <div class="columnconfig-title-left"><img src="./assets/aiconfig-funnel.svg" alt="" /></div>
    <div class="columnconfig-title-right">
      <div class="columnconfig-title-text"><h3>Hemos configurado estas columnas en el pipeline</h3></div>
      <div class="columnconfig-title-description">Si hay algo que quieres añadir o borrar puedes hacerlo fácilmente</div>
    </div>
  </div>
  <div mat-dialog-content class="columnconfig-content cart-scroll">
    <div *ngFor="let column of configColumns; let i = index" class="columnconfig-itemcontainer">
      <div class="columnconfig-itemcontainer-left">
        <input [(ngModel)]="column.title" placeholder="Title">
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6" [(ngModel)]="column.description" placeholder="Description"></textarea>
      </div>
      <div class="columnconfig-itemcontainer-right">
        <mat-icon (click)="removeColumn(i)">close</mat-icon>
      </div>
    </div>    
    <mat-icon class="columnconfig-addcircle" (click)="addColumn()">add_circle</mat-icon>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button class="columnconfig-submitbutton" mat-button (click)="openFinalizeModal()" cdkFocusInitial mat-raised-button color="primary">SIGUIENTE <mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
</div>