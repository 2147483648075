// shared-data.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SharedDataService {
  private dataSubject = new Subject<any>();
  private unReadMessages:any;

  setUnReadMessages(newValue:Number) {
    this.unReadMessages = newValue;
  }

  getUnReadMessages() {
    return this.unReadMessages;
  }

  sendData(data: any) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.asObservable();
  }
}
