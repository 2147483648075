<form (ngSubmit)="updateBotAPI()" *ngIf="!loading">
  <mat-card class="mat-card-vertical-scroll cart-scroll">
    <mat-card-title>
      <div class="aiconfig-title">
        <div class="aiconfig-title-left">
          <img src="./assets/aiconfig-ai.svg" alt="" />
        </div>
        <div class="aiconfig-title-right">
          <div class="aiconfig-title-titletext">
            <h3 style="padding-right:8px">Configuración del IA BOT</h3>
            <span class="aiconfig-title-toggle">
                <div class="checkbox-wrapper-12">
                  <div class="cbx">
                    <input id="cbx-12" type="checkbox" [(ngModel)]="form.manual" name="manual" (change)="onManualCheckboxChange()"/>
                    <label for="cbx-12"></label>
                    <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                  </div>
              <!-- Gooey-->
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                  <filter id="goo-12">
                    <fegaussianblur in="SourceGraphic" stddeviation="4" result="blur"></fegaussianblur>
                    <fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></fecolormatrix>
                    <feblend in="SourceGraphic" in2="goo-12"></feblend>
                  </filter>
                </defs>
              </svg>
            </div>
            </span>         
            <div class="aiconfig-arrow">
              <img src="./assets/activado-arrow.svg" alt="" />
              <span *ngIf="form.manual">Activado Modo IA</span>
              <span *ngIf="!form.manual">Modo Manual</span>
            </div>
          </div>
          <div class="aiconfig-title-description">
            Vamos a configurar la inteligencia artificial
          </div>
        </div>
        <div class="aiconfig-percent-right">
          <div class="intelligence-level">
            <div class="intelligence-level-title">NIVEL DE INTELIGENCIA</div>
            <div class="intelligence-level-value" 
                 [ngClass]="{
                   'low': intelligenceLevel < 50, 
                   'medium': intelligenceLevel >= 50 && intelligenceLevel <= 70, 
                   'high': intelligenceLevel > 70
                 }">
              {{ intelligenceLevel }}%
            </div>
            <div class="intelligence-level-subtitle">
              <ng-container *ngIf="intelligenceLevel > 70">ALTO NIVEL DE INTELIGENCIA</ng-container>
              <ng-container *ngIf="intelligenceLevel <= 70 && intelligenceLevel >= 50">INTELIGENCIA MEDIA</ng-container>
              <ng-container *ngIf="intelligenceLevel < 50">BAJO NIVEL DE INTELIGENCIA</ng-container>
            </div>
          </div>          
        </div>
      </div>
    </mat-card-title>
    <mat-card-content class="aiconfig-cardoffset" *ngIf="!loading">
      <div class="aiconfig-content-container">
        <div class="aiconfig-leftcol">
          <h3 class="aiconfig-textarea-title">Describe tu negocio y lo que quieres conseguir:</h3>
          <span
          (click)="onEnhance()"
            class="ai-helper-button" 
            matSuffix
            [matMenuTriggerFor]="helperMenu" 
            style="cursor: pointer;" 
            matTooltip="Haz clic para mejorar tu prompt"
            matTooltipPosition="above">
            <svg width="14" height="15" viewBox="0 0 14 15" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg" class="i8gzK v2EZs">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7 0.714966C6.57172 0.714966 6.19841 1.00644 6.09453 1.42193C5.64458 3.22175 5.11525 4.31311 4.3567 5.07167C3.59815 5.83022 2.50678 6.35955 0.706966 6.8095C0.291477 6.91337 -3.32794e-07 7.28669 -3.32794e-07 7.71497C-3.32794e-07 8.14324 0.291477 8.51656 0.706966 8.62043C2.50678 9.07039 3.59815 9.59971 4.3567 10.3583C5.11525 11.1168 5.64458 12.2082 6.09453 14.008C6.19841 14.4235 6.57172 14.715 7 14.715C7.42828 14.715 7.80159 14.4235 7.90547 14.008C8.35542 12.2082 8.88475 11.1168 9.6433 10.3583C10.4019 9.59971 11.4932 9.07039 13.293 8.62043C13.7085 8.51656 14 8.14324 14 7.71497C14 7.28669 13.7085 6.91337 13.293 6.8095C11.4932 6.35955 10.4019 5.83022 9.6433 5.07167C8.88475 4.31311 8.35542 3.22175 7.90547 1.42193C7.80159 1.00644 7.42828 0.714966 7 0.714966Z">
              </path>
            </svg>
            <span>Asistente de Prompt</span>
          </span>
          <mat-menu #helperMenu="matMenu" [hasBackdrop]="true" [overlapTrigger]="false" class="prompt-helper-menu">
            <app-prompt-helper-menu style="width: 100%;"
              [chatMessages]="chatMessages"
              [isProcessingResponse]="isProcessingResponse"
              [currentInstructions]="form.instructions"
              (sendMessage)="onEnhanceInstructions($event)"
              (closeMenuEvent)="helperMenu.closed.emit()">
            </app-prompt-helper-menu>
          </mat-menu>
          <mat-form-field appearance="outline">
            <textarea matInput [(ngModel)]="form.instructions" name="description" rows="6" 
                      (input)="onFormChange(); updateIntelligenceScore()" 
                      [disabled]="!form.manual"></textarea>
          </mat-form-field>
          <div class="toggle-options">
            <mat-expansion-panel *ngIf="userId == 'j.marquez.serra@gmail.com'" [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>assistant</mat-icon>¿No sabes como hacerlo?, usa el Asistente de Prompt"
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="aiconfig-accordion-copy">Aqui sabras como usarlo
                <button type ="button" mat-icon-button class="play-video-button" (click)="$event.stopPropagation(); openYoutubeVideoModal()" matTooltip="Ver tutorial en video" matTooltipPosition="above">
                <mat-icon>play_circle_filled</mat-icon>
                </button>
              </span>
              <div class="aiconfig-textarea-description">
                <strong>Ejemplo:</strong> Gimnasio y centro de bienestar dedicado a ofrecer programas de entrenamiento personalizado y clases grupales para adultos. Puedo ofrecer 1 clase de prueba gratuita. Quiero conseguir que la gente me contacte y resuelva todas sus dudas y que reserve una clase de prueba gratis.
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title><mat-icon>forum</mat-icon>¿Quieres que si alguien no te responde le pueda hacer un seguimiento?</mat-panel-title>
              </mat-expansion-panel-header>
              <span class="aiconfig-accordion-copy">Los seguimientos son recordatorios que se hacen baja el contexto de la conversación. Por ejemplo si alguien no te responde en menos 24horas la IA enviará un recordatorio para incentivar a continuar la conversación.</span>
              <mat-checkbox class="aiconfig-checkbox" [(ngModel)]="form.followUp" name="followUp" (change)="updateIntelligenceScore()" [disabled]="!form.manual">Si activar la función de seguimiento</mat-checkbox>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title><mat-icon>mic</mat-icon>¿Quieres que el bot haga audios?</mat-panel-title>
              </mat-expansion-panel-header>
              <span class="aiconfig-accordion-copy"> La IAbot tiene la capacidad de poder responder con audios y interpretar los audios que se le envian. También de manera aleatoria y natural enriquecer la conversación con audios cortos de manera aleatoria.</span>
              <mat-checkbox class="aiconfig-checkbox" [(ngModel)]="form.respondWithAudio" name="respondWithAudio" (change)="updateIntelligenceScore()" [disabled]="!form.manual">
                Activa la función de responder aleatoriamente con audios
                <mat-icon matTooltip="" matTooltipPosition="above">info</mat-icon>
              </mat-checkbox>
              <mat-checkbox class="aiconfig-checkbox" [(ngModel)]="form.randomAudios" name="audioEnrichment" (change)="updateIntelligenceScore()" [disabled]="!form.manual || !form.respondWithAudio">
                Activar la función de enriquecimiento de audios aleatorios
                <mat-icon matTooltip="" matTooltipPosition="above">info</mat-icon>
              </mat-checkbox>
              <div class="aiconfig-voice-container">
                <div 
                  *ngFor="let voice of app.voicesId" 
                  class="aiconfig-voice-selection" 
                  [class.active]="form.voiceId === voice.id" 
                  [class.disabled]="!form.respondWithAudio"
                  (click)="selectVoice(voice.id)">
                  <mat-icon>graphic_eq</mat-icon> {{ voice.name }}
                </div>
              </div>
              <div class="custom-voice-section">
                <div class="custom-voice-header">
                  <div class="custom-voice-title">
                    <mat-icon>record_voice_over</mat-icon>
                    <span>Voces personalizadas</span>
                  </div>
                  <button [disabled]="!form.manual" type="button" mat-flat-button color="primary" class="add-voice-button" (click)="openAddCustomVoiceDialog()">
                    <mat-icon>add</mat-icon>
                    <span>Añadir voz</span>
                  </button>
                </div>
                
                <div class="custom-voice-info" *ngIf="!app.customVoicesId || app.customVoicesId.length === 0">
                  <mat-icon>info</mat-icon>
                  <div class="info-content">
                    <p>Sube tu propia voz para personalizar la experiencia:</p>
                    <ul>
                      <li>Archivo de audio de 5-8 minutos</li>
                      <li>Formato mp3 o m4a</li>
                      <li>Habla con claridad y naturalidad</li>
                    </ul>
                  </div>
                </div>

                <div class="custom-voice-container">
                  <div *ngFor="let voice of app.customVoicesId" class="voice-row">
                    <div class="aiconfig-voice-selection" 
                        [class.active]="form.voiceId === voice.id" 
                        [class.disabled]="!form.respondWithAudio"
                        (click)="selectVoice(voice.id, true)">
                      <mat-icon>graphic_eq</mat-icon>
                      <span>{{ voice.name }}</span>
                      
                      <button type="button" 
                              class="play-voice-button"
                              (click)="$event.stopPropagation(); togglePlayCustomVoice(voice.id)"
                              [matTooltip]="isPlaying && playingVoiceId === voice.id ? 'Pausar voz' : 'Escuchar voz'"
                              matTooltipPosition="above">
                        <mat-icon>{{ isPlaying && playingVoiceId === voice.id ? 'pause' : 'play_arrow' }}</mat-icon>
                      </button>
                    </div>
                    <button type="button" 
                            mat-icon-button
                            class="delete-voice-button"
                            [disabled]="form.voiceId === voice.id"
                            [matTooltip]="form.voiceId === voice.id ? 'Selecciona otra voz para eliminar esta' : 'Eliminar voz'"
                            matTooltipPosition="above"
                            (click)="attemptDeleteVoice(voice.id)">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <audio #audioPlayer hidden></audio>            
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="aiconfig-cardactions" align="end" *ngIf="!loading">
      <button mat-button type="button" (click)="cancel()">Cancelar</button>
      <button *ngIf="form.manual" type="button" class="aiconfig-savebutton" [ngClass]="{'pulse-animation': isDirty}" mat-raised-button color="primary" (click)="handleSaveConfig()"><span>GUARDAR</span></button>
      <button *ngIf="form.manual" class="aiconfig-submitbutton" mat-raised-button color="primary" type="submit">SIGUIENTE<mat-icon>keyboard_arrow_right</mat-icon></button>
    </mat-card-actions>
  </mat-card>
</form>
<div *ngIf="loading" class="loading-container">
  <div class="loading-indicator">
    
    <img src="./assets/loading.gif" alt="Loading..." class="loading-spinner" />
    <p>Estamos personalizando el pipeline para ti. Esto tomará solo unos segundos…</p>
  </div>
</div>