import {
  Component,
  EventEmitter,
  ViewChild,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { extraMessage, ReplyInterface } from 'src/app/shared/types';
import { ImageDialogueComponent } from '../image-dialogue/image-dialogue.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MainViewComponent } from '../main-view.component';
import { DateTimeSelectorComponent } from 'src/app/date-time-selector/date-time-selector.component';
import { ReplyTemplatesComponent } from '../../reply-templates/reply-templates.component';

// import { Chat } from 'src/app/models/chat';
// import { Message } from 'src/app/models/message';
// import { User } from 'src/app/models/user';
// import { AuthService } from 'src/app/services/auth-service/auth.service';

const MAX_LENGTH = 10;
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {
  @Input() messages: any = null;
  @Input() updateMessage: Function = new Function();
  @Input() mentionChange: Function = new Function();
  @Input() modalNameToShow: string | undefined;
  @Input() otherUser: any = null; // the user you are chatting,depends on selectedChat
  @Input() groupList: any = [];
  @Input() defaultGroupId: any = null;
  @Input() contactId: any = null;
  @Output() groupomEvent = new EventEmitter<any>();
  @Input() parentComponent!: MainViewComponent;

  @ViewChild('input', { static: true }) textareaRef: ElementRef | undefined;
  @ViewChild('messageslistContainer') listContainer!: ElementRef;
  private scrollInterval: any;

  userSubscription: Subscription | undefined;
  textMsg: string = '';
  infoText: string = 'Clica para más información';
  isEmojiShown: boolean = false;
  isAudioRecordShown: boolean = false;
  participantId: any = null;
  conversationType:any = null;
  replies: ReplyInterface[] = [];
  extra: extraMessage[] = [];
  fileView: boolean = true;
  cancelIds: string[] = [];
  uploadedBlobType:any;
  isConversationLocked: boolean = false;
  manual = false;
  constructor(
    private dialogRef: MatDialogRef<MessageComponent>,
    private dialog: MatDialog,
    public app: AppService,
    private api: ApiService,
    private sanitizer : DomSanitizer
  ) {}

  checkLockState(): void {
    if (this.otherUser?.contactId?.conversationType === 'whatsappApi') {
      const lastUpdateDate = new Date(this.otherUser.contactId.LastMessageReceivedOn);
      const currentDate = new Date();
      const diffHours = Math.abs(currentDate.getTime() - lastUpdateDate.getTime()) / 36e5;
      this.isConversationLocked = diffHours >= 24;
    }
  }

  toggleAI(isAIActivated: boolean) {
    this.manual = !isAIActivated;
    this.onCheckboxChange();
  }

  onCheckboxChange() {
    
    this.otherUser.contactId.manual = this.manual;
    this.api.sendPostRequest({
      action: 'Action/UpdateLeadBotAPI',
      body: {
        id: this.otherUser.contactId.id,
        manual: this.manual
      }
    }).subscribe({
      next: (res) => {

      },
      error: (error) => {
      }
    });
    }

  ngOnInit(): void {
    // this.userSubscription = this.authService.currentUser$.subscribe((user) => {
    //   this.currentUser = user;
    // });
    this.app.showReplyTemplates = false;
    this.checkLockState();
    this.participantId = this.otherUser.contactId.participantId;
    this.conversationType = this.otherUser.contactId.conversationType;
    this.manual = this.otherUser.contactId.manual;
    this.app.clearReplyState();
    this.app.addReplyEventEmitter.subscribe((msg) => {
      this.addReplyMessage(msg);
    });
    this.app.addTemplateEventEmitter.subscribe((msg) => {
      this.addTemplateMessage(msg);
    });
    this.app.notifyMessageView.subscribe(()=>{
      this.isConversationLocked = false;
    })
    this.api
      .sendGetRequest({ action: 'Template/Get', params: {} })
      .subscribe((res) => {
        // this.replies = res.filter((item:any)=>item.name === this.otherUser.name || item.name === this.otherUser.contactId.id  );
        this.replies = res;
        // setTimeout(() => {
        //   const listEl = document.querySelector('.list-messages');
        //   listEl?.scrollTo({ top: listEl.scrollHeight, behavior: 'smooth' });
        // }, 200);
    });
    this.app.scrollToButtomEvent.subscribe(() => {
      this.scrollToBottom();
    });
    
    this.app.showSidebar=true;
  }

  loadVideoInstead(message: any) {
    message.useVideo = true;
  }

  ngAfterViewChecked(): void {
    if (this.app.scrollheight<this.listContainer.nativeElement.scrollHeight)
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
      this.listContainer.nativeElement.scrollTop = this.listContainer.nativeElement.scrollHeight;
      this.app.scrollheight = this.listContainer.nativeElement.scrollHeight;
  }
  
  deleteReply(id: string) {
    this.api
      .sendPostRequest({ action: 'Template/Delete', params: { id: id } })
      .subscribe((res) => {
        this.replies = this.replies.filter((reply) => reply.id !== id);
      });
  }
  addEmoji(ev: any) {
    this.textMsg += ev.emoji.native;
  }
  focusOut() {
    
  }

  showEmoji() {
    this.isEmojiShown = !this.isEmojiShown;
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.app.scrollheight = 0;
    this.app.stillLoading = false;
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  }

  showSearchMessage() {
    // this.onSelectModal.emit('search-message');
  }

  showContactInfo(contactId: number | undefined) {
    this.dialogRef.close();
  }

  handleEnterPress(event: Event) {
    event.preventDefault();
  
    // Set a timeout to wait for 100ms before calling onSend
    setTimeout(() => {
      this.onSend();
    }, 250);
  }
  

  sendTextMessage() {
    var that = this;
    const txtmsg = this.textMsg;
    if(this.conversationType=='instagram' || this.conversationType =="messenger")
    {
      this.api
      .sendPostRequest({
        action: 'Message/SendInsta',
        params: {
          participantId: this.participantId,
          message: this.textMsg,
        },
      })
      .subscribe((res) => {
      });
      let currentUtcTimeString = this.convertLocal(Date.now());
      this.updateMessage({
        timestamp: currentUtcTimeString,
        id: 'true_' + this.otherUser.contactId.id + '@c.us_' + Date.now(),
        body: this.app.wrapLinksWithAnchorTag(this.textMsg),
        fromMe: true,
        type: 1,
        fileDownloadUrl: null,
      });
    }
    else
    {
      if(this.conversationType=='whatsapp')
      {
      this.api
      .sendPostRequest({
        action: 'Message/SendTextMessage',
        params: {
          text: txtmsg,
          contactId: that.otherUser.contactId.id
        },
      })
      .subscribe((res) => {
        
        var id=res.fromMe+"_"+res.remote+"_"+res.id;
        let currentUtcTimeString = this.convertLocal(Date.now());
        this.updateMessage({
          timestamp: currentUtcTimeString,
          id: id,
          body: this.app.wrapLinksWithAnchorTag(txtmsg),
          fromMe: true,
          type: 1,
          fileDownloadUrl: null,
        });
      });
    }
      else
      {
        const action = !this.isConversationLocked ? 'Message/SendMessageApi' : 'Message/SendMessageTemplateApi';
       var templateName = "";
        try{
        templateName = this.app.templates?.find(template => template.templateText == txtmsg).templateName;
       }
       catch{
       }
        this.api
        .sendPostRequest({
        action: action,
        body: {
          text: txtmsg,
          leadId: that.otherUser.contactId.id,
          templateName: templateName
        },
      })
      .subscribe((res) => {
      });
      }
      let currentUtcTimeString = this.convertLocal(Date.now());
      this.updateMessage({
        timestamp: currentUtcTimeString,
        id: currentUtcTimeString,
        body: this.app.wrapLinksWithAnchorTag(txtmsg),
        fromMe: true,
        type: 1,
        fileDownloadUrl: null,
      });
    }
      this.otherUser.contactId.unreadMessages = 0 ;
      this.textMsg = ''
  }

  async onRescheduleSend() {
    const dialogRef = this.dialog.open(DateTimeSelectorComponent, {
      width: '300px'
    });
    
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const date = new Date(result);    
        const sendDate = this.convertLocalToUTC(date);
        const nowDateUtc = this.convertLocalToUTC(new Date());
        
        const platform = this.conversationType;
        const generatedId = this.generateId();
        const message = this.textMsg; 
        var messageType = 0;
        var recipientId = "";
        const lastMessageId = this.app.allConversationsMessages[this.contactId][this.app.allConversationsMessages[this.contactId].length - 1].id;
        if(platform=='instagram' || platform =="messenger")
          recipientId = this.participantId;
        else
          recipientId = this.otherUser.contactId.id;

        if (this.extra.length === 0) {
          if (this.textMsg === '') return;
          messageType = 1;
          await this.api
          .sendPostRequest({
              action: 'Message/RescheduleSend',
              body: {
                recipientId: recipientId,
                platform: platform,
                messageType: messageType,
                message: message,
                createdDate: nowDateUtc,
                sendDate: sendDate,
                lastMessageId: lastMessageId,
                convId: this.otherUser.contactId.id,
                generatedId: generatedId
              },
          })
          .toPromise();
          let currentUtcTimeString = this.convertLocal(Date.now());
          this.updateMessage({
            timestamp: currentUtcTimeString,
            id: generatedId,
            body: this.app.wrapLinksWithAnchorTag(this.textMsg),
            fromMe: true,
            type: 1,
            fileDownloadUrl: null,
            style: 'rescheduled',
            rescheduled: true,
            sendDate: sendDate
          });
          this.textMsg = '';
        } 
        else {
          const that = this;
          const currentAttachments = [...this.extra];
          this.extra = [];
          this.textMsg = "";
          const newIdPrefix = Date.now();
          let currentUtcTimeString = this.convertLocal(Date.now());
          currentAttachments.forEach(function(item, index) {
            var urlData = item.data;
            if(item.type === 3)
                urlData = that.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(item.data));
            if(message != "")
            {
              that.updateMessage({
                timestamp: currentUtcTimeString,
                id: generatedId,
                body: that.app.wrapLinksWithAnchorTag(message),
                fromMe: true,
                type: 1,
                fileDownloadUrl: null,
                style: 'rescheduled',
                rescheduled: true,
                sendDate: sendDate
              });
            }
            that.updateMessage({
                timestamp: currentUtcTimeString,
                id: 'true_' + that.otherUser.contactId.id + '@c.us_' + newIdPrefix + index,
                body: '',
                caption: '',
                fromMe: true,
                type: item.type,
                fileDownloadUrl: urlData,
                uploading: true,
                style: 'rescheduled',
                rescheduled: true,
                sendDate: sendDate
            });
          });
          if(message != "")
          {
          messageType = 1; 
          await this.api
          .sendPostRequest({
              action: 'Message/RescheduleSend',
              body: {
                recipientId: recipientId,
                platform: platform,
                messageType: messageType,
                message: message,
                createdDate: nowDateUtc,
                sendDate: sendDate,
                lastMessageId: lastMessageId,
                convId: this.otherUser.contactId.id,
                generatedId: generatedId
              },
          })
          .toPromise();
          this.textMsg = "";
          }
          for (const [index, item] of currentAttachments.entries()) {
            try {
                const id = 'true_' + that.otherUser.contactId.id + '@c.us_' + newIdPrefix + index;  
                let file = item.data;
                messageType = item.type;
                if (item.type === 2) {
                    file = await that.getFileFromDataURL(item.data);
                }
                if (that.cancelIds.includes(id)) continue;
                this.app.ConversationUploadingVideo[this.otherUser.contactId.id] = true;
                const fileUrl = await that.fileUpload(file);
                
                that.updateMessage({ uploading: false }, id);
                if (fileUrl) {
                  await this.api
                  .sendPostRequest({
                      action: 'Message/RescheduleSend',
                      body: {
                        recipientId: recipientId,
                        platform: platform,
                        messageType: messageType,
                        message: "",
                        createdDate: nowDateUtc,
                        sendDate: sendDate,
                        lastMessageId: lastMessageId,
                        fileName: file.name,
                        fileUrl: fileUrl,
                        convId: this.otherUser.contactId.id,
                        generatedId: id
                      },
                  })
                  .toPromise();
                  that.updateMessage({ uploading: false, fileDownloadUrl: fileUrl, fileName: file.name  }, id);
                }
            } catch (error) {
                
            }
        }
        }
      }
    });
  }

  async sendAttachMessage() {
    const that = this;
    const currentAttachments = [...this.extra]; // Copy current attachments
    this.extra = []; // Clear extra for new attachments upload
    const newIdPrefix = Date.now();
    let currentUtcTimeString = this.convertLocal(Date.now());
    currentAttachments.forEach(function(item, index) {
        var urlData = item.data;
        if(item.type === 3 || item.type === 4)
            urlData = that.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(item.data));
        that.updateMessage({
            timestamp: currentUtcTimeString,
            id: 'true_' + that.otherUser.contactId.id + '@c.us_' + newIdPrefix + index,
            body: '',
            caption: currentAttachments.length === index + 1 ? that.textMsg : '',
            fromMe: true,
            type: item.type,
            fileDownloadUrl: urlData,
            uploading: true,
        });
    });
    
    const textMessage = this.textMsg;
    const participantId = this.participantId;
    this.textMsg = '';

    // Upload and send attachments
    for (const [index, item] of currentAttachments.entries()) {
        try {
            var realId = null;
            const id = 'true_' + that.otherUser.contactId.id + '@c.us_' + newIdPrefix + index;  
            let file = item.data;
            if (item.type === 2) {
                file = await that.getFileFromDataURL(item.data);
            }
            if (that.cancelIds.includes(id)) continue;
            this.app.ConversationUploadingVideo[this.otherUser.contactId.id] = true;
            const fileUrl = await that.fileUpload(file);
            if (that.cancelIds.includes(id)) continue;
            if (fileUrl) {
              if(this.conversationType=='instagram' || this.conversationType =="messenger")
              {

                await that.api.sendPostRequest({
                  action: 'Message/SendMediaInsta',
                  body: {
                      name: that.otherUser.contactId.id,
                      fileUrl: fileUrl,
                      participantId: this.participantId,
                      message: currentAttachments.length === index + 1 ? textMessage : '',
                      type: item.type,
                      fileName: file.name
                  },
                }).toPromise();
              }
              else
              {
                if(this.conversationType=='whatsapp')
                {
                  realId = await that.api.sendPostRequest({
                    action: 'Message/SendMedia',
                    body: {
                      name: that.otherUser.contactId.id,
                      fileUrl: fileUrl,
                      message: currentAttachments.length === index + 1 ? textMessage : '',
                      type: item.type,
                      fileName: file.name,
                      mimetype: this.uploadedBlobType
                    },
                  }).toPromise();
                  this.app.ConversationUploadingVideo[this.otherUser.contactId.id] = false;

                  if (realId) {
                    var fullid=realId.fromMe+"_"+realId.remote+"_"+realId.id;
                    that.updateMessage({
                        uploading: false, 
                        fileDownloadUrl: fileUrl, 
                        fileName: file.name,
                        id: fullid
                    }, id);
                    
                  }
                }
                else
                {
                  const action = !this.isConversationLocked ? 'Message/SendMessageApi' : 'Message/SendMessageTemplateApi';
                  await that.api.sendPostRequest({
                    action: action,
                    body: {
                      leadId: that.otherUser.contactId.id,
                      url: fileUrl,
                      text: currentAttachments.length === index + 1 ? textMessage : '',
                      type: item.type,
                    },
                  }).toPromise();
                  this.app.ConversationUploadingVideo[this.otherUser.contactId.id] = false;

                }
              }
            }
            that.updateMessage({ uploading: false, fileDownloadUrl: fileUrl, fileName: file.name }, id);
        } catch (error) {
            
        }
    }
    if((this.conversationType=='instagram' || this.conversationType =="messenger") && textMessage != null && textMessage != "" )
    {
        this.api
          .sendPostRequest({
              action: 'Message/SendInsta',
              params: {
              participantId: participantId,
              message: textMessage,
          },
          })
          .subscribe((res) => {
          });
    }
    this.otherUser.contactId.unreadMessages = 0 ;
  }
   
  createNewReply() {
    this.api
      .sendPostRequest({
        action: 'Template/Create',
        body: {
          id: '0',
          shortcut: this.app.newReply.shortcut,
          value: this.app.newReply.message,
          name: this.otherUser.contactId.id,
        },
      })
      .subscribe((res) => {
        this.replies.push({
          id: res,
          shortcut: this.app.newReply.shortcut,
          value: this.app.newReply.message,
          name: this.otherUser.contactId.id,
          userId: this.api.userId,
        });
        this.app.clearReplyState();
        this.app.showQuickReplies = true;
        this.app.searchKeyword = '';
        this.app.quickReplyState = 'list';
      });
  }
  updateReply() {
    this.api
      .sendPostRequest({
        action: 'Template/Update',
        params: {
          id: this.app.newReply.id,
          shortcut: this.app.newReply.shortcut,
          value: this.app.newReply.message,
          name: this.otherUser.contactId.id,
        },
      })
      .subscribe((res) => {
        let cReply: any = this.replies.find(
          (item: any) => item.id === this.app.newReply.id
        );
        if (cReply) {
          cReply.shortcut = this.app.newReply.shortcut;
          cReply.value = this.app.newReply.message;
        }
        this.app.showQuickReplies = true;
        this.app.searchKeyword = '';
        this.app.quickReplyState = 'list';
      });
  }

  async onSend() {
    this.app.showReplyTemplates = false;
    if (this.app.quickReplyState === 'list') {
      if (this.extra.length === 0) {
        if (this.textMsg === '') return;
        this.app.lastMessagesWritedLocally.push({
          message: this.textMsg,
          timestamp: Date.now()
        });
       this.sendTextMessage();
      } else {
        this.sendAttachMessage();
      }
    } else {
      if (this.app.quickReplyState === 'create') {
        this.createNewReply();
      } else {
        this.updateReply();
      }
    }
  }

  generateId(length = 20) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return `id_${result}`;
  }

  private convertLocalToUTC(date: Date): string {
    return new Date(date.toISOString()).toISOString();
  }
  
  cancelUploading(id: string) {
    this.cancelIds.push(id);
    this.updateMessage({ delete: true }, id);
  }

  handleMessageChange(event: any) {
    if (this.app.quickReplyState !== 'list') {
      this.app.newReply.message = event.target.value;
    }
    const textareaEle = this.textareaRef?.nativeElement;
    textareaEle.style.height = 'auto'; // Reset the height to auto
    textareaEle.style.height = textareaEle.scrollHeight + 'px';
  }

  addReplyMessage(msg: string) {
    console.group(this.textMsg, msg, this.app.searchKeyword);
    if (this.app.searchKeyword === '') {
      if (this.textMsg.endsWith('/')) {
        let str = this.textMsg.slice(0, -1);
        this.textMsg = str + msg;
      } else {
        this.textMsg += msg;
      }
    } else {
      if (this.textMsg.includes('/' + this.app.searchKeyword)) {
        this.textMsg = this.textMsg.replace('/' + this.app.searchKeyword, msg);
      } else {
        this.textMsg += msg;
      }
    }
  }
  addTemplateMessage(msg: string) {
    this.textMsg = "";
    this.textMsg = msg;
  }
  showAddFile(id: string) {
    const fileInput = document.getElementById(id);
    fileInput?.click();
  }



  async handleFileUpload(event: any, isVideo: boolean = false) {
    const files = event.target.files;
    const fileArr = Array.prototype.slice.call(files);
    const that = this;
    for (let i = 0; i < fileArr.length; i++) {
      if (that.extra.length > MAX_LENGTH) {
        
      } else {
        const file = fileArr[i];
        const type = this.getTypeFromExtension(file.type);
        if (type === 2) {
          const reader = new FileReader();
          reader.onload = function (e) {
            if (e.target?.result) {
              const ind =
                that.extra.length === 0
                  ? 0
                  : that.extra[that.extra.length - 1]['ind'] + 1;
              that.extra.push({
                type: 2,
                data: e.target.result,
                ind: ind,
              });
            }
          };
          reader.onerror = function (e) {
            console.error(
              'Error occurred while reading the file:',
              e.target?.error
            );
          };
          reader.readAsDataURL(file);
        } else {
          const ind =
            that.extra.length === 0
              ? 0
              : that.extra[that.extra.length - 1]['ind'] + 1;

          that.extra.push({
            type: type ,
            data: file,
            ind: ind,
          });
        }
      }
    }
    event.target.value = '';
  }
  getTypeFromExtension(ext: string) {
    let type = 4;
    if (ext.startsWith('video/')) type = 3;
    else if (ext.startsWith('audio/')) type = 5;
    else if (ext.startsWith('image/')) type = 2;
    else type = 4;
    return type;
  }

  removeFile(ind: number) {
    this.extra = this.extra.filter((item) => item.ind !== ind);
  }

  showAudioRecord() {
    this.isAudioRecordShown = true;
  }

  endAudioRecord(blob?: Blob) {
    this.isAudioRecordShown = false;
  }

  getFileFromDataURL(dataURL: string) {
    const parts = dataURL.split(',');
    const metadata = parts[0];
    const mimeType = metadata.slice(5, metadata.length - 7);
    const data = parts[1];

    const isBase64 = metadata.includes(';base64');
    const decodedData = isBase64 ? atob(data) : decodeURIComponent(data);

    const dataArray = new Uint8Array(decodedData.length + 1);
    for (let i = 0; i < decodedData.length; i++) {
      dataArray[i] = decodedData.charCodeAt(i);
    }
    const file = new Blob([dataArray], { type: mimeType || 'image/png' });
    return file;
  }

  checkFileValidity(file: any) {
    // Check the MIME type
    if (!file.type.startsWith('image/')) {
      
      return false;
    }

    // Check the file size (adjust the minSize and maxSize according to your requirements)
    const minSize = 1; // Minimum file size in bytes
    const maxSize = 1024 * 1024 * 1024 * 1024; // Maximum file size in bytes

    if (file.size < minSize || file.size > maxSize) {
      
      return false;
    }

    // Open and display the file (Example: displaying an image)
    const fileURL = URL.createObjectURL(file);
    const imgElement = document.createElement('img');
    imgElement.onload = function () {
      // File successfully loaded and displayed
      
      URL.revokeObjectURL(fileURL); // Clean up object URL
    };
    imgElement.onerror = function () {
      
      URL.revokeObjectURL(fileURL); // Clean up object URL
    };
    imgElement.src = fileURL;

    return true;
  }

  fileUpload(blob: any) {
    return new Promise((resolve, reject) => {
      this.uploadedBlobType = blob.type;
      let extension = '';
      if (blob.name) {
        extension = blob.name.split('.').pop();
      } else {
        extension = blob.type.split('/')[1];
      }
      const formData = new FormData();
      formData.append('file', blob);
      formData.append('apiKey', Date.now() + '');
      formData.append('contactId', this.otherUser.contactId.id);
      formData.append('extension', extension);
      
      
      this.api
        .sendFormDataRequest({ action: 'File/upload', formData: formData })
        .subscribe((res) => {
          if (res) {
            
            resolve(res);
          } else {
            
            reject('Server connection error!');
          }
        });
    });
  }

  changeEmoji(emoji: string) {
    this.api
      .sendPostRequest({
        action: 'Contact/saveContact',
        params: { phone: this.otherUser.contactId.id, emoji: emoji, contactType: this.conversationType},
      })
      .subscribe((res) => {
        this.otherUser.contactId.emoji = emoji;
      });
  }

  markAsRead() {
    this.api
      .sendPostRequest({
        action: 'Message/MarkAsRead',
        params: { contactId: this.otherUser.contactId.id,conversationType: this.conversationType },
      })
      .subscribe((res) => {
      });
      this.otherUser.contactId.unreadMessages = 0;
    }
  
  //function to convert UTC time to local after loading messages
  convertUtcToLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }

  //function to convert local time while sending messages
  convertLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }

  //call image dialog component for big size
  openImageDialog(imageData: string): void {
    this.dialog.open(ImageDialogueComponent, {
      data: imageData,
      panelClass: 'custom-dialog-container',
    });
  }
}