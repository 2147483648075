import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-logo',
  templateUrl: './video-logo.component.html',
  styleUrls: ['./video-logo.component.scss']
})
export class VideoLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
