import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContactCartComponent } from './contact-cart/contact-cart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { CreateGroupComponent } from './create-group/create-group.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { MentionModule } from 'angular-mentions';
import { ToastrModule } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MainViewComponent } from './main-view/main-view.component';
import { MessageComponent } from './main-view/message/message.component';
import { SearchLogoComponent } from './main-view/svgs/search-logo/search-logo.component';
import { MenuLogoComponent } from './main-view/svgs/menu-logo/menu-logo.component';
import { EdgeGreenMsgLogoComponent } from './main-view/svgs/edge-green-msg-logo/edge-green-msg-logo.component';
import { EdgeXLogoComponent } from './main-view/svgs/edge-x-logo/edge-x-logo.component';
import { EmojiLogoComponent } from './main-view/svgs/emoji-logo/emoji-logo.component';
import { AttachLogoComponent } from './main-view/svgs/attach-logo/attach-logo.component';
import { VoiceLogoComponent } from './main-view/svgs/voice-logo/voice-logo.component';
import { SendLogoComponent } from './main-view/svgs/send-logo/send-logo.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SidebarRightComponent } from './main-view/sidebar-right/sidebar-right.component';
import { BackLogoComponent } from './main-view/svg/back-logo/back-logo.component';
import { DeleteLogoComponent } from './main-view/svg/delete-logo/delete-logo.component';
import { ArrowRightLogoComponent } from './main-view/svgs/arrow-right-logo/arrow-right-logo.component';
import { ScheduleLogoComponent } from './main-view/svgs/schedule-logo/schedule-logo.component';
import { VideoLogoComponent } from './main-view/svgs/video-logo/video-logo.component';
import { FastLogoComponent } from './main-view/svgs/fast-logo/fast-logo.component';
import { QuickReplyComponent } from './main-view/quick-reply/quick-reply.component';
import { ReplyFilterPipe } from './shared/reply-filter.pipe';
import { MediaRecorderComponent } from './main-view/media-recorder/media-recorder.component';
import { ConvertDatePipe } from './shared/convert-date.pipe';
import { DatePipe } from '@angular/common';
import { ImageLogoComponent } from './main-view/svgs/image-logo/image-logo.component';
import { MusicLogoComponent } from './main-view/svgs/music-logo/music-logo.component';
import { PrevLogoComponent } from './main-view/svgs/prev-logo/prev-logo.component';
import { FileVideoLogoComponent } from './main-view/svgs/file-video-logo/file-video-logo.component';
import { DisconnectLogoComponent } from './main-view/svgs/disconnect-logo/disconnect-logo.component';
import { SharedDataService } from './services/shared-data.service';
import { GroupListComponent } from './main-view/svgs/group-list/group-list.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './shared/confirm-dialog/confirm-dialog.service';
import { ImageDialogueComponent } from './main-view/image-dialogue/image-dialogue.component';
import { HelpLogoComponent } from './shared/help-logo/help-logo.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { ConnectionModalComponent } from './shared/connection-modal/connection-modal.component';
import { InitConnectionModalComponent } from './shared/init-connection-modal/init-connection-modal.component';
import { DateTimeSelectorComponent } from './date-time-selector/date-time-selector.component';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import { WhatsappApiConfigComponent } from './whatsapp-api-config/whatsapp-api-config.component';
import { ColumnConfigModalComponent } from './column-config-modal/column-config-modal.component';
import { BotFinalizeModalComponent } from './bot-finalize-modal/bot-finalize-modal.component';
import { VideoModalComponent } from './video-modal/video-modal.component';
import { SubscriptionModalComponent } from './subscription-modal/subscription-modal.component';
import { StopStimulationDialogComponent } from './stop-stimulation-dialog/stop-stimulation-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { StartStimulationDialogComponent } from './start-stimulation-dialog/start-stimulation-dialog.component';
import { ReplyTemplatesComponent } from './reply-templates/reply-templates.component';
import { AddCustomVoiceComponent } from './add-custom-voice/add-custom-voice.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PromptHelperMenuComponent } from './shared/components/prompt-helper-menu/prompt-helper-menu.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { YoutubeVideoModalComponent } from './youtube-video-modal/youtube-video-modal.component';

const routes: Routes = [

];

@NgModule({
  declarations: [
    AppComponent,
    //Component
    ContactCartComponent,
    CreateGroupComponent,
    MainViewComponent,
    MessageComponent,
    SidebarRightComponent,
    QuickReplyComponent,
    //Icon
    SearchLogoComponent,
    MenuLogoComponent,
    EdgeGreenMsgLogoComponent,
    EdgeXLogoComponent,
    EmojiLogoComponent,
    AttachLogoComponent,
    VoiceLogoComponent,
    SendLogoComponent,
    BackLogoComponent,
    DeleteLogoComponent,
    ArrowRightLogoComponent,
    ScheduleLogoComponent,
    VideoLogoComponent,
    FastLogoComponent,
    ReplyFilterPipe,
    MediaRecorderComponent,
    ConvertDatePipe,
    ImageLogoComponent,
    MusicLogoComponent,
    PrevLogoComponent,
    FileVideoLogoComponent,
    DisconnectLogoComponent,
    GroupListComponent,
    ConfirmDialogComponent,
    ImageDialogueComponent,
    HelpLogoComponent,
    ConnectionModalComponent,
    InitConnectionModalComponent,
    DateTimeSelectorComponent,
    WhatsappApiConfigComponent,
    ColumnConfigModalComponent,
    BotFinalizeModalComponent,
    VideoModalComponent,
    SubscriptionModalComponent,
    StopStimulationDialogComponent,
    StartStimulationDialogComponent,
    ReplyTemplatesComponent,
    AddCustomVoiceComponent,
    PromptHelperMenuComponent,
    AddCustomVoiceComponent,
    ConfirmationDialogComponent,
    YoutubeVideoModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatDividerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    HttpClientModule,
    NgxSpinnerModule,
    PickerModule,
    MentionModule,
    MatExpansionModule,
    MatToolbarModule,
    MatTooltipModule,
    ToastrModule.forRoot(),
    MatCardModule,
    BrowserAnimationsModule,
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatMenuModule,
    MatProgressSpinnerModule
  ],
  providers: [NgxSpinnerService, DatePipe, SharedDataService, ConfirmDialogService,  {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {
}