<section class="container">
    <div class="reply-title">
      <h4>Gestionar plantilla</h4>
      <mat-icon (click)="cancelReplyMessage()" class="clear-icon">clear</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="reply-body">
      <ng-container *ngIf="templates.length > 0">
        <div class="reply-item" *ngFor="let template of templates">
          <div class="reply-item-body" (click)="addReplyMessage(template)">
            <span class="template-text">{{ template.templateText }}</span>
            <span class="template-status"
              [ngClass]="{
                'approved': template.status === 'APPROVED',
                'pending': template.status === 'PENDING',
                'rejected': template.status === 'REJECTED',
                'unavailable': template.status === 'UNAVAILABLE'
              }">
              {{ statusLabels[template.status] }}
      </span>
          </div>
        </div>
      </ng-container>
      <div *ngIf="templates.length === 0">
        <p>No hay resultados.</p>
      </div>
    </div>
  </section>  