import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-reply-templates',
  templateUrl: './reply-templates.component.html',
  styleUrls: ['./reply-templates.component.scss']
})
export class ReplyTemplatesComponent implements OnInit {
  templates: any;
  statusLabels: { [key: string]: string } = {
    APPROVED: 'Aprobado',
    PENDING: 'Pendiente',
    REJECTED: 'Rechazado',
    UNAVAILABLE: 'No disponible'
  };  

  constructor(public app: AppService) { }

  ngOnInit(): void {
    this.templates = this.app.templates;
  }
  
  cancelReplyMessage() {
    this.app.showReplyTemplates = false;
    this.app.addTemplateMessage("Selecciona una plantilla.");
  }
  
  addReplyMessage(template: any) {
    if (template.status === 'APPROVED') {
      this.app.addTemplateMessage(template.templateText);
    }
  }  
}
