import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-stop-stimulation-dialog',
  templateUrl: './stop-stimulation-dialog.component.html',
  styleUrls: ['./stop-stimulation-dialog.component.scss']
})
export class StopStimulationDialogComponent {

  constructor(public dialogRef: MatDialogRef<StopStimulationDialogComponent>) {}

  stopOnly(): void {
    this.dialogRef.close('stop');
  }

  stopAndDelete(): void {
    this.dialogRef.close('delete');
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
