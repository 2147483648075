<div class="modal-backdrop" (click)="closeModal()"></div>
<div class="modal">
  <div class="modal-content">
    <span class="close" (click)="closeModal()">&times;</span>
    
    <div class="modal-header">
      <h2>Como configurar el pipeline con WhatsApp API</h2>
    </div>
    
    <div class="video-container">
      <!-- Embed Vimeo video -->
      <iframe src="https://player.vimeo.com/video/1020639020" 
              width="100%" height="360" frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowfullscreen>
      </iframe>
    </div>
    <div>
      <h5>En este vídeo te voy a enseñar a vincular tu WhatsApp API al Pipeline PRO.</h5>
      <h4>Recuerda esto siempre: </h4>
    </div>
    <div class="modal-footer">
      <p>La SIM o eSIM tiene que ser exclusiva solo para esto. No puede estar
        vinculada a ningún WhatsApp, si no, no va a funcionar.</p>
      <p>Es posible que se quede pensando, si esto llegase a ocurrir tendrás que ir a la barra de navegación a permitir pantalla emergente.</p>
    </div>
  </div>
</div>