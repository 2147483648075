import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

const weekArray: any = {
  Yesterday: 'Ayer',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miercoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  Sunday: 'Domingo',
};

@Pipe({
  name: 'convertDate',
})
export class ConvertDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string): string {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const sevenDaysAgo = new Date(now);
    sevenDaysAgo.setDate(now.getDate() - 7);
    
//convert into local time
    const lastTime = new Date(value);
    const timestamp: number = Date.parse(lastTime.toISOString());
    const lastUpdatedTime = this.convertLocal(timestamp);

    if (
      lastUpdatedTime >= sevenDaysAgo &&
      lastUpdatedTime.toDateString() !== now.toDateString()
    ) {
      if (lastUpdatedTime.toDateString() === yesterday.toDateString()) {
        return 'Ayer';
      } else {
        const dayOfWeek =
          weekArray[
            lastUpdatedTime.toLocaleDateString('en-US', { weekday: 'long' })
          ];
        return dayOfWeek || '';
      }
    } else if (lastUpdatedTime.toDateString() === now.toDateString()) {
      return this.datePipe.transform(lastUpdatedTime, 'h:mm a', 'es') || '';
    } else {
      return this.datePipe.transform(lastUpdatedTime, 'M/d/yyyy', 'es') || '';
    }
  }

  convertLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }
}
