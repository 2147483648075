<div class="card" [style.background-color]="card.grouping.color || '#d35bd8'">

  <div class="card-header">
    <!--<ng-container *ngIf="isEditTitle">
      <input type="text" [(ngModel)]="title" class="card-title" />
      <div class="action-group">
        <div class="action-edit" (click)="saveTitle()">
          <svg class="material-icons" viewBox="0 0 16 16" height="0.8em" width="0.8em">
            <path
              d="M10.97 4.97a.75.75 0 011.07 1.05l-3.99 4.99a.75.75 0 01-1.08.02L4.324 8.384a.75.75 0 111.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 01.02-.022z" />
          </svg>
        </div>
        <div class="action-edit action-cancel" (click)="cancelEditTitle()">
          <svg class="material-icons" viewBox="0 0 470 1000" height="0.8em" width="0.8em">
            <path
              d="M452 656c12 12 18 26.333 18 43s-6 31-18 43c-12 10.667-26.333 16-43 16s-31-5.333-43-16L234 590 102 742c-12 10.667-26.333 16-43 16s-31-5.333-43-16C5.333 730 0 715.667 0 699s5.333-31 16-43l138-156L16 342C5.333 330 0 315.667 0 299s5.333-31 16-43c12-10.667 26.333-16 43-16s31 5.333 43 16l132 152 132-152c12-10.667 26.333-16 43-16s31 5.333 43 16c12 12 18 26.333 18 43s-6 31-18 43L314 500l138 156" />
          </svg>
        </div>
      </div>
    </ng-container>-->

    <ng-container *ngIf="isEditTitle">
      <mat-icon class="lock-icon" *ngIf="cardIndex=== 0">lock_outline</mat-icon>
      <span class="card-title">{{ card.grouping.name }}</span>
      <div class="action-group">
        <div class="action-edit" (click)="editBox(card.grouping)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" height="22px" width="22px">
            <path
              d="M20.548,3.452a1.542,1.542,0,0,1,0,2.182L12.912,13.27,9.639,14.361l1.091-3.273,7.636-7.636A1.542,1.542,0,0,1,20.548,3.452ZM4,21H19a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v7H5V6h7a1,1,0,0,0,0-2H4A1,1,0,0,0,3,5V20A1,1,0,0,0,4,21Z"
              fill="white" />
          </svg>
        </div>
        <div class="action-edit action-delete" (click)="deleteGroup()" *ngIf="conversations.length === 0">
          <mat-icon>delete</mat-icon>
        </div>
      </div>
    </ng-container>
    <div class="card-plus" (click)="createBox(card.grouping)">
      <svg class="card-plus-icon" viewBox="0 0 1024 1024" fill="currentColor">
        <path
          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
      </svg>
    </div>
  </div>
  <div class="action-group2">
    <div class="action-group1 action-group1_mr">
      <span class="group-amount-label">{{ this.conversations.length }} leads</span>
      <span class="group-amount-label">Hay {{ card.grouping.groupAmount | number : '1.0-0' }}
        {{app.selectedSymbol}}</span>
    </div>
    <div class="action-group1" *ngIf="isEditTitle">
      <span class="btn" [ngClass]="{ disabled: card.grouping.position == 1 || card.grouping.position == 2 }"
        (click)="updatePosition(-1, card.grouping)">
        <i class="list-item__icon isax isax-arrow-left-14 fz28"></i>
      </span>
      <!--<input type="color" [(ngModel)]="color" (change)="updateColor()" />-->
      <span class="btn"
        [ngClass]="{ disabled: card.grouping.position == 1 || card.grouping.position == columnLength   }"
        (click)="updatePosition(1, card.grouping)">
        <i class="list-item__icon isax isax-arrow-right-41 fz28"></i>
      </span>
    </div>
  </div>

  <div class="card-body cart-scroll" cdkDropList [cdkDropListData]="card.grouping.id"
    (cdkDropListDropped)="dropFunction($event)">
    <!-- Поле для ввода поиска -->

    <ng-container *ngFor="let contact of filteredConversations">
      <div>
        <div (cdkDragStarted)="dragFunction($event)" cdkDrag class="contact-item"
          [ngClass]="{ 'highlight-contact': contact.unreadMessages > 0, 'testing-background': contact.testing, ' contact-item-colorful' : app.isWhatsappAPIConnected && !app.appConfig.manual && !contact.manual}"
          *ngIf="(app.showUnread && contact.unreadMessages > 0) || !app.showUnread"
          (click)="showMainView(contact, card?.grouping?.id)" [attr.data-id]="contact.id">
          <div class="contact-avatar">
            <span class="badge" *ngIf="contact.unreadMessages > 0">
              {{contact.unreadMessages}}
            </span>
            <img [src]="contact.thumbnailUrl || './assets/guest.png'" />
            <img
              [src]="contact.conversationType === 'whatsapp' ? 'assets/whatsapp-icon.svg' : contact.conversationType === 'instagram' ? 'assets/instagram-icon.svg' :
                            contact.conversationType === 'messenger' ? 'assets/messenger-icon.svg' : 'assets/busniess.png'"
              class="conversation-service-icon" />
          </div>
          <div class="contact-name">
            <span class="contact-name-text" [class.blurred]="app.showCheck" *ngIf="app.showCheck">
              <span class="fi fi-{{ contact.countryCode | lowercase }}"></span>
              {{ contact.name || contact.phoneFormatted }}
            </span>
            <span class="contact-name-text" *ngIf="!app.showCheck">
              <span *ngIf="contact.conversationType=='whatsapp' || contact.conversationType=='whatsappApi'"
                class="fi fi-{{ contact.countryCode | lowercase }}"></span>
              <img *ngIf="contact.conversationType=='instagram'" class="worldwide-icon"
                src="assets/worldwide-icon.png" />
              <span [ngClass]="{
                'contact-name-label-whatsapp': contact.conversationType=='whatsapp',
                'contact-name-label': contact.conversationType=='instagram'
              }">{{ contact.name || contact.phoneFormatted }}</span>
            </span>
            <span class="contact-last-message" *ngIf="contact.lastMessage != null">{{
              (contact.lastMessage?.type === 1 || contact.lastMessage?.type === 0)
              ? contact.lastMessage.body
              : "Compartió " + typeLabel[contact.lastMessage?.type]}}
            </span>
          </div>
          <div class="emoji-container">
            <span class="emoji-icon" *ngIf="contact.emoji">{{contact.emoji}}</span>
          </div>
          <div class="contact-star">
            <span>
              {{ contact.lastUpdate | convertDate }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
  <div class="card-footer"></div>
</div>