<svg
  viewBox="0 0 10 21"
  height="21"
  width="10"
  preserveAspectRatio="xMidYMid meet"
  class=""
  version="1.1"
  x="0px"
  y="0px"
  enable-background="new 0 0 10 21"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M1,15.75l5.2-5.2L1,5.35l1.5-1.5l6.5,6.7l-6.6,6.6L1,15.75z"
  ></path>
</svg>
