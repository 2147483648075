<div class="youtube-modal-container">
  <div class="modal-header">
    <h2>Tutorial: Cómo usar el Asistente de Prompt</h2>
    <button class="close-button" (click)="closeModal()" aria-label="Cerrar">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="video-container">
    <iframe 
      [src]="safeVideoUrl" 
      width="100%" 
      height="315" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      allowfullscreen>
    </iframe>
  </div>
  
  <div class="modal-footer">
    <p>Este video te muestra paso a paso cómo utilizar el Asistente de Prompt para configurar tu IA BOT de manera efectiva.</p>
  </div>
</div>
