import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-video-modal',
  templateUrl: './youtube-video-modal.component.html',
  styleUrls: ['./youtube-video-modal.component.scss']
})
export class YoutubeVideoModalComponent implements OnInit {
  safeVideoUrl: SafeResourceUrl;

  constructor(
    private dialogRef: MatDialogRef<YoutubeVideoModalComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { videoUrl: string }
  ) {
    this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.videoUrl);
  }

  ngOnInit(): void { }

  closeModal(): void {
    this.dialogRef.close();
  }

  private getYoutubeVideoId(url: string): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : '';
  }
}
