<svg
  viewBox="0 0 8 13"
  height="13"
  width="8"
  preserveAspectRatio="xMidYMid meet"
  class=""
  version="1.1"
  x="0px"
  y="0px"
  enable-background="new 0 0 8 13"
  xml:space="preserve"
>
  <path
    opacity="0.13"
    fill="#0000000"
    d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"
  ></path>
  <path
    fill="#d9fdd3"
    d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"
  ></path>
</svg>
