<section class="contact-info">
  <header class="header">
      <!-- Logo icon-back:  -->
      <span class="back-icon" (click)="goBack()">
          <app-back-logo></app-back-logo>
      </span>
      <h3 class="contactoH3Tag">Contacto</h3>
  </header>

  <div class="container">
      <!-- Image: -->
      <div class="img-container">
          <div class="image-icon-container">
              <img [src]="otherUser?.contactId.thumbnailUrl || './assets/guest.png'" alt="" />
              <img [src]="this.otherUser?.contactId.conversationType === 'whatsapp' ? 'assets/whatsapp-icon.svg' :
               this.otherUser?.contactId.conversationType === 'instagram' ? 'assets/instagram-icon.svg' :
               this.otherUser?.contactId.conversationType === 'whatsappApi' ? 'assets/busniess.png' :
               'assets/messenger-icon.svg'" class="conversation-service-icon"/>

          </div>
          <div>
              <h3 class="name-container">
                <span *ngIf="otherUser?.contactId.conversationType=='whatsapp' || otherUser?.contactId.conversationType=='whatsappApi'" class="fi fi-{{ otherUser?.contactId.countryCode | lowercase }}"></span>
                <img *ngIf="otherUser?.contactId.conversationType=='instagram'" class="worldwide-icon" src="assets/worldwide-icon.png"/>
                &nbsp;
                <span [ngClass]="{'contact-name-label': otherUser?.contactId.conversationType=='instagram'}">
                {{ otherUser?.contactId.name }}
                </span>
              </h3>
              <p [class.blurred]="app.showCheck" *ngIf="app.showCheck">{{ otherUser?.contactId.phoneFormatted }}</p>
              <p *ngIf="!app.showCheck">{{ otherUser?.contactId.phoneFormatted }}</p>
          </div>
      </div>
      <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                  <mat-panel-title class="lead-card-title">
                      Etiquetas
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="tag-body">
                  <div class="tag-add">
                      <input type="text" [(ngModel)]="newTag" new="newTag" />
                      <span class="tag-add-icon" (click)="addTag()">
                          <mat-icon>add</mat-icon>
                      </span>
                  </div>
                  <div class="tag-list">
                      <div *ngFor="let tag of tags" class="tag">
                          <span>{{tag}}</span>
                          <mat-icon (click)="deleteTag(tag)">clear</mat-icon>
                      </div>
                  </div>
              </div>
          </mat-expansion-panel>
          
          <mat-card class="lead-labeling-content lead-amount">
            <mat-card-header class="lead-status-header">
                <mat-card-title class="lead-card-title">
                  Valor del Lead
                  <div class="pt-2">
                    <div class="lead-card-content" *ngIf="!editMode">
                      {{ leadValue | number : '1.0-0' }} {{app.selectedSymbol}}
                        <mat-icon class="edit-icon" (click)="toggleEditMode(true)">edit</mat-icon>
                    </div>
                    <div class="lead-card-input" *ngIf="editMode">
                      <input #leadValueInput type="number" class="custom-number-input" [(ngModel)]="leadValue" (keyup.enter)="toggleEditMode(false)" *ngIf="editMode" /> {{app.selectedSymbol}}
                      <mat-icon class="edit-icon" (click)="toggleEditMode(false)">check</mat-icon>
                    </div>
                  </div>
                </mat-card-title>
            </mat-card-header>
          </mat-card>

          <mat-card class="lead-labeling-content lead-status">
            <mat-card-header class="lead-status-header">
                <mat-card-title class="win-card-title">
                  <div>
                    Lead ganado
                  </div>
                    <div class="checkbox-wrapper-3">
                      <input type="checkbox" id="cbx-3" [(ngModel)]="leadWon" (change)="onLeadWonChange()" checked />
                      <label for="cbx-3" class="toggle"><span></span></label>
                    </div>
                  
                </mat-card-title>
            </mat-card-header>
          </mat-card>

          <mat-card class="message-listing">
            <mat-card-header>
                <mat-card-title>Mensajes Programados</mat-card-title> <!--Rescheduled Messages-->
            </mat-card-header>
            <mat-card-content>
                <div *ngFor="let message of filteredMessages" class="rescheduled-message-item">
                    <div style="display: flex; justify-content: end; align-items: start; ">
                      <span (click)="cancelRescheduledMessage(message.id)" matTooltip="Cancelar" matTooltipPosition="above">
                        <img class="trash-btn" style="width: 17px" src="./assets/trash-bin.png" />
                      </span>
                    </div>
                    <div class="message-details">
                        <!-- Text message -->
                        <p *ngIf="message.type === 1" class="message-content">{{ message.body }}</p>
                        
                        <!-- Image -->
                        <img *ngIf="message.type === 2 && message.fileDownloadUrl" [src]="message.fileDownloadUrl" alt="Image" class="message-image">
        
                        <!-- Video -->
                        <video *ngIf="message.type === 3 && message.fileDownloadUrl" controls>
                            <source [src]="message.fileDownloadUrl" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                        <div *ngIf="message.type === 4 && message.fileDownloadUrl" class="file">
                          <div class="file-image-container">
                            <img class="file-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC"/>
                              <a [href]="message.fileDownloadUrl" class="download" download target="_blank">
                              <span class="uploading-icon"><mat-icon>download</mat-icon></span>
                              </a>
                          </div>
                      </div>     
                        <!-- Audio -->
                        <audio *ngIf="message.type === 5 && message.fileDownloadUrl" controls>
                            <source [src]="message.fileDownloadUrl" type="audio/ogg">
                            Your browser does not support the audio element.
                        </audio>
        
                        <!-- Caption if available -->
                        <p *ngIf="message.caption" class="message-caption">{{ message.caption }}</p>
                        
                        <p class="reschedule-date">Programado para: {{ message.sendDate | date:'mediumDate' }}, {{ message.sendDate | date:'shortTime' }}</p> <!--Rescheduled for-->
                    </div>
                  </div>
                </mat-card-content>
        </mat-card>

      </mat-accordion>
  </div>
</section>