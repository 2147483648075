<div class="preview" *ngIf="status==='done'">
    <span class="delete-icon" (click)="delete()">
        <mat-icon>delete</mat-icon>
    </span>
    <audio controls="" class="ng-star-inserted">
        <source type="audio/ogg" [src]="blobUrl"> 
        Your browser does not support the audio element. 
    </audio>
    <span class="send-icon" (click)="rescheduleSend()">
        <app-schedule-logo></app-schedule-logo>
    </span>
    <span class="send-icon" (click)="send()">
        <app-send-logo></app-send-logo>
    </span>
</div>
<div class="container" *ngIf="status!=='done'">
    <span class="mic-icon" [ngClass]="{'active':status==='recording'}">
        <mat-icon>mic</mat-icon>
    </span>
    <span>{{formatTime(recordingTime)}}</span>
    <span  *ngIf="status==='start' || status==='pause'" (click)="start()" style="display: flex; align-items: center;">
       <img src="../../../assets/record.png" style="width: 20px; height: 20px;" alt="">
    </span>
    <span class="control-icon" *ngIf="status==='recording'" (click)="pause()">
        <mat-icon>pause</mat-icon>
    </span>
    <span class="control-icon" *ngIf="status!=='start'" (click)="done()">
        <mat-icon>done</mat-icon>
    </span>
    <span class="control-icon" (click)="clear()">
        <mat-icon>clear</mat-icon>
    </span>
</div>