<mat-card class="custom-voice-card">

  <mat-card-content class="mat-card-container">
    <form>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nombre de la Voz</mat-label>
        <input matInput [(ngModel)]="voiceName" name="voiceName" required>
      </mat-form-field>
      <div class="file-upload-container">
        <label class="file-upload-label">
          <input type="file" hidden (change)="onFileSelected($event)" accept=".mp3,.m4a">
          <span class="upload-icon">
            <mat-icon>upload</mat-icon>
          </span>
          <span class="upload-text">
            Arrastra el archivo o haz clic para seleccionar
          </span>
        </label>
        <div *ngIf="selectedFile" class="file-preview">
          <mat-icon class="success-icon">check_circle</mat-icon>
          <span>{{ selectedFile.name }}</span>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions align="end">
    <button mat-button type="button" (click)="dialogRef.close()">Cancelar</button>
    <button mat-raised-button color="primary" type="button" (click)="uploadVoice()" [disabled]="loading">
      <ng-container *ngIf="!loading">Subir</ng-container>
      <ng-container *ngIf="loading">
        <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
      </ng-container>
    </button>
  </mat-card-actions>
  
</mat-card>