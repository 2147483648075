import { Injectable, EventEmitter } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { CardType } from './shared/types';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './shared/api.service';

interface ComponentDetails {
  text: string;
  type: string;
}

interface Template {
  name: string;
  language: string;
  category: string;
  status: string;
  components: ComponentDetails[];
}
@Injectable({
  providedIn: 'root',
})


export class AppService {
  config = {
    mentionTriggerChar: '/',
  };
  cards: CardType[] = [];
  userId : string = "";
  allConversationsMessages: { [contactId: string]: any[] } = {};
  allConversationsFetchToken: { [contactId: string]: string } = {};
  allRequestCount: { [contactId: string]: number } = {};
  showQuickReplies = false;
  quickReplyState = 'list';
  templates: Template[] = [];
  conversationSyncMessage = true;
  isInitModalOpened: boolean = false;
  stillLoading: boolean = false;
  newReply = {
    id: '',
    shortcut: '',
    message: '',
    option: '0',
  };
  showSidebar: boolean = false;
  showUnread: boolean = false;
  showCheck: boolean = false;
  isAudioDisabled: boolean = true;
  isConversationsLoaded: boolean = false;
  selectedSymbol: string = '$';
  totalAmount: number = 0;
  totalProfit: number = 0;
  totalLeads: number = 0;
  scrollheight: number = 0;
  groupingUpdated: boolean = false;
  isWhatsappConnected: boolean = false; 
  isFacebookConnected: boolean = false;
  isInstagramConnected: boolean = false;
  isWhatsappAPIConnected: boolean = false;
  whatsappApiNumber = "";
  appConfig: any = null;
  phoneNumbers:any=null;
  initiatedFromConnectionModal: boolean = false;
  leadsLimit = 150;
  apiLeadsCount = 0;
  apiAllLeadsCount = 0;
  selectedPhoneNumberId: string = '';
  showWhatsappApiAccordion = false;
  botId = 0;
  voicesId: { id: string; name: string }[] = [];
  localMessagesList: string[] = [];
  conversationFullyLoaded: { [contactId: string]: boolean } = {};
  conversationInitiallyLoaded: { [contactId: string]: boolean } = {};
  ConversationUploadingVideo: { [contactId: string]: boolean } = {};
  conversationTyping: { [contactId: string]: boolean } = {};
  conversationTypingCount: { [contactId: string]: number } = {};
  conversationLoadedInView: { [contactId: string]: boolean } = {};
  allowGroupUpdate: boolean = true;
  lastMessagesWritedLocally: { message: string; timestamp: number; }[] = [];
  role: string = "Pro";
  whatsappNumbersFetched = new Subject<void>(); // Subject to notify when numbers are fetched
  public isMainViewOpen: boolean = false;
  public addReplyEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  public connectionIsUpdated: EventEmitter<any> = new EventEmitter<any>();
  public showLoadingEvent: EventEmitter<any> = new EventEmitter<any>();
  public facebookConnectedEvent: EventEmitter<boolean> = new EventEmitter();
  public scrollToButtomEvent: EventEmitter<any> = new EventEmitter();
  public modalCloseEvent: EventEmitter<any> = new EventEmitter();
  public checkIsConnectedEvent: EventEmitter<any> = new EventEmitter();
  public markAsReadEvent: EventEmitter<any> = new EventEmitter();
  public showSpinnerEvent: EventEmitter<any> = new EventEmitter();
  public refreshConversations: EventEmitter<any> = new EventEmitter();
  private searchQuerySource = new BehaviorSubject<string>('');
  constructor(
    private toastr: ToastrService,
    private api: ApiService
  ) { }

  searchKeyword: string = '';

  searchQuery$ = this.searchQuerySource.asObservable();
  updateSearchQuery(query: string) {
    this.searchQuerySource.next(query.toLowerCase());
  }
  notifyFacebookStatusChange(status: boolean) {
    this.facebookConnectedEvent.emit(status);
  }
  loadMoreMetaConversation(conversationId: string, platform: string) {
    if (this.allConversationsFetchToken[conversationId] != "") {
      this.api
        .sendGetRequest({ action: 'Message/GetMoreMetaMessages', params: { conversationId: conversationId, nextFetchToken: this.allConversationsFetchToken[conversationId], platform: platform } })
        .subscribe((res) => {
          res.messages.forEach((message: any) => {
            message.timestamp = this.convertLocal(message.timestamp);
            message.style = "default";
          });
          this.allConversationsFetchToken[conversationId] = res.nextFetch;
          this.allConversationsMessages[conversationId] = [...res.messages, ...this.allConversationsMessages[conversationId]];
          this.stillLoading = true;
          if (res.nextFetch === "") this.conversationFullyLoaded[conversationId] = true;
        });
    }
  }

  closeSession() {
    this.showSpinnerEvent.emit();
    localStorage.clear();
    this.api
      .sendPostRequest({ action: 'Action/CloseSession' })
      .subscribe((res) => {
        
        localStorage.clear();
        this.isWhatsappConnected = false;
        window.location.reload();
      });
  }

  convertLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }

  facebookLogout() {
    localStorage.clear();
    this.showLoadingEvent.emit();
    this.api
      .sendGetRequest({ action: 'Action/CloseFacebook' })
      .subscribe((res) => {
        localStorage.clear();
        this.isFacebookConnected = false;
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  whatsappLogout() {
    localStorage.clear();
    this.showLoadingEvent.emit();
    this.api
      .sendGetRequest({ action: 'Action/Close' })
      .subscribe((res) => {
        localStorage.clear();
        this.isWhatsappConnected = false;
        setTimeout(() => {


          
          window.location.reload();
        }, 500);
      });
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  }
  showSweetAlert() {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result: any) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your imaginary file is safe :)', 'error');
      }
    });
  }
  mentionChange(event: string) {
    
    if (this.quickReplyState === 'list') {
      if (event.includes(' ')) {
        this.searchKeyword = '';
        this.showQuickReplies = false;
      } else {
        this.searchKeyword = event;
      }
    }
  }
  closedMention(event: any) {
  }
  openedMention(event: any) {
    if (this.quickReplyState === 'list') {
      this.searchKeyword = '';
      this.showQuickReplies = true;
    }
  }

  getWhatsppNumbers() {
    this.api
      .sendGetRequest({ action: 'Action/GetWhatsappNumberById' })
      .subscribe((res) => {
        this.phoneNumbers = res;
        this.getSelectedWhatsapp();
        console.log('Phone Numbers Fetched:', res);
        this.whatsappNumbersFetched.next(); // Notify subscribers that numbers are fetched
      });
  }

  getSelectedWhatsapp() {
    this.api
      .sendGetRequest({ action: 'Action/GetSelectedWhatsapp' })
      .subscribe((res) => {
        this.selectedPhoneNumberId = res;
        console.log("getSelected phone number id ",this.selectedPhoneNumberId);
      });
  }
  addReplyMessage(msg: string) {
    this.addReplyEventEmitter.emit(msg);
  }
  clearReplyState() {
    this.quickReplyState = 'list';
    this.showQuickReplies = false;
    this.searchKeyword = '';
    this.newReply = {
      id: '',
      shortcut: '',
      message: '',
      option: '0',
    };
  }

  showSpinner() {
    this.toastr.success('Hello world', 'Toastr fun!');
  }
  hideSpinner() {
    
  }
  showErrorToastr(error: string) {
    this.toastr.error(error);
  }
  handleShowUnread(e: any) {
    this.showUnread = e;
  }
  handleBlur(e: any) {
    
    this.showCheck = e;
    // let cssBlur = document.getElementsByClassName(
    //   'contact-name-text'
    // ) as HTMLCollectionOf<HTMLElement>;
    // for (let i = 0; i < cssBlur.length; i++) {
    //   if (e == true) {
    //     cssBlur[i].style.opacity = '0.5';
    //   } else {
    //     cssBlur[i].style.opacity = '1';
    //   }
    // }
  }
  wrapLinksWithAnchorTag(msg: string) {
    const urlRegex = /((?:https?:\/\/|www\.)[^\s]+)/g;
    const emailRegex = /([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})/g;
    const boldRegex = /\*(.*?)\*/g;

    const alreadyWrappedCheck = /<a [^>]+class="wrapped-link"[^>]*>/;

    if (alreadyWrappedCheck.test(msg)) {
      return msg;
    }

    const wrappedLinksAndEmails = msg
      .replace(
        urlRegex,
        (link: string) =>
          `<a href="${link.startsWith('http') ? link : 'http://' + link}" target="_blank" class="wrapped-link">${link}</a>`
      )
      .replace(
        emailRegex,
        (email: string) => `<a href="mailto:${email}" class="wrapped-link">${email}</a>`
      )
      .replace(
        boldRegex,
        (match: string, text: string) => `<b>${text}</b>`
      );

    return wrappedLinksAndEmails;
  }
}