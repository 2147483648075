import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioPreloadService {
  private preloadedAudioMap: Map<number, HTMLAudioElement> = new Map();

  // Call this for each custom voice you want to preload
  preload(voiceId: number, voiceUrl: string): void {
    if (this.preloadedAudioMap.has(voiceId)) return;

    const audio = new Audio();
    audio.src = voiceUrl; 
    audio.preload = 'auto';
    // Optionally: audio.load();
    this.preloadedAudioMap.set(voiceId, audio);
  }

  getAudioElement(voiceId: number): HTMLAudioElement | undefined {
    return this.preloadedAudioMap.get(voiceId);
  }
}