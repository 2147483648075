<div class="dialog-container">
    <h2>Iniciar Estimulación</h2>
    <div style="padding: 0 15px;">
      <mat-form-field appearance="fill">
        <mat-label>Descripción del Negocio</mat-label>
        <textarea
          matInput
          [(ngModel)]="businessDescription"
          placeholder="Ingrese la descripción de su negocio aquí"
          rows="8"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="button-container">
      <button mat-button (click)="onCancel()">Cancelar</button>
      <button mat-raised-button color="primary" (click)="onStart()">Iniciar</button>
    </div>
  </div>  