<div class="date-time-selector">
  <h2>Seleccionar fecha y hora</h2>
  <mat-form-field appearance="fill" class="date-picker">
    <mat-label>Selecciona la fecha</mat-label>
    <input matInput [matDatepicker]="datePicker" [(ngModel)]="date">
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>

  <div class="time-picker">
    <mat-form-field appearance="fill">
      <mat-label>Selecciona la hora</mat-label>
      <div class="time-inputs">
        <input matInput #hourInput type="number" (input)="onHourChange()" placeholder="HH" min="1" max="12">
        <span>:</span>
        <input matInput #minuteInput type="number" (click)="onMinuteClick($event)" (input)="onMinuteChange()" placeholder="MM" min="0" max="59">
      </div>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-select [(ngModel)]="period" placeholder="AM/PM">
        <mat-option value="AM">AM</mat-option>
        <mat-option value="PM">PM</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  
  <button mat-raised-button color="primary" (click)="confirmDateTime()">Confirmar</button>
</div>
