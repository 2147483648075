import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-logo',
  templateUrl: './search-logo.component.html',
  styleUrls: ['./search-logo.component.scss']
})
export class SearchLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
