import {
  CdkDragDrop,
  CdkDragStart,
  copyArrayItem,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AppService } from './app.service';
import { MessageService } from './shared/message.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { AES, enc } from 'crypto-js';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AudioPreloadService } from './shared/audio-preload.service';
import { ApiService } from './shared/api.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { timer, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateGroupComponent } from './create-group/create-group.component';
import { CardType } from './shared/types';
import { MainViewComponent } from './main-view/main-view.component';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from './services/shared-data.service';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { HelpLogoComponent } from './shared/help-logo/help-logo.component';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { SignalRService } from './services/signalr.service';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { retry } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { LeadUpdateService } from 'src/app/services/lead-update.service';
import { ConnectionModalComponent } from './shared/connection-modal/connection-modal.component';
import { InitConnectionModalComponent } from './shared/init-connection-modal/init-connection-modal.component';
import { WhatsappApiConfigComponent } from './whatsapp-api-config/whatsapp-api-config.component';
import { SubscriptionModalComponent } from './subscription-modal/subscription-modal.component';
import { StopStimulationDialogComponent} from './stop-stimulation-dialog/stop-stimulation-dialog.component';
import { StartStimulationDialogComponent } from './start-stimulation-dialog/start-stimulation-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'trello';
  //cards: CardType[] = [];
  dialogWidth = '90%';
  inited: boolean = false;
  scrollPosition = {};
  cardScrollPosition: any = {};
  groupList: any = [];
  facebookConversationsIdsList: any = [];
  instagramConversationsIdsList: any = [];
  whatsappApiConversationsIdsList:{ id: string, lastUpdate: string }[] = [];
  whatsappConversationsIdsList: { id: string, lastUpdate: string }[] = [];
  search: string = '';
  filteredData: any = [];
  waitRequest: boolean = false;
  isEditTitle: boolean = false;
  newData: any = {};
  inactivityTimer: any;
  inactivityDuration = 30000;
  private updateGroupListInactivityTimer: any;
  private readonly inactivityDurationForGroupUpdate = 300000; // 5 minutes in milliseconds
  isUserInactive: boolean = false;
  modoPrivadoActive: boolean = false;
  noLeidosActive: boolean = false;
  selectedCurrency: string = 'USD';
  requestCount: number = 0;
  tripleMultiplier = 3;
  initialLoad = false;
  stimulation = false;
  conversationsCallCount = 1;
  testingConv:any = [];
  showDate: boolean = false;
  remainingDays: number=0;
  readCountList: { conversationId: string, count: number }[] = [];
  private lastGetConversationsTime: number = 0;
  private recentMessagesCache = new Map<string, { message: any, unreadCount: number, timestamp: number, amount: number }>();
  dragByThisDevice: boolean = false; // Variable to store interval ID
  selectedFlag: string = 'assets/flags/usd.svg';
  currencies = [
    { code: 'USD', flag: 'assets/flags/usd.svg', symbol: '$' },
    { code: 'EUR', flag: 'assets/flags/euro.svg', symbol: '€' },
    { code: 'BRL', flag: 'assets/flags/brazil.svg', symbol: 'R$' },
    { code: 'ARS', flag: 'assets/flags/argentina.svg', symbol: '$' },
    { code: 'VEF', flag: 'assets/flags/venezuela.svg', symbol: 'Bs' },
    { code: 'COP', flag: 'assets/flags/colombia.svg', symbol: '$' },
    { code: 'USD', flag: 'assets/flags/ecuador.svg', symbol: '$' },
    { code: 'CLP', flag: 'assets/flags/chile.svg', symbol: '$' },
    { code: 'PEN', flag: 'assets/flags/peru.svg', symbol: 'S/.' },
    { code: 'MXN', flag: 'assets/flags/mexico.svg', symbol: '$' },
  ];
  contactsIdList: any[] = [];

  private viewportSubscription: Subscription = new Subscription();
  private currentContactId: any;
  private audio: HTMLAudioElement;
  constructor(
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    public app: AppService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private viewportRuler: ViewportRuler,
    private sharedDataService: SharedDataService,
    public signalRService: SignalRService,
    private leadUpdateService: LeadUpdateService,
    private audioPreloadService: AudioPreloadService
  ) {
    this.audio = new Audio();
    this.audio.src = "../assets/beep/notification-sound.wav";
    this.audio.volume = 0.3;
    this.audio.load();
  }
  LastMessagesWritedyClearing() {
    setInterval(() => {
      const currentTime = Date.now();
      const durationToKeepMessage = 60000; // 2 seconds in milliseconds
      this.app.lastMessagesWritedLocally = this.app.lastMessagesWritedLocally.filter((item) => {
        return currentTime - item.timestamp < durationToKeepMessage;
      });
    }, 120000); // Check every 1 second to keep the UI updated
  }

  onSearch(event: any) {
    const searchValue = event.target.value;
    this.app.updateSearchQuery(searchValue);
  }

  getLastPaymentDate() {
    this.api.sendGetRequest({ action: 'Action/GetSubPaymentDate' })
      .subscribe((dateText) => {
        if (dateText === "empty") {
          this.showDate = false;
        } else {
          try
          {
          this.showDate = true;
          const adjustedDateText = dateText.trim().replace(/^"|"$/g, '');
          const nextPaymentDate = new Date(adjustedDateText);
          const today = new Date();
          const timeDiff = nextPaymentDate.getTime() - today.getTime();
          this.remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          if (this.remainingDays < 0) {
            this.remainingDays = 0;
          }
        }
        catch(error)
        {
          this.showDate = false;
          console.log(error);
        }
        }
      });
  }

  getSelectedCurrency() {
    this.selectedCurrency = "USD";
    this.api.sendGetRequest({ action: 'Contact/getUserCurrencyPreference' })
      .subscribe((selectedCurrency) => {
        if (selectedCurrency != null && selectedCurrency.length > 0) {
          this.selectedCurrency = selectedCurrency;
          const selectedCurrencyObj = this.currencies.find(currency => currency.code === this.selectedCurrency);
          if (selectedCurrencyObj != null && selectedCurrencyObj?.flag != null)
            this.selectedFlag = selectedCurrencyObj?.flag;
          if (selectedCurrencyObj != null && selectedCurrencyObj?.symbol != null)
            this.app.selectedSymbol = selectedCurrencyObj?.symbol;
        }
      });
  }
  onSelectChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const selectedValue = target.value;

    if (selectedValue === 'modo-privado') {
      this.toggleModoPrivado();
      this.app.showUnread = false;
    } else if (selectedValue === 'no-leidos') {
      this.toggleNoLeidos();
      this.app.showCheck = false;
    } else {
      this.app.showCheck = false;
      this.app.showUnread = false;
      this.modoPrivadoActive = false;
      this.noLeidosActive = false;
    }
  }

  onCurrencyChange(newCurrency: string) {
    const selectedCurrencyObj = this.currencies.find(currency => currency.code === newCurrency);
    if (selectedCurrencyObj) {
      this.selectedCurrency = newCurrency;
      this.selectedFlag = selectedCurrencyObj.flag;
      this.app.selectedSymbol = selectedCurrencyObj.symbol;
      this.api.
        sendPostRequest
        ({
          action: 'Contact/saveUserCurrencyPreference',
          params: { currencyCode: this.selectedCurrency },
        })
        .subscribe(() => {

        });
    } else {
      
    }
  }
  moveConversationToNewGroup(conversationId: string, newGroupId: number) {
  const sourceGroup = this.app.cards.find(group =>
    group.conversations.some((conversation: any) => conversation.id === conversationId)
  );
  const targetGroup = this.app.cards.find(group => group.grouping.id === newGroupId);

  if (sourceGroup && targetGroup) {
    const conversationIndex = sourceGroup.conversations.findIndex(
      (conversation: any) => conversation.id === conversationId
    );

    if (conversationIndex !== -1) {
      if (sourceGroup.grouping.id != targetGroup.grouping.id) {
        const [movedConversation] = sourceGroup.conversations.splice(conversationIndex, 1);
        if (!this.app.isMainViewOpen) {
          this.animateConversation(movedConversation, sourceGroup, targetGroup);
        } else {
          this.executeDrop(movedConversation, targetGroup);
        }
      }
    } else {
      console.error(`Conversation with ID ${conversationId} not found in source group.`);
    }
  } else {
    if (!sourceGroup) console.error(`Source group for conversation ID ${conversationId} not found.`);
    if (!targetGroup) console.error(`Target group with ID ${newGroupId} not found.`);
  }
}


  animateConversation(conversation: any, sourceGroup: any, targetGroup: any) {
    const conversationElement = document.querySelector(`.contact-item[data-id="${conversation.id}"]`) as HTMLElement;

    if (conversationElement) {
      const clone = conversationElement.cloneNode(true) as HTMLElement;

      // Match the clone's dimensions and styling to the original card
      const originalStyles = getComputedStyle(conversationElement);
      clone.style.position = 'absolute';
      clone.style.width = originalStyles.width;
      clone.style.height = originalStyles.height;
      clone.style.zIndex = '1';
      clone.style.boxShadow = originalStyles.boxShadow;
      clone.style.borderRadius = originalStyles.borderRadius;
      clone.style.padding = originalStyles.padding;
      clone.style.background = originalStyles.background;
      clone.style.border = originalStyles.border;
      document.body.appendChild(clone);

      const { top, left } = conversationElement.getBoundingClientRect();
      clone.style.top = `${top}px`;
      clone.style.left = `${left}px`;

      // Find the target group (destination group) element
      const targetGroupIndex = this.app.cards.indexOf(targetGroup);
      const targetElement = document.querySelectorAll('.card-body')[targetGroupIndex];

      if (targetElement) {
        // Hide the original card immediately using display: none
        conversationElement.style.display = 'none';
        const targetRect = targetElement.getBoundingClientRect();
        const targetTop = targetRect.top + window.scrollY;
        const targetLeft = targetRect.left + window.scrollX;

        clone.style.transition = 'top 1s ease, left 1s ease';
        setTimeout(() => {
          clone.style.top = `${targetTop}px`;
          clone.style.left = `${targetLeft}px`;

          clone.addEventListener('transitionend', () => {
            if (clone.parentNode === document.body) {
              document.body.removeChild(clone);
            }
            this.executeDrop(conversation, targetGroup);
          });
        });
      } else {
        console.error('Target element not found');
        document.body.removeChild(clone);
      }
    } else {
      console.error('Conversation element not found');
    }
  }

  executeDrop(conversation: any, targetGroup: any) {
    const existingConversation = targetGroup.conversations.find((c: any) => c.id === conversation.id);
    if (!existingConversation) {
      // If the conversation does not exist, add it to the target group
      targetGroup.conversations.push(conversation);
      // Sort the conversations in the target group
      this.sortConversationsInCard(targetGroup.grouping.id);
      // Trigger change detection
      this.cdr.detectChanges();
    } else {
      
    }
  }
  ngOnInit(): void {
    this.setViewPortSize();

    this.route.queryParams.subscribe(async (params) => {
      if (params.user) {
        this.spinner.show();
        const user = decodeURIComponent(params.user);
        this.app.role = params.role || 'Pro'; // Provide a default value if needed
        var isAllowed = false;
        var testUser = this.decryptString(
          'UJgidYgQbChhieUUGrzfN9cKGD69jHp1',
          user.replace(/\s/g, '+'))
          console.log("testi",testUser,this.api.apiEndPoint);
          try {
         const res = await this.api.sendPostRequest({
            action: 'Action/getAccessFromGL',
            body: { hash: user.replace(/\s/g, '+') }
          }).toPromise();
          isAllowed = res as unknown as boolean;
        } catch (error) {
         this.spinner.hide();
          Swal.fire({
            title: 'Error de Acceso!',
            text: 'No estás conectado actualmente. Por favor, conecte de nuevo su cuenta para poder continuar.',
            icon: 'question',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
          });
          }
        if (user && isAllowed) {
          this.api.updateUserKey(user.replace(/\s/g, '+'));
          const userId = this.decryptString(
            'UJgidYgQbChhieUUGrzfN9cKGD69jHp1',
            user.replace(/\s/g, '+')
          );
          if (userId){
            this.api.userId = userId;
            this.app.userId = userId;
          }

          this.checkIsConnected();
          this.app.checkIsConnectedEvent.subscribe(() => {
            this.checkIsConnected();
          })
          this.getSelectedCurrency();
          this.getLastPaymentDate();
          this.app.showLoadingEvent.subscribe(() => {
            this.spinner.show();
          })
          this.app.lastMessagesWritedLocally.push({
            message: "",
            timestamp: Date.now()
          });
          this.setupInactivityTimer();
          this.LastMessagesWritedyClearing();
          this.signalRService.startConnection();
          this.signalRService.addMessageListener();
          this.signalRService.addRefreshListener();
          this.signalRService.addWonListener();
          this.signalRService.addLeadsAmountListener();
          this.signalRService.addGroupUpdateListener();
          this.signalRService.addLeadMovesListener();
          this.signalRService.updateInstagramStatus();
          this.signalRService.addNewInstaMessageListener();
          this.signalRService.addNewFacebookMessageListener();
          this.signalRService.addRemoveRescueduledListener();
          this.signalRService.addApiListener();
          this.signalRService.addHandleSuccessListener();
          this.app.refreshConversations.subscribe(() => {
            this.refreshConversations();
          })
          this.app.showSpinnerEvent.subscribe(() => {
            this.spinner.show();
          });
          this.signalRService.groupingUpdated.subscribe(() => {
            if (!this.dragByThisDevice) {
              this.updateGroupList();
            }
            this.dragByThisDevice = false;
          });
          this.signalRService.leadMoved.subscribe((updatedConversation: any) => {
            this.moveConversationToNewGroup(updatedConversation.conversationId, updatedConversation.newGroupId);
          });

          this.signalRService.instagramConnectionStatus.subscribe((status: boolean) => {
            if (this.app.initiatedFromConnectionModal) {
              this.app.initiatedFromConnectionModal = false;
              if (status) {
                this.connectionUpdated();
              }
            }
            this.app.notifyFacebookStatusChange(true)
          });
          this.signalRService.messageApiReceived.subscribe((newMessage) => {
            if(!newMessage.fromMe)
            this.app.notifyMessageView.emit();
            this.cdr.detectChanges();
            this.handlewhatsappApiMessage(newMessage);
          }
          );
          this.signalRService.facebookNewMessage.subscribe((newMessage) => {
            var appendMessage = (!this.app.lastMessagesWritedLocally.some(item => item.message == newMessage.message) && newMessage.messageType == 1) || (newMessage.messageType != 1 && !newMessage.fromMe);
            if (newMessage.append) appendMessage = true;
            this.handleMetaNewMessage(newMessage, "messenger", appendMessage);
          });
          this.signalRService.instagramNewMessage.subscribe((newMessage) => {
            
            var appendMessage = (!this.app.lastMessagesWritedLocally.some(item => item.message == newMessage.message) && newMessage.messageType == 1) || (newMessage.messageType != 1 && !newMessage.fromMe);
            if (newMessage.append) appendMessage = true;
            this.handleMetaNewMessage(newMessage, "instagram", appendMessage);
          });

          this.signalRService.leadsAmountUpdated.subscribe((leadsAmount) => {
            this.app.totalAmount = leadsAmount;
          });
          this.signalRService.wonUpdated.subscribe((wonAmount) => {
            this.app.totalProfit = wonAmount;
          });
          this.signalRService.botSucceed.subscribe((success) => {
            console.log("Bot status : ",success);
            this.app.isWhatsappAPIConnected = success;
            this.cdr.detectChanges();
          });
          this.signalRService.messageReceived.subscribe((newMessage) => {
            this.handleWhatsappNewMessage(newMessage);
          });
          this.app.markAsReadEvent.subscribe((conversationId) => {
            var conversation = this.findConversationById(conversationId);
            this.recentMessagesCache.set(conversationId, {
              message: conversation.lastMessage,
              unreadCount: 0,
              timestamp: Date.now(),
              amount: conversation.amount
            });
          });
           this.signalRService.rescheduledSended.subscribe((rescheduledmsg) => {
            if (this.app.allConversationsMessages[rescheduledmsg.convId]) {
              this.app.allConversationsMessages[rescheduledmsg.convId] =
                this.app.allConversationsMessages[rescheduledmsg.convId].filter(message => message.id !== rescheduledmsg.generatedId);
            }
          });
          this.setBackGroundUpdateInterval();
          this.sharedDataService.getData().subscribe((data) => {
            this.moveConversation(data.selectedGroupId, data.contactId);
            this.api
              .sendPostRequest({
                action: 'Grouping/setGrouping',
                params: { groupingId: data.selectedGroupId, phone: data.contactId },
              })
              .subscribe((res) => {
                this.saveGrouping();
              });
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            title: 'Error de Acceso!',
            text: 'No estás conectado actualmente. Por favor, conecte de nuevo su cuenta para poder continuar.',
            icon: 'question',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
          });
        }
      }
    });
  }
  async refreshConversations() {
    this.spinner.show();

    setTimeout(() => {
      this.updateGroupList();
    }, 3000);
  }

  handlewhatsappApiMessage(newMessage: any) {
    setTimeout(async () => {
      const conversationId = newMessage.leadId;
      const conversation = this.findConversationById(conversationId);
      if (this.app.conversationTypingCount[conversationId] === undefined || this.app.conversationTypingCount[conversationId] === null) {
        this.app.conversationTypingCount[conversationId] = 0;
      }
      if(!newMessage.fromMe)
      {
        this.app.conversationTypingCount[conversationId]++;       
      }
      if(this.app.conversationTypingCount[conversationId]<4)
      this.app.conversationTyping[conversationId] = newMessage.fromMe;
      else this.app.conversationTyping[conversationId] = false;
      this.cdr.detectChanges();
      if (conversation) {
        if(conversation.testing && !this.stimulation) 
          {
            this.app.conversationTyping[conversationId] = false;
            return;
          }
        const newConvertedMessage = this.convertNewMessage(newMessage, "whatsapp");
        conversation.lastMessage = newConvertedMessage;
        conversation.lastUpdate = this.convertLocal(Date.now());
        if(!newMessage.fromMe) conversation.LastMessageReceivedOn=this.convertLocal(Date.now());
        this.sortConversationsInCard(this.findGroupingById(conversationId));
        newMessage.timestamp = this.convertLocal(Date.now());
        if (!newMessage.fromMe || newMessage.sendByBot || newMessage.rescheduled) 
        {
          if(!this.app.allConversationsMessages[conversationId]) this.app.allConversationsMessages[conversationId]=[];
          const existingMessage = this.app.allConversationsMessages[conversationId].find(
            msg => msg.body == newMessage.body
        );

        if (!existingMessage || newMessage.type !=1) {
            this.app.allConversationsMessages[conversationId].push(newMessage);
        }
        }
        if (newMessage.fromMe) {
          conversation.unreadMessages = 0;
        } else {
          if (this.isUserInactive || document.hidden) {
            this.audio.play();
          }
          conversation.unreadMessages += 1;
        }
      }
      else {
        if(this.app.apiAllLeadsCount)
        this.app.apiAllLeadsCount++;
        else this.app.apiAllLeadsCount = 1;
        const messagesResponse = await this.fetchConversationAPIMessages(conversationId);
        this.app.allConversationsMessages[conversationId] = messagesResponse;
        this.app.conversationInitiallyLoaded[conversationId] = true;
        this.app.conversationFullyLoaded[conversationId] = true;
        const existingConversation = this.findConversationById(conversationId);
        if (existingConversation) {
          return;
        }
      
        if (newMessage.name) {
          var manual = this.app?.appConfig?.manual;
          const newConversation = {
          id: conversationId,
          countryCode: newMessage.countryCode || null,
          name: newMessage.name || 'Unknown',
          phoneFormatted: newMessage.phoneFormatted || 'Unknown',
          unreadMessages: 0,
          lastUpdate: this.convertLocal(Date.now()),
          LastMessageReceivedOn: this.convertLocal(Date.now()),
          lastUpdateText: newMessage.body, 
          lastMessage: this.convertNewMessage(newMessage, 'whatsapp'),
          color: null,
          emoji: null,
          amount: null,
          won: false,
          manual: manual,
          testing : newMessage.testing,
          conversationType: 'whatsappApi',
          thumbnailUrl: newMessage.profilePicture
          };
          const existingConversation = this.findConversationById(conversationId);
          if (existingConversation) {
            return;
            }
            newConversation.unreadMessages += 1;
            if(this.app.cards[0])
            {
            this.app.cards[0].conversations.push(newConversation);
            this.sortConversationsInCard(this.app.cards[0].grouping.id);
            this.app.totalLeads = this.app.cards.reduce((total, grouping) => total + grouping.conversations.length, 0);
            }
            this.cdr.detectChanges();
          } else {
          this.updateGroupList();
        }
      }      
    }, 200);
  }
  async handleWhatsappNewMessage(newMessage: any) {
    setTimeout(async () => {
      const conversationId = !newMessage.fromMe ? newMessage.from : newMessage.to;
      const conversation = this.findConversationById(conversationId);
      if (conversation) {
        const newConvertedMessage = this.convertNewMessage(newMessage, "whatsapp");
        conversation.lastMessage = newConvertedMessage;
        conversation.lastUpdate = this.convertLocal(newMessage.t * 1000);
        this.sortConversationsInCard(this.findGroupingById(conversationId));

        if (!newMessage.fromMe) {
          this.appendNewMessage(conversationId, newMessage, "whatsapp");
          if (!this.app.allConversationsMessages[conversationId] || this.app.allConversationsMessages[conversationId].length <= 0) {
            try {
              const messagesResponse = await this.fetchMessagesForConversation(conversationId, 200);
              this.app.allConversationsMessages[conversationId] = messagesResponse;
            } catch (error) {
              console.error('Error fetching messages:', error);
            }
          }
        } else {
          if (!this.app.lastMessagesWritedLocally.some(item => item.message === newMessage.body) && newMessage.type == "chat") {
            this.appendNewMessage(conversationId, newMessage, "whatsapp");
          }
          if (newMessage.type != "chat" && newMessage.fileDownloadUrl && newMessage.fileDownloadUrl.length != "") {
            this.appendNewMessage(conversationId, newMessage, "whatsapp");
          }
          this.tripleMultiplier++;
        }

        if (newMessage.fromMe) {
          conversation.unreadMessages = 0;
        } else {
          if (this.isUserInactive || document.hidden) {
            this.audio.play();
          }
          conversation.unreadMessages += 1;
        }
        let updatedUnreadCount = conversation.unreadMessages;
        this.recentMessagesCache.set(conversation.id, {
          message: newConvertedMessage,
          unreadCount: updatedUnreadCount,
          timestamp: Date.now(),
          amount: conversation.amount
        });

        this.cdr.detectChanges();
        this.app.scrollToButtomEvent.emit();
      } else {
        this.updateGroupList();
      }
    }, 1000);
  }

  handleMetaNewMessage(newMessage: any, platform: string, appendMessage: boolean = true) {
    const participantId = newMessage.participantId;
    const conversation = this.findConversationByParticipantId(participantId);
    if (conversation) {
      if (!newMessage.isDeleted) {
        const newConvertedMessage = this.convertNewMessage(newMessage, platform);
        conversation.lastMessage = newConvertedMessage;
        conversation.lastUpdate = this.convertLocal(newMessage.timestamp);
        this.sortConversationsInCard(this.findGroupingById(conversation.id));
        this.cdr.detectChanges();
        if (appendMessage)
          this.appendNewMessage(conversation.id, newMessage, platform);
        if (newMessage.fromMe)
          conversation.unreadMessages = 0;
        else {
          if (this.isUserInactive || document.hidden)
            this.audio.play();
          conversation.unreadMessages += 1;
        }

        let updatedUnreadCount = conversation.unreadMessages;
        this.recentMessagesCache.set(conversation.id, {
          message: newConvertedMessage,
          unreadCount: updatedUnreadCount,
          timestamp: Date.now(),
          amount: conversation.amount
        });
      }
      else {
        this.deleteMessage(conversation.id, newMessage.messageId)
      }
    }
    else {
      this.updateGroupList();
    }
  }

  saveConversations() {
    /*
    const userId = this.api.userId;
    localStorage.setItem(`${this.api.userId}_allConversations`, JSON.stringify(this.app.allConversationsMessages));
    */
  }
  saveGrouping() {
    /*
    if (this.newData != null && this.newData.length > 0) {
      localStorage.setItem(`${this.api.userId}_allGrouping`, JSON.stringify(this.newData));
    }*/
  }


  deleteMessage(conversationId: string, messageId: string) {
    this.app.allConversationsMessages[conversationId].forEach(message => {
      if (message.id == messageId) {
        message.body = " This Message is deleted";
        message.fileDownloadUrl = null;
        message.type = 1;
        message.style = "deleted";
      }

    });
  }
  loadSavedGrouping() {
    const savedGrouping = localStorage.getItem(`${this.api.userId}_allGrouping`);
    if (savedGrouping !== null && savedGrouping.trim() !== '') {
      this.updateCards(JSON.parse(savedGrouping), true);
      this.inited = true;
      this.spinner.hide();
    }
  }

  loadSavedConversationsMessages() {
    const savedConversations = localStorage.getItem(`${this.api.userId}_allConversations`);
    if (savedConversations) {
      this.app.allConversationsMessages = JSON.parse(savedConversations);
    }
  }

  setBackGroundUpdateInterval() {
    setInterval(() => {
      this.updateGroupList();
    }, 6000000);
  }

  private setupUpdateGroupListTimer(): void {
    const tenMinutes = 2000000;
    const fortySeconds = 150000;

    const stopTimer$ = timer(tenMinutes);
    
    timer(0, fortySeconds)
      .pipe(takeUntil(stopTimer$))
      .subscribe({
        next: () => {
          
          this.updateGroupList(true);
        },
        complete: () => {
          
          this.app.conversationSyncMessage = false;
        }
      });
  }

  loadConversationMessages(conversationId: any, callback: any) {
    this.api.sendGetRequest({ action: 'Message/GetWhatsappConversationMessages', params: { contactId: conversationId, user: this.api.userId } })
      .subscribe((messages) => {
        this.app.allConversationsMessages[conversationId] = messages;
        callback();
      });
  }

  appendNewMessage(conversationId: any, newMessage: any, platform: string) {
    const convertedMessage = this.convertNewMessage(newMessage, platform);
    const isMessageExist = this.app.allConversationsMessages[conversationId] && this.app.allConversationsMessages[conversationId].some(conversation => conversation.id === convertedMessage.id);
    if (!isMessageExist) {
      if (convertedMessage.body == null && platform != "whatsapp") {
        convertedMessage.body = "Has recibido un mensaje de audio. Los mensajes de audio no son compatibles por ahora.";
        convertedMessage.style = "deleted"
      }
      
      if (this.app.allConversationsMessages[conversationId]) {
        if (!this.app.allConversationsMessages[conversationId].some(existingMsg => existingMsg.id === convertedMessage.id)) {
          

          this.app.allConversationsMessages[conversationId].push(convertedMessage);
        } else {
          
        }
      }
      else {
        if (platform == "whatsapp") {
          this.fetchMessagesForConversation(conversationId, 200).then(messagesResponse => {
            this.app.allConversationsMessages[conversationId] = messagesResponse;
          }).catch(error => {
            console.error('Error fetching messages:', error);
          });
        }
        if (platform == "instagram") {
          this.fetchInstagramMessages(conversationId);
        }
        if (platform == "messenger") {
          this.fetchFacebookMessages(conversationId);
        }
      }
    } else {
      
    }
  }

  ngOnDestroy(): void {
    this.viewportSubscription.unsubscribe();
    clearTimeout(this.inactivityTimer);
    clearTimeout(this.updateGroupListInactivityTimer);
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  toggleModoPrivado() {
    this.modoPrivadoActive = true;
    this.app.handleBlur(this.modoPrivadoActive);
  }

  toggleNoLeidos() {
    this.noLeidosActive = true;
    this.app.handleShowUnread(this.noLeidosActive);
  }

  private setViewPortSize(): void {
    this.viewportSubscription = this.viewportRuler.change().subscribe(() => {
      const { width, height } = this.viewportRuler.getViewportSize();
      this.setDialogWidth(width);
    });
    const { width, height } = this.viewportRuler.getViewportSize();
    this.setDialogWidth(width);
  }
  setDialogWidth(viewportWidth: number): void {
    const maxWidth = Math.min(viewportWidth * 0.9, 800); // Set maximum width to 90% of viewport or 800px
    this.dialogWidth = `${maxWidth}px`;
  }

  encryptString(key: string, plainText: string) {
    const encrypted = AES.encrypt(plainText, key);
    return encrypted.toString();
  }

  decryptString(key: string, encryptedText: string) {
    try {
      const combinedIvCt = enc.Base64.parse(encryptedText);
      const iv = enc.Hex.parse(combinedIvCt.toString().slice(0, 32));
      const cipherText = enc.Hex.parse(combinedIvCt.toString().slice(32));
      const decrypted = AES.decrypt(
        cipherText.toString(enc.Base64),
        enc.Utf8.parse(key),
        { iv: iv }
      );
      return decrypted.toString(enc.Utf8);
    } catch (error) {
      return false;
    }
  }
  checkStatus() {
    this.api.sendGetRequest({ action: 'Action/IsConnected' })
      .subscribe
      ({
        next: (res) => {
          this.app.isWhatsappConnected = res.whatsAppConnected;
          this.app.isFacebookConnected = res.facebookConnected;
          this.app.isInstagramConnected = res.instagramConnected;
          this.app.isWhatsappAPIConnected = res.whatsappApiConnected;
          
        }
      });
  }
  /*
    checkIsConnected(isInit = true) {
      if (isInit) {
        this.inited = false;
      }
  
      this.api.sendGetRequest({ action: 'Action/IsConnected' })
        .subscribe({
          next: (res) => {
            this.app.isWhatsappConnected = res.whatsAppConnected;
            this.app.isFacebookConnected = res.facebookConnected;
            this.app.isInstagramConnected = res.instagramConnected;
            if (!this.app.isWhatsappConnected) this.whatsappConversationsIdsList = [];
            if (!this.app.isFacebookConnected) this.facebookConversationsIdsList = [];
            if (!this.app.isInstagramConnected) this.instagramConversationsIdsList = [];
            if (this.app.isWhatsappConnected || this.app.isFacebookConnected) {
              if (isInit) {
                if(this.app.isWhatsappConnected)
                this.loadSavedGrouping();
                // this.loadSavedConversationsMessages()
              }
              
              this.setupUpdateGroupListTimer();
              } 
              else {
              this.spinner.hide();
              this.openConnectionModal(true);
            }
          },
          error: (error) => {
            console.error('Failed to check connection status:', error);
            this.spinner.hide();
            this.openConnectionModal(true);
          }
        });
         next: (res) => {
          this.app.isWhatsappConnected = res.whatsAppConnected;
          this.app.isFacebookConnected = res.facebookConnected;
          this.app.isInstagramConnected = res.instagramConnected;
    }
  */
  openSubscriptionModal() {
    var width = this.app.role.toLowerCase() == 'proia' ? '850px' : '400px';
    const dialogRef = this.dialog.open(SubscriptionModalComponent, {
      width: width,
      data: { leadsLimit: this.app.leadsLimit }
    });
  }
  checkIsConnected(isInit = true) {
    if (isInit) {
      this.inited = false;
    }
    this.api.sendGetRequest({ action: 'Action/IsConnected' })
      .subscribe({
        next: (res) => {
          this.app.isWhatsappConnected = res.whatsAppConnected;
          this.app.isFacebookConnected = res.facebookConnected;
          this.app.isInstagramConnected = res.instagramConnected;
          this.app.isWhatsappAPIConnected = res.whatsappApiConnected;
          console.log("All status ",res);
          if (this.app.isWhatsappAPIConnected) {
            this.api.sendGetRequest({ action: 'Action/GetBotLimit' })
            .subscribe({
              next: (res) => {
                this.app.leadsLimit = res;
              }
            });
            this.api.sendGetRequest({ action: 'Action/GetBotVoicesId' })
            .subscribe({
              next: (res) => {
                this.app.voicesId = res.slice(0, 3);
              }
            });
            this.api.sendGetRequest({ action: 'Action/GetBotCustomVoicesId' })
            .subscribe({
              next: (res) => {
                this.app.customVoicesId = res;
                this.app.customVoicesId.forEach(voice => {
                  if (voice.voiceLink) {
                    const url = 'https://glpipeline8b0b.blob.core.windows.net/audios/' + voice.voiceLink;
                    this.audioPreloadService.preload(voice.id, url);
                  }
                });
              }
            });
            this.api.sendGetRequest({ action: 'Action/GetTemplatesByUserId' })
            .subscribe({
              next: (res) => {
                this.app.templates = res;
              }
            });
            this.app.botId = res.botId;
            this.api.sendGetRequest({ action: 'Action/GetWhatsappApiNumber' })
              .subscribe({
                next: (res) => {
                  this.app.whatsappApiNumber = res.name;
                  console.log("Phone number :",res);
                }
              });
            this.api.sendGetRequest({ action: 'Action/GetWhatsappBotConfig' })
              .subscribe({
                next: (res) => {
                  this.app.appConfig = res;
                }
              });
              this.api.sendGetRequest({action: 'Message/GetLeadsCount'})
              .subscribe((res) => {
              this.app.apiAllLeadsCount=res;
              });
          }
          if (this.app.isWhatsappConnected || this.app.isFacebookConnected || this.app.isWhatsappAPIConnected)
            this.updateGroupList(true);
          else {
            this.spinner.hide();
            this.openConnectionModal(true);
          }
        },
        error: (error) => {
          console.error('Failed to check connection status:', error);
          this.spinner.hide();
          this.openConnectionModal(true);
        }
      });
  }
  getProgress(): number {
    if (this.app.leadsLimit === 0) return 0;  // Avoid division by zero
    return (this.app.apiAllLeadsCount / this.app.leadsLimit) * 100;
  }

  openConnectionModal(init: boolean = false) {
    if (init) {
      const dialogRef = this.dialog.open(InitConnectionModalComponent, {
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.spinner.show();
        setTimeout(() => {
          this.checkIsConnected();
        }, 5000);
      });
    }
    else {
      const dialogRef = this.dialog.open(ConnectionModalComponent, {
        width: '850px',
        panelClass: 'custom-connection-modal-container'
      });
      this.app.connectionIsUpdated.subscribe(() => {
        location.reload();
      });
    }
  }

  private getCardScroll() {
    const listEls = document.querySelectorAll('.card-body');
    for (let i = 0; i < listEls.length; i++) {
      this.cardScrollPosition[i] = listEls[i].scrollTop;
    }

  }

  private setCardScroll(): void {
    const cardBodyElements = document.querySelectorAll('.card-body');
    for (let i = 0; i < cardBodyElements.length; i++) {
      if (this.cardScrollPosition[i] != undefined && this.cardScrollPosition[i] > 0)
        cardBodyElements[i].scrollTop = this.cardScrollPosition[i];
    }
  }

  disconnect() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Cerrar sesión',
          message: 'Estás seguro de que deseas desconectar tu cuenta?',
        },
        width: '400px',
        height: '200px',
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result == true) {
          this.api
            .sendGetRequest({ action: 'Action/Close' })
            .subscribe((res) => {
              this.app.isWhatsappConnected = false;
              
              this.updateGroupList(true);
              this.inited = false;
              this.spinner.show();
              this.checkIsConnected();
            });
        }
      });
  }

  helpModal() {
    const dialogRef = this.dialog.open(HelpLogoComponent, {
      data: {
        title: 'Help',
        message: '',
      },
      panelClass: 'custom-helppopup',
      width: '60%'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  drag = (event: CdkDragStart<string[]>) => {
    this.app.allowGroupUpdate = false;
  };

  drop = (event: CdkDragDrop<string[]>) => {

    this.app.allowGroupUpdate = true;
    this.dragByThisDevice = true;
    const current_card: any = this.app.cards.find(
      (card) => card?.grouping.id === (event.container.data as any)
    );
    const previous_card: any = this.app.cards.find(
      (card) => card?.grouping.id === (event.previousContainer.data as any)
    );
    if (event.previousContainer === event.container) {
      moveItemInArray(
        current_card['conversations'],
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const id = previous_card['conversations'][event.previousIndex]['id'];
      const exist = current_card['conversations'].findIndex(
        (conv: any) => conv.id === id
      );
      if (exist === -1) {
        transferArrayItem(
          previous_card['conversations'],
          current_card['conversations'],
          event.previousIndex,
          event.currentIndex
        );
        this.api
          .sendPostRequest({
            action: 'Grouping/setGrouping',
            params: {
              groupingId: current_card.grouping.id,
              phone: id,
            },
          })
          .subscribe((res) => {
          });
      }
      this.leadUpdateService.notifyLeadUpdated();
      this.saveGrouping();
    }
  };

  createBox = (group: any) => {
    this.app.allowGroupUpdate = false;
    const ref = this.dialog.open(CreateGroupComponent, {
      data: { action: 'create', group } // Pass both the action type and group data
    });
    ref.afterClosed().subscribe((res) => {
      if (!res) {
        this.app.allowGroupUpdate = true;
        return;
      }
      const groupName = res.name;
      const randomId = Math.floor(Math.random() * 100000) + Date.now();
      this.api
        .sendPostRequest({
          action: 'Grouping/create',
          params: {
            name: res.name,
            description: res.description,
            color: res.color,
            emoji: res.emoji,
            position: group.position + 1,
          },
        })
        .subscribe((res) => {
          var afterIndex = this.app.cards.findIndex((item) => item.grouping.id == group.id);
          this.groupList.splice(afterIndex + 1, 0, { id: res.id, groupName: groupName });
          this.app.cards.splice((afterIndex + 1), 0, { "grouping": res, "conversations": [] });
          this.sortAndUpdateCards();
          this.app.allowGroupUpdate = true;
        });
    });
  };

  async startStimulation() {
    console.log("Roles :",this.app.role);
    const dialogRef = this.dialog.open(StartStimulationDialogComponent, {
      width: '500px',
      maxWidth: '90vw',
      height: 'auto',
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.businessDescription) {
        this.stimulation = true;
        const businessDescription = result.businessDescription;

        for (let i = 0; i <20; i++) {
          if (!this.stimulation) break;

          this.api.sendPostRequest({
            action: 'Message/CreateTestLead',
            params: {},
            body: { businessDescription: businessDescription }
          }).subscribe({
            next: (response) => {
              // Handle response if necessary
            },
            error: (error) => {
              console.error(`Error creating lead ${i + 1}`, error);
            },
          });
          const delay = Math.floor(Math.random() * (30000 - 10000 + 1)) + 10000;
          await this.delay(delay);
        }
      }
    });
  }

  stopStimulation() {
    // Open the dialog and ask the user for a choice
    const dialogRef = this.dialog.open(StopStimulationDialogComponent, {
      width: '500px',
      maxWidth: '90vw',
      height: 'auto',
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'stop') {
        this.stimulation = false;
      } else if (result === 'delete') {
        this.stimulation = false;
        this.spinner.show();
        this.api
          .sendPostRequest({
            action: 'Message/RemoveTestLeads',
          })
          .subscribe((res) => {
            setTimeout(() => {
              this.updateGroupList();
            }, 6000);
          });
      }
    });
  }  
  
  editBox = (group: any) => {
    this.app.allowGroupUpdate = false;
    var oldname = group.name;
    const ref = this.dialog.open(CreateGroupComponent, {
      data: { action: 'edit', group } // Pass both the action type and group data
    });
    ref.afterClosed().subscribe((res) => {
      if (!res) {
        this.app.allowGroupUpdate = true;
        return;
      }
      const index = this.app.cards.findIndex(card => card.grouping.id === group.id);
      if (index !== -1) {
        this.app.cards[index].grouping.name = res.name;
        this.app.cards[index].grouping.description = res.description;
        this.app.cards[index].grouping.color = res.color;
      }
      this.api
        .sendPostRequest({
          action: 'Grouping/edit',
          params: {
            oldname: oldname,
            name: res.name,
            description: res.description,
            color: res.color,
          },
        })
        .subscribe((updatedGrouping) => {
        this.app.allowGroupUpdate = true;
        });
    });
  };
  
  

  connectionUpdated = () => {
    this.spinner.show();
    this.app.isFacebookConnected = true;
    this.app.isInstagramConnected = true;
    this.cdr.detectChanges();
    this.updateGroupList();
  }
  async reloadSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.sendGetRequest({ action: 'Action/Reload' }).subscribe({
        next: (res) => {
          
          resolve(res);  // Resolve the promise with the response
        },
        error: (err) => {
          console.error(err);
          reject(err);  // Reject the promise if there's an error
        }
      });
    });
  }

  async getQr(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.sendGetRequest({ action: 'Action/GetQr' }).subscribe({
        next: (res) => {
          
          resolve(res);  // Resolve the promise with the response
        },
        error: (err) => {
          console.error(err);
          reject(err);  // Reject the promise if there's an error
        }
      });
    });
  }
  updateGroupList = (isInit = false) => {
    if (this.app.allowGroupUpdate && (this.app.isWhatsappConnected || this.app.isFacebookConnected || this.app.isWhatsappAPIConnected)) {
      this.checkStatus();
      this.lastGetConversationsTime = Date.now();
      this.getLastPaymentDate();
      this.api
        .sendGetRequest({
          action: 'Message/GetConversations',
          params: {
            whatsappConnected: this.app.isWhatsappConnected,
            facebookConnected: this.app.isFacebookConnected,
            instagramConnceted: this.app.isInstagramConnected,
            whatsappApiConnected: this.app.isWhatsappAPIConnected
          },
        })
        .subscribe((res) => {
          this.checkStatus();
          if(this.app.isWhatsappAPIConnected)
            this.api
            .sendGetRequest({
            action: 'Message/GetLeadsCount'}).subscribe((res) => {
            this.app.apiAllLeadsCount=res;
            });
 
          if (this.app.allowGroupUpdate && res != null && res != "") {
            
            this.getCardScroll();
            let leadsCount = 0;
            let apileadsCount = 0;
            let amountCount = 0;
            let profitCount = 0;
            let facebookIds: any = [];
            let instagramIds: any = [];
            let whatsappApiIds: { id: string, lastUpdate: string }[] = [];
            let whatsappIds: { id: string, lastUpdate: string }[] = [];

            res.forEach((group: any) => {
              group.conversations.forEach((conversation: any) => {
                if (this.app.allRequestCount[conversation.id] == null)
                  this.app.allRequestCount[conversation.id] = 0;
                if (conversation.conversationType == "whatsappApi") {
                  whatsappApiIds.push({ id: conversation.id, lastUpdate: conversation.lastUpdate });
                }
                if(conversation.testing)
                this.testingConv.push(conversation);
                if (conversation.conversationType == "messenger") {
                  conversation.lastUpdate = this.convertUtcStringToLocal(conversation.lastUpdate);
                  facebookIds.push(conversation.id);
                  if (this.facebookConversationsIdsList != null && conversation.id != null && !this.facebookConversationsIdsList.some((item: any) => item == conversation.id)
                    && this.conversationsCallCount > 1) {
                    conversation.unreadMessages = 1;
                  }
                }
                if (conversation.conversationType == "instagram") {
                  conversation.lastUpdate = this.convertUtcStringToLocal(conversation.lastUpdate);
                  instagramIds.push(conversation.id);
                  if (this.instagramConversationsIdsList != null && conversation.id != null && !this.instagramConversationsIdsList.some((item: any) => item == conversation.id)
                    && this.conversationsCallCount > 1) {
                    conversation.unreadMessages = 1;
                  }
                }
                if (conversation.conversationType == "whatsapp") {
                  if (conversation.id != null)
                    whatsappIds.push({ id: conversation.id, lastUpdate: conversation.lastUpdate });
                }
                const cachedData = this.recentMessagesCache.get(conversation.id);
                if (cachedData && cachedData.timestamp > this.lastGetConversationsTime) {
                  conversation.amount = cachedData.amount;
                  conversation.lastMessage = cachedData.message;
                  conversation.unreadMessages = cachedData.unreadCount;
                  
                }

                if (conversation.conversationType != "whatsapp") {
                  if (cachedData) {
                    
                    conversation.unreadMessages = cachedData.unreadCount;
                  }
                }
                if (conversation.id != null) {
                  leadsCount += 1;
                  if (conversation.conversationType == "whatsappApi")
                    apileadsCount += 1;
                  if (this.app.ConversationUploadingVideo[conversation.id] == null)
                    this.app.ConversationUploadingVideo[conversation.id] = false;
                }
                amountCount += conversation.amount;
                if (conversation.won)
                  profitCount += conversation.amount;
              });
            });

            // Sort IDs by last update time
            whatsappIds.sort((a: { id: string, lastUpdate: string }, b: { id: string, lastUpdate: string }) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
            whatsappApiIds.sort((a: { id: string, lastUpdate: string }, b: { id: string, lastUpdate: string }) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
            facebookIds.sort((a: { id: string, lastUpdate: string }, b: { id: string, lastUpdate: string }) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
            instagramIds.sort((a: { id: string, lastUpdate: string }, b: { id: string, lastUpdate: string }) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
            // Update the state with the sorted and limited conversations
            this.whatsappApiConversationsIdsList = whatsappApiIds;
            this.facebookConversationsIdsList = facebookIds;
            this.instagramConversationsIdsList = instagramIds;
            this.whatsappConversationsIdsList = whatsappIds;
            this.app.totalLeads = leadsCount;
            this.app.apiLeadsCount = apileadsCount;
            this.app.totalAmount = amountCount;
            this.app.totalProfit = profitCount;
            this.groupList = [];
            
            this.updateCards(res, true);
            this.saveGrouping();
            this.app.modalCloseEvent.emit();
            this.spinner.hide();
            console.log("Testo",this.whatsappApiConversationsIdsList,
              this.facebookConversationsIdsList,
              this.instagramConversationsIdsList,
              this.whatsappConversationsIdsList);
            if (isInit) {
              this.inited = true;
              this.spinner.hide();
              if (this.app.isWhatsappAPIConnected)
                this.getWhatsappApiConversationsMessages();
              if (this.app.isInstagramConnected)
                this.getInstagramConversationsMessages();
              if (this.app.isWhatsappConnected) {
                this.getWhatsappConversationsMessages(200).then(() => {
                  return this.getWhatsappConversationsMessages(200);
                });
              }
              if (this.app.isFacebookConnected)
                this.getFacebookConversationsMessages();
            }
            this.initialLoad = true;
            this.conversationsCallCount++;
          }
        },
          (error) => {
            window.location.reload();
          });
    }
  };


  async getWhatsappConversationsMessages(count: number): Promise<void> {
    // Iterate over the sorted list of WhatsApp conversation IDs
    for (const conversation of this.whatsappConversationsIdsList) {
      const conversationId = conversation.id;
      try {
        this.app.allRequestCount[conversationId]++;
        if (this.app.allRequestCount[conversationId] && this.app.allRequestCount[conversationId] <= 3 && !this.app.ConversationUploadingVideo[conversationId] && !this.app.conversationFullyLoaded[conversationId]) {
          const messagesResponse = await this.fetchMessagesForConversation(conversationId, count);
          if (messagesResponse != null) {
            for (let i = 0; i < messagesResponse.length; i++) {
              if (messagesResponse[i]['body'])
                messagesResponse[i]['body'] = this.app.wrapLinksWithAnchorTag(messagesResponse[i]['body']);
            }
            if (messagesResponse.length == 200) this.app.conversationFullyLoaded[conversationId] = true;
          }
          const newMessages = messagesResponse || [];
          let existingMessages = this.app.allConversationsMessages[conversationId] || [];

          // Get last 5 messages from existing and new messages for comparison
          const lastExistingMessages = existingMessages.slice(-5);
          const lastNewMessages = newMessages.slice(-5);

          // Compare and merge messages
          newMessages.forEach((newMsg: any) => {
            const existingMatchIndex = lastExistingMessages.findIndex(existingMsg =>
              existingMsg.body === newMsg.body &&
              existingMsg.fromMe === newMsg.fromMe
            );

            if (existingMatchIndex === -1) {
              existingMessages.push(newMsg);
            } else {
              // Update existing message properties
              const fullIndex = existingMessages.length - 5 + existingMatchIndex; // Adjust for full index
              existingMessages[fullIndex] = { ...existingMessages[fullIndex], ...newMsg };
            }
          });

          existingMessages.sort((a, b) => a.timestamp - b.timestamp);
          this.app.allConversationsMessages[conversationId] = messagesResponse;
          this.cdr.detectChanges();
          if (this.app.allRequestCount[conversationId] >= 1) {
            this.app.conversationFullyLoaded[conversationId] = true;
          }
        }

      } catch (error) {
        console.error(`Error fetching messages for contact ${conversationId}:`, error);
      }
    }
  }

  moveConversation(newGroupId: string, conversationId: string) {
    let sourceGroup = this.findGroupContainingConversation(conversationId);
    let targetGroup = this.app.cards.find(group => group.grouping.id === newGroupId);

    if (sourceGroup && targetGroup) {
      const conversationIndex = sourceGroup.conversations.findIndex((c: any) => c.id === conversationId);
      if (conversationIndex !== -1) {
        // Remove from current group
        const [movedConversation] = sourceGroup.conversations.splice(conversationIndex, 1);
        // Add to target group
        targetGroup.conversations.push(movedConversation);
        // Sort or process as needed, for example:
        this.sortConversationsInCard(targetGroup.grouping.id);
        // Trigger Angular's change detection manually if necessary
        this.cdr.detectChanges();
      }
    }
  }

  findGroupContainingConversation(conversationId: string) {
    return this.app.cards.find(group => group.conversations.some((c: any) => c.id === conversationId));
  }

  async fetchMessagesForConversation(conversationId: string, count: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.api.sendGetRequest({
        action: 'Message/GetWhatsappMessages',
        params: { contactNumber: conversationId, limit: count }
      }).subscribe(
        response => resolve(response),
        error => reject(error)
      );
    });
  }
  configurateWhatsappApi() {
    this.dialog.open(WhatsappApiConfigComponent, {
      width: '600'
    });
  }
  async getWhatsappApiConversationsMessages():Promise<void>{
    for(const conversation of this.whatsappApiConversationsIdsList)
    {
      const conversationId = conversation.id;
      try
      {
        const messagesResponse = await this.fetchConversationAPIMessages(conversationId);
        messagesResponse.forEach((message:any) => {
          message.style = "default";
        });
        this.app.allConversationsMessages[conversationId] = messagesResponse;
        this.app.conversationInitiallyLoaded[conversationId] = true;
        this.app.conversationFullyLoaded[conversationId] = true;
      }
      catch(error)
      {
        console.error(`Error fetching messages for contact ${conversationId}:`, error);
      }
    }
  }
  
  async fetchConversationAPIMessages(conversationId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.api.sendGetRequest({
          action: 'Message/GetWhatsappApiMessages',
          params: { leadId: conversationId}
      }).subscribe(
          response => resolve(response),
          error => reject(error)
      );
  });
  }
  getFacebookConversationsMessages = () => {
    this.facebookConversationsIdsList.forEach((conversationId: any) => {
      if (this.app.allConversationsMessages[conversationId] == null || this.app.allConversationsMessages[conversationId].length <= 0) {
        this.api.sendGetRequest({ action: 'Message/GetFacebookConversationMessages', params: { conversationId: conversationId } })
          .subscribe((response) => {
            response.messages.forEach((message: any) => {
              message.timestamp = this.convertLocal(message.timestamp);
              message.style = "default";
            });

            this.app.allConversationsMessages[conversationId] = response.messages;
            this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
            this.app.conversationInitiallyLoaded[conversationId] = true;
            if (response.nextFetch == "")
              this.app.conversationFullyLoaded[conversationId] = true;
          });
      }
    });
  }

  getInstagramConversationsMessages = () => {
    this.instagramConversationsIdsList.forEach((conversationId: any) => {
      if (this.app.allConversationsMessages[conversationId] == null || this.app.allConversationsMessages[conversationId].length <= 0) {
        this.api.sendGetRequest({ action: 'Message/GetInstagramConversationMessages', params: { conversationId: conversationId } })
          .subscribe((response) => {
            response.messages.forEach((message: any) => {
              message.timestamp = this.convertLocal(message.timestamp);
              if (message.type == 5 && (message.fileDownloadUrl == null || message.fileDownloadUrl == "")) {
                message.body = "Los mensajes de audio antiguos no son compatibles en este momento.";
                message.style = "deleted"
                message.type = 1;
              }
            });
            this.app.allConversationsMessages[conversationId] = response.messages;
            this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
            this.app.conversationInitiallyLoaded[conversationId] = true;
            if (response.nextFetch == "")
              this.app.conversationFullyLoaded[conversationId] = true;
          });
      }
    });
  }

  fetchInstagramMessages = (conversationId: string) => {
    this.api.sendGetRequest({ action: 'Message/GetInstagramConversationMessages', params: { conversationId: conversationId } })
      .subscribe((response) => {
        response.messages.forEach((message: any) => {
          message.timestamp = this.convertLocal(message.timestamp);
          if (message.type == 5 && (message.fileDownloadUrl == null || message.fileDownloadUrl == "")) {
            message.body = "Los mensajes de audio antiguos no son compatibles en este momento.";
            message.style = "deleted"
            message.type = 1;
          }
        });
        this.app.allConversationsMessages[conversationId] = response.messages;
        this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
        this.app.conversationInitiallyLoaded[conversationId] = true;
        if (response.nextFetch == "")
          this.app.conversationFullyLoaded[conversationId] = true;
      });
  }

  fetchFacebookMessages = (conversationId: string) => {
    this.api.sendGetRequest({ action: 'Message/GetFacebookConversationMessages', params: { conversationId: conversationId } })
      .subscribe((response) => {
        response.messages.forEach((message: any) => {
          message.timestamp = this.convertLocal(message.timestamp);
          message.style = "default";
        });
        this.app.allConversationsMessages[conversationId] = response.messages;
        this.app.allConversationsFetchToken[conversationId] = response.nextFetch;
        this.app.conversationInitiallyLoaded[conversationId] = true;
        if (response.nextFetch == "")
          this.app.conversationFullyLoaded[conversationId] = true;
      });
  }

  showMainView = (contactId: number, card: any, conversationId: any) => {
    this.app.showCheck;
    this.currentContactId = contactId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '90vh';
    dialogConfig.width = '90%';
    dialogConfig.maxWidth = '1300px';
    dialogConfig.panelClass = 'custom-messagebox';
    dialogConfig.data = {
      contactId: contactId,
      groupList: this.groupList,
      defaultGroupId: card,
      conversationId: conversationId
    };
    this.app.isMainViewOpen = true;
    const ref: MatDialogRef<MainViewComponent> = this.dialog.open(
      MainViewComponent,
      dialogConfig
    );
    ref.afterClosed().subscribe(() => {
      this.app.isMainViewOpen = false;
    });
  };

  updatePosition = (movement: number, grouping: any) => {

    const currentIndex = this.app.cards.findIndex((item: any) => item.grouping.id === grouping.id);
    if (currentIndex !== -1) {
      const newIndex = currentIndex + movement;

      if (newIndex >= 1 && currentIndex >= 1 && newIndex < this.app.cards.length) {
        // Swap positions
        [this.app.cards[currentIndex].grouping.position, this.app.cards[newIndex].grouping.position] = [
          this.app.cards[newIndex].grouping.position,
          this.app.cards[currentIndex].grouping.position,
        ];

        this.sortAndUpdateCards();
        const movementAction = movement > 0 ? 'increaseGroupingPosition' : 'decreaseGroupingPosition';
        this.api
          .sendPostRequest({
            action: `Grouping/${movementAction}`,
            params: { groupingId: grouping.id },
          })
          .subscribe();
      }
    }
    this.saveGrouping();
  };

  private sortAndUpdateCards() {
    this.app.cards = this.app.cards.sort((p: any, n: any) => p.grouping.position - n.grouping.position);
    this.app.cards.forEach((card, index) => (card.grouping.position = index + 1));
  }


  updateCards(data?: any, isInit: boolean = false) {
    if (!this.app.allowGroupUpdate) {
      return;
    }
    if (data) {
      const filteredData = data.map((card: CardType) => ({
        ...card,
        conversations: card.conversations.filter((conv: any) => conv.id !== null)
      }));

      this.newData = filteredData.sort(
        (p: any, n: any) => p.grouping.position - n.grouping.position
      );
      if (isInit) {
        this.app.cards = this.newData;
      }
    }
    for (let g = 0; g < this.app.cards.length; g++) {
      var groupName = this.app.cards[g]?.grouping?.name;
      var id = this.app.cards[g]?.grouping?.id;
      this.groupList.push({ id: id, groupName: groupName });
    }
    var that = this;
    setTimeout(function () {
      that.setCardScroll();
    }, 0)
  }

  changeConversationLastMessage() {
    var conversation = this.findConversationById("ss");
  }

  handleSearch(e: any) {
    this.search = e;
    this.updateCards();
  }

  setupInactivityTimer() {
    this.resetInactivityTimer();
    this.resetUpdateGroupListInactivityTimer();
    const activityEvents = ['mousemove', 'click', 'scroll', 'keypress'];
    activityEvents.forEach(event => {
      document.addEventListener(event, () => {
        this.resetInactivityTimer();
        this.resetUpdateGroupListInactivityTimer();
      });
    });
  }

  resetUpdateGroupListInactivityTimer() {
    clearTimeout(this.updateGroupListInactivityTimer);
    this.updateGroupListInactivityTimer = setTimeout(() => {
      if (this.app.isWhatsappAPIConnected || this.app.isFacebookConnected || this.app.isWhatsappAPIConnected) {
        this.updateGroupList(true);
      }
      this.resetUpdateGroupListInactivityTimer();
    }, this.inactivityDurationForGroupUpdate);
  }

  resetInactivityTimer() {
    clearTimeout(this.inactivityTimer);
    this.isUserInactive = false;
    this.inactivityTimer = setTimeout(() => {
      this.isUserInactive = true;
    }, this.inactivityDuration);
  }

  convertUtcToLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }

  convertLocal(utcTimestamp: number): Date {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }
  convertUtcStringToLocal(utcDateString: string): Date {
    const utcDate = new Date(utcDateString);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localDate;
  }

  convertNewMessage(newMessage: any, platform: string): any {
    let convertedMessage: any;

    if (platform == "whatsapp") {
      convertedMessage = {
        timestamp: this.convertLocal(newMessage.t * 1000),
        id: newMessage.id,
        body: newMessage.body,
        caption: newMessage.caption,
        fromMe: newMessage.fromMe,
        type: newMessage.type,
        fileName: newMessage.filename,
        fileDownloadUrl: newMessage.fileDownloadUrl,
        description: newMessage.description,
        hasReaction: newMessage.hasReaction,
        isForwarded: newMessage.isForwarded,
        duration: newMessage.duration,
        quotedMessageId: newMessage.quotedMessageId
      };
    }
    else if (platform == "instagram" || platform == "messenger") {
      convertedMessage = {
        timestamp: this.convertLocal(newMessage.timestamp),
        id: newMessage.messageId,
        body: newMessage.message,
        fromMe: newMessage.fromMe,
        type: newMessage.messageType,
        fileDownloadUrl: newMessage.fileDownloadUrl
      };
    }


    return convertedMessage;
  }

  getTypeFromNewMessage(type: string): any {
    if (type == 'chat') return 1;
    if (type == 'image') return 2;
    if (type == 'video') return 3;
    if (type == 'document') return 4;
    if (type == 'ptt') return 5;
    if (type == 'audio') return 5;
  }

  findConversationById(conversationId: string) {
    for (const card of this.app.cards) {
      for (const conversation of card.conversations) {
        if (conversation.id === conversationId) {
          return conversation;
        }
      }
    }
    return null;
  }

  findConversationByParticipantId(participantId: string) {
    for (const card of this.app.cards) {
      for (const conversation of card.conversations) {
        if (conversation.participantId === participantId) {
          return conversation;
        }
      }
    }
    return null;
  }

  findGroupingById(conversationId: string) {
    // Iterate over each card in app.cards
    for (const card of this.app.cards) {
      // Check if this card has the conversation with the given conversationId
      const foundConversation = card.conversations.find((conversation: any) => conversation.id === conversationId);

      // If found, return the grouping ID
      if (foundConversation) {
        return card.grouping.id;
      }
    }

    // If no matching conversation is found, return null
    return null;
  }
  sortConversationsInCard(cardId: string) {
    const card = this.app.cards.find((card: any) => card.grouping.id === cardId);
    if (card) {
      card.conversations.sort((a: any, b: any) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
    }

  }
  findMostRecentUpdateInCard(card: any): number {
    let mostRecentUpdate = new Date(0); // Start with a default old date
    card.conversations.forEach((conversation: any) => {
      if (conversation.lastUpdate && new Date(conversation.lastUpdate) > mostRecentUpdate) {
        mostRecentUpdate = new Date(conversation.lastUpdate);
      }
    });
    return mostRecentUpdate.getTime(); // Return the timestamp
  }

}