import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <div class="confirmation-dialog-container">
      <div class="confirmation-header">
        <span class="warning-triangle">⚠️</span>
        <h2 class="confirmation-title">PRECAUCIÓN, LEE DETENIDAMENTE</h2>
      </div>
      <div class="confirmation-content">
        <p>{{ data.message }}</p>
      </div>
      <div class="confirmation-actions">
        <button mat-button (click)="onNoClick()" class="cancel-button">Cancelar</button>
        <button mat-raised-button (click)="onYesClick()" class="accept-button">
          Aceptar <span class="check-icon">✓</span>
        </button>
      </div>
    </div>
  `,
  styles: [`
    .confirmation-dialog-container {
      padding: 24px 32px;
      max-width: 550px;
    }
    .confirmation-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .warning-triangle {
      color: #f57c00;
      margin-right: 12px;
      font-size: 26px;
    }
    .confirmation-title {
      margin: 0;
      font-size: 22px;
      font-weight: 500;
      color: #333;
    }
    .confirmation-content {
      color: #333;
      line-height: 1.6;
      margin-bottom: 24px;
      font-size: 16px;
      letter-spacing: 0.2px;
    }
    .confirmation-content p {
      margin: 0;
    }
    .confirmation-actions {
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin: 0;
    }
    .accept-button {
      background-color: #3d893d;
      color: white;
      padding: 6px 16px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
    }
    .check-icon {
      margin-left: 8px;
    }
    .cancel-button {
      margin-right: 10px;
      color: #333;
      background-color: #e6e6e6;
      padding: 6px 16px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
    }
  `]
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
} 