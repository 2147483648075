<section class="message">

    <!-- Header: -->
    <header class="header" [ngClass]="{ 'm-left': app.showSidebar }">
        <div class="container" (click)="showContactInfo(otherUser?.contactId.id)">
            <img [src]="otherUser?.contactId.thumbnailUrl || './assets/guest.png'" alt="" />
            <div class="message-details">
                <h4 class="name-container">
                    <span *ngIf="otherUser?.contactId.conversationType=='whatsapp'" class="fi fi-{{ otherUser?.contactId.countryCode | lowercase }}"></span>
                    <img *ngIf="otherUser?.contactId.conversationType=='instagram'" class="worldwide-icon" src="assets/worldwide-icon.png"/>
                    <p [ngClass]="{'contact-name-label': otherUser?.contactId.conversationType=='instagram'}">&nbsp; {{ otherUser?.contactId.name }} {{ otherUser?.contactId.emoji }}</p>
                </h4>
                <span [class.blurred]="app.showCheck" *ngIf="app.showCheck">{{ otherUser?.contactId.phoneFormatted }}</span>
                <span *ngIf="!app.showCheck">{{ otherUser?.contactId.phoneFormatted }}</span>
            </div>
        </div>
        <div class="btns">
            <span class="emoji">
        <mat-label>Emoji</mat-label>
        <mat-select [value]="otherUser?.contactId.emoji || ''" (selectionChange)="changeEmoji($event.value)">
          <mat-option class="mat-font" value="">Neutral</mat-option>
          <mat-option class="mat-font" value="🔥">🔥 Urgente</mat-option>
          <mat-option class="mat-font" value="⭐">⭐ Importante</mat-option>
          <mat-option class="mat-font" value="⏳">⏳ Pendiente</mat-option>
          <mat-option class="mat-font" value="🧊">🧊 Frío</mat-option>
        </mat-select>
      </span>
            <app-group-list [groupList]="groupList" [defaultGroupId]="defaultGroupId" [contactId]="contactId"></app-group-list>
            <!-- <span class="search-icon" (click)="showSearchMessage()">
          <app-search-logo></app-search-logo>
        </span> -->

            <span class="menu" (click)="app.showSidebar = true" *ngIf="!app.showSidebar">
        <app-menu-logo></app-menu-logo>
      </span>
        </div>

    </header>

    <div class="list list-messages message-scroll" [ngClass]="app.showSidebar ? 'w-left' : ''" #messageslistContainer>
        <div class="sync-conversation" [class.instagram-loading-label]="conversationType === 'instagram'" *ngIf="!app.conversationFullyLoaded[otherUser?.contactId.id] && conversationType != 'whatsappApi'">
            <span *ngIf="conversationType === 'whatsapp'">Sincronizando</span>
            <span *ngIf="conversationType === 'instagram' || conversationType === 'messenger' " (click)="app.loadMoreMetaConversation(otherUser.contactId.id,conversationType)">Cargar más mensajes</span>
        </div>
        <!-- Message Preview -->
        <div *ngFor="let message of messages">
            <div *ngIf="message.type !== 0 || conversationType === 'instagram'" class="message-preview" [ngClass]="message.fromMe === true ? 'my-msg' : 'friend-msg'">
                <span class="reschedule-icon" *ngIf="message.style == 'rescheduled'">
                    <img src="assets/reschedulingmsg.png" />
                </span>                
                <div class="msg" [ngClass]="message.style == 'rescheduled' ? 'rescheduled-message' : ''" >
                    <span class="msg-body" *ngIf="message.type === 0" [innerHTML]="message.body"
                    [ngStyle]="{
                        'font-weight': (message.body === 'You have been mentioned in a story') ? '500' : '400',
                        'font-style': (message.body === 'You have been mentioned in a story') ? 'normal' : 'italic',
                        'color': (message.body === 'You have been mentioned in a story') ? '#000000' : 'rgb(114, 114, 114)'}">
                    </span>
                    <span class="msg-body" *ngIf="message.type === 1" [innerHTML]="message.body"     [ngStyle]="message.style == 'deleted' || message.style == 'rescheduled' ? {'font-weight': '400', 'font-style': 'italic', 'color': 'rgb(114, 114, 114)'} : {}">></span>
                    <div class="msg-body">
                        <img [src]="message.fileDownloadUrl" *ngIf="message.type === 2" [ngClass]="message.uploading ? 'msg-blur' : ''" (click)="openImageDialog(message.fileDownloadUrl)" />
                        
                        <div *ngIf="message.type === 0 && (message.fileDownloadUrl !== '' && message.fileDownloadUrl !== null)" class="file mt">
                            <img class="story-content" [src]="message.fileDownloadUrl" (error)="loadVideoInstead(message)" (click)="openImageDialog(message.fileDownloadUrl)" *ngIf="!message.useVideo">

                            <!-- Fallback to video if image fails to load -->
                            <video class="story-content" *ngIf="message.useVideo" controls>
                                <source [src]="message.fileDownloadUrl" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        <div *ngIf="message.type === 3" class="file mt">
                            <video width="100%" controls>
                            <source [src]="message.fileDownloadUrl" type="video/mp4" />
                            Your browser does not support the video tag.
                            </video>
                        </div>

                        <div *ngIf="message.type === 4" class="file">
                            <img class="file-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC"/>
                            <a [href]="message.fileDownloadUrl" class="download" download target="_blank">
                            <span class="uploading-icon"><mat-icon>download</mat-icon></span>
                            </a>
                        </div>

                        <audio controls *ngIf="message.type === 5" style="max-width: 100%">
                            <source [src]="message.fileDownloadUrl" type="audio/ogg" />
                            Your browser does not support the audio element.
                        </audio>

                        <div *ngIf="message.uploading" (click)="cancelUploading(message.id)">
                            <div class="uploading"></div>
                            <span class="uploading-icon"><mat-icon>clear</mat-icon></span>
                        </div>
                    </div>

                    <span class="msg-body" *ngIf="message.type !== 1 && message.caption==''" [innerHTML]="message.fileName"></span>
                    <span class="msg-body" *ngIf="message.type !== 1 && message.caption!=''" [innerHTML]="message.caption"></span>

                    <div class="time">
                        <!-- <small>{{ message.timestamp | date: 'h:mm a'}}</small> -->
                        <small>{{
                            convertUtcToLocal(message.timestamp)
                            | date : "h:mm a | dd MMM YYYY"
                            }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="mark-as-read" (click)="markAsRead()" *ngIf="
            otherUser.contactId.unreadMessages > 0 &&  app.conversationInitiallyLoaded[otherUser?.contactId.id]">       
            <button *ngIf="!otherUser.contactId.testing" mat-button color="primary">Marcar como leído</button>
        </div>
    </div>

    <emoji-mart *ngIf="isEmojiShown" (emojiClick)="addEmoji($event)" title="Pick your emoji…" emoji="point_up" [ngClass]="app.showSidebar ? 'w-left emoji-wrap' : 'emoji-wrap'">
    </emoji-mart>

    <section class="quick-reply" [ngClass]="app.showSidebar ? 'w-left' : ''" *ngIf="app.searchKeyword.length > 0 || app.showQuickReplies">
        <app-quick-reply [search]="app.searchKeyword" [replies]="replies" [deleteReply]="deleteReply"></app-quick-reply>
    </section>
    <section class="quick-reply" [ngClass]="app.showSidebar ? 'w-left' : ''" *ngIf="app.showReplyTemplates">
        <app-reply-templates></app-reply-templates>
    </section>
    <div class="audio-recorder" *ngIf="isAudioRecordShown === true">
        <app-media-recorder (endAudioRecord)="endAudioRecord($event)" [name]="otherUser.contactId.id" [participantId]="participantId"  [conversationType]="conversationType" [updateMessage]="updateMessage"></app-media-recorder>
    </div>
    <div class="typing-label" *ngIf="otherUser?.contactId.testing && app.conversationTyping[otherUser?.contactId.id]">
        <div class="bubble">
            <span class="typing-text">Escribiendo &nbsp;&nbsp;</span>
            <div class="dot-typing">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
            </div>
        </div>
    </div>
    <section class="input-message" [ngClass]="app.showSidebar ? 'w-leftinput' : ''">
        <div class="disabled-input" *ngIf="isAudioRecordShown === true"></div>
        <div class="container">
            <textarea #input
            class="input"
            type="text"
            [placeholder]="isConversationLocked ? 'Selecciona una plantilla.' : ((conversationType === 'whatsappApi' && !app.appConfig.manual && !this.manual) ? 'Conversación bloqueada' : (app.quickReplyState === 'list' ? 'Escribe tu mensaje aquí' : 'Escribe tu respuesta rápida aquí'))"
            [ngModel]="app.quickReplyState === 'list' ? textMsg : app.newReply.message"
            (ngModelChange)="app.quickReplyState === 'list' ? (textMsg = $event) : (app.newReply.message = $event)"
            (keydown.enter)="handleEnterPress($event)"
            (focusout)="focusOut()"
            (input)="handleMessageChange($event)"
            [disabled]="(conversationType === 'whatsappApi' && !app.appConfig.manual && !this.manual) || isConversationLocked"
            [style.background-color]="(conversationType === 'whatsappApi' && !app.appConfig.manual && !this.manual) || isConversationLocked ? '#B2B4B2' : ''"
            [mentionConfig]="{ triggerChar: '/' }"
            (searchTerm)="app.mentionChange($event)"
            (opened)="app.openedMention($event)"
            (closed)="app.closedMention($event)">
            </textarea>
            <div class="file-list" *ngIf="fileView">
                <div *ngFor="let file of extra" class="file-item">
                    <div *ngIf="file.type === 2">
                        <img [src]="
                file.type === 2
                  ? file.data
                  : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC'
              " />
                    </div>
                    <div *ngIf="file.type === 4" class="file">
                        <img class="file-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC"
                        />
                        <div class="file-info">
                            <span><b>{{ file.data.name }}</b></span>
                            <!-- <span>{{file.data.type}}</span> -->
                        </div>
                    </div>

                    <div *ngIf="file.type === 3" class="file">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB90lEQVR4nO2bvUoDQRSFv8JCwR/SWIilP7H1J76A2FgIwUfQIOgLCCm2EiwlpQ9hIWohaJWgYmllYaNJpUaIIGKxsjALgyQxwd3NZO89cAjsDjtzzszee3d2AwqFokuMAkXgDvgA/AQZ9PcAnAI7wCQJYxmoJSy6Hb+AEjCehPgs8O6A6GasmcmJFZcOCA14BGwDN7+OfwKrcYmfc0B4SM8a1wbwYp17A6biMKDgqAEBpoFn63w5DgOKDggPud9kfPMmIIZt1qM2wHNAeMiTFmM8sNpcpdmAbyDXZIwT5lzYJpNWA3wT7DaNaBsVq81amg3wO2BBugFF6QZ4akCE8ByYUV0B6C2AxgA0CKJZAE2DaB2AFkL0XSVYBhaAQfN7LKkQqgJjTa67AtxLMKDU5toDwC7wmmYDvA76CHZwDq1dHXEG2C9gziQbECIPPEo2IHwRey3ZAMw7P9EGDEk3ICc9BlSkGpCXmgWywPk/++tLAzJprgRL0p8FatKfBn0TzRdNXl+Sth/g94BqALoCooPnwJLWWwCNAWgQRLMAmgbROgAthEi4Eiw6kNd7+p3glgOCumXwKW1kyDogqFvOEDEuHBDVKYO/90SOWaDugLi/WI9j9u09+qoDIlvxyWy2xIoRYA+4BRoOiG6YsQRjGo5bvEJBuvADFvgfWs6iKe4AAAAASUVORK5CYII="
                        />
                        <div class="file-info">
                            <span><b>{{ file.data.name }}</b></span>
                            <!-- <span>{{file.data.type}}</span> -->
                        </div>
                    </div>
                    <div class="close-btn" (click)="removeFile(file.ind)">
                        <mat-icon>clear</mat-icon>
                    </div>
                </div>
            </div>
            <div class="input-toolbar">
                <div class="d-flex">
                    <div class="disabled-input" *ngIf="
              app.quickReplyState === 'create' || app.quickReplyState === 'edit'
            "></div>
                    <span  *ngIf="conversationType == 'whatsappApi' && !isConversationLocked" class="attach" (click)="showAddFile('file-input-general')">
            <app-attach-logo></app-attach-logo>
            <input type="file" id="file-input-general" style="display: none" (change)="handleFileUpload($event)" multiple />
          </span>
                    <span *ngIf="!isConversationLocked" class="attach"  (click)="showAddFile('file-input-image')">
            <app-image-logo></app-image-logo>
            <input type="file" id="file-input-image" style="display: none" (change)="handleFileUpload($event)" multiple
              accept="image/*" />
          </span>
                    <span *ngIf="!isConversationLocked" class="video-message" (click)="showAddFile('file-input-video')">
            <app-video-logo></app-video-logo>
            <input type="file" id="file-input-video" style="display: none; height: 50px; width: 200px;"
              (change)="handleFileUpload($event)" multiple accept="video/*" />
          </span>
                    <mat-divider *ngIf="!isConversationLocked" [vertical]="true"></mat-divider>
                    <span *ngIf="!isConversationLocked" class="emojis" (click)="showEmoji()">
            <app-emoji-logo *ngIf="!isEmojiShown"></app-emoji-logo>
            <app-edge-x-logo *ngIf="isEmojiShown"></app-edge-x-logo>
            </span>
                    <mat-divider *ngIf="!isConversationLocked" [vertical]="true"></mat-divider>
                    <span *ngIf="!isConversationLocked" class="voice-message" (click)="showAudioRecord()">
            <app-voice-logo></app-voice-logo>
            </span>
            <mat-divider  [vertical]="true"></mat-divider>
            <span *ngIf="conversationType!='whatsappApi' || !isConversationLocked" class="fast-answer" 
                (click)="app.showQuickReplies ? (app.showQuickReplies = false): (app.showQuickReplies = true)">
            <app-fast-logo></app-fast-logo>
            </span>
            <span *ngIf="conversationType == 'whatsappApi' && isConversationLocked" class="fast-answer" 
                (click)="app.showReplyTemplates = !app.showReplyTemplates">
                <mat-icon>view_list</mat-icon>
            </span>
            
            <mat-divider [vertical]="true"></mat-divider>
            <span *ngIf="conversationType=='whatsappApi'&& !app.appConfig.manual && app.role.toLowerCase() =='proia'" class="activate-ai" matTooltip="IA Activada. Click aquí para pasar a modo Manual">            
                <mat-checkbox [checked]="!manual" (change)="toggleAI($event.checked)">
                    <img src="assets/chat-ai-mode.svg"/>
                </mat-checkbox>
            </span>
                </div>
                <div class="d-flex">
                    <div *ngIf="!isConversationLocked">
                        <span class="send-logo" [ngClass]="{active : extra.length > 0 || (app.quickReplyState === 'list' ? textMsg : app.newReply.message)}"
                            (click)="extra.length > 0 || (app.quickReplyState === 'list' ? textMsg : app.newReply.message) ? onRescheduleSend() : $event.stopPropagation()">
                            <app-schedule-logo matTooltip="Programar Mensaje" *ngIf="app.quickReplyState === 'list'"></app-schedule-logo>
                        </span>
                    </div>
                    <div>
                        <span class="send-logo" [ngClass]="{active : extra.length > 0 || (app.quickReplyState === 'list' ? textMsg : app.newReply.message)}" (click)="onSend()">
                            <app-send-logo matTooltip="Enviar" *ngIf="app.quickReplyState === 'list'"></app-send-logo>
                            <span class="quick-reply-button" *ngIf="app.quickReplyState === 'create'">Crear Respuesta Rápida</span>
                            <span class="quick-reply-button" *ngIf="app.quickReplyState === 'edit'">Actualizar Respuesta Rápida</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>