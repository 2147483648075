import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-dialogue',
  templateUrl: './image-dialogue.component.html',
  // template: ` <img [src]="data" style="max-width: 50%; max-height: 50%;" /> `,
  styleUrls: ['./image-dialogue.component.scss'],
})
export class ImageDialogueComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ImageDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
