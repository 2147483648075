import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from '../../../app.service';

// Define the message interfac
export interface ChatMessage {
  role: 'user' | 'assistant';
  message: string;
}

@Component({
  selector: 'app-prompt-helper-menu',
  templateUrl: './prompt-helper-menu.component.html',
  styleUrls: ['./prompt-helper-menu.component.scss']
})
export class PromptHelperMenuComponent implements OnInit {
  @Input() chatMessages: ChatMessage[] = [];
  @Input() isProcessingResponse = false;
  @Input() currentInstructions = '';
  
  @Output() sendMessage = new EventEmitter<string>();
  @Output() closeMenuEvent = new EventEmitter<void>();
  
  menuExtraDetails = '';
  assistantProfilePic: string;
  
  constructor(private snackBar: MatSnackBar,public app: AppService) {
    // Select a random profile picture when the component initializes
    const profilePics = this.app.profilePictures;
    const randomIndex = Math.floor(Math.random() * profilePics.length);
    this.assistantProfilePic = profilePics[randomIndex];
  }
  
  ngOnInit() {
  }
  
  onEnhanceInstructions(): void {
    if (!this.menuExtraDetails.trim()) {
      this.snackBar.open('Por favor, ingresa algunos detalles para mejorar la descripción', 'Cerrar', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['custom-snackbar']
      });
      return;
    }
    
    // Emit the message text to parent
    this.sendMessage.emit(this.menuExtraDetails);
    
    // Clear input field after sending
    this.menuExtraDetails = '';
  }
  
  // Helper method to handle Enter key in textarea
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onEnhanceInstructions();
    }
  }

  /**
   * Closes the prompt helper menu
   */
  closeMenu(): void {
    this.closeMenuEvent.emit();
  }
} 