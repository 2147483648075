import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-help-logo',
  templateUrl: './help-logo.component.html',
  styleUrls: ['./help-logo.component.scss']
})
export class HelpLogoComponent {

  customHeight: string = '600px'; 
  customWidth: string = '800px'; 

  constructor(
    public dialogRef: MatDialogRef<HelpLogoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
