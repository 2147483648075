import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-bot-finalize-modal',
  templateUrl: './bot-finalize-modal.component.html',
  styleUrls: ['./bot-finalize-modal.component.scss']
})
export class BotFinalizeModalComponent implements OnInit {

  constructor(
    public app : AppService,
    private dialogRef: MatDialogRef<BotFinalizeModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  finalize(): void {
    this.dialogRef.close();
    setTimeout(() => {
      this.app.refreshConversations.emit();
    }, 500);
  }
}