import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  error: string = '';
  groupBlock!: FormGroup; // Definite assignment to ensure it's initialized
  actionType: string = 'create'; // Default to 'create'
  groupData: any = null; // To hold the group data when editing

  constructor(
    private dialogRef: MatDialogRef<CreateGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // Inject the dialog data
  ) {
    this.actionType = data.action; // Get the action type ('create' or 'edit')
    this.groupData = data.group ? data.group : null; // Get the group data if available
  }

  ngOnInit(): void {
    // Initialize form based on action type
    this.groupBlock = new FormGroup({
      name: new FormControl(this.groupData ? this.groupData.name : ''),
      description: new FormControl(this.groupData ? this.groupData.description : ''),
      color: new FormControl(this.groupData ? this.groupData.color : '#000000') // Default color
    });

    // Optional: Subscribe to form changes if you want to handle updates dynamically
    this.groupBlock.valueChanges.subscribe(() => this.changeGroupInfo());
  }

  changeGroupInfo() {
    // Handle dynamic updates if needed
  }

  updateColor(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const color = inputElement.value;
    this.groupBlock.patchValue({ color });
  }
  

  create() {
    const { name, description, color } = this.groupBlock.value;
    if (name) {
      this.dialogRef.close({ name, description, color });
    } else {
      this.error = 'Por favor, introduce el nombre del grupo.';
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
