import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../shared/api.service';

@Component({
  selector: 'app-start-stimulation-dialog',
  templateUrl: './start-stimulation-dialog.component.html',
  styleUrls: ['./start-stimulation-dialog.component.scss']
})
export class StartStimulationDialogComponent implements OnInit {
  businessDescription: string = '';

  constructor(
    public dialogRef: MatDialogRef<StartStimulationDialogComponent>,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.api.sendGetRequest({ action: 'Action/GetBusinessDescription' })
      .subscribe({
        next: (res) => {
          if (res) {
            this.businessDescription = res;
          }
        },
        error: (err) => {
          console.error('Error fetching business description:', err);
        }
      });
  }

  onStart() {
    if (this.businessDescription.trim()) {
      // Save the business description before closing
      this.api.sendPostRequest({
        action: 'Action/CreateBusinessDescription',
        params: {},
        body: 
        {
        businessDescription: this.businessDescription
        }
      }).subscribe({
        next: () => {
          this.dialogRef.close({ businessDescription: this.businessDescription });
        },
        error: (err) => {
          console.error('Error saving business description:', err);
        }
      });
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}