<div class="dialog-confirmation"> 
    <h2 mat-dialog-title class="dialog-title">
      <mat-icon aria-hidden="false" aria-label="Stop Simulation">stop_circle</mat-icon>
      Detener Simulación
    </h2>
    <mat-dialog-content class="dialog-content">
      <p>¿Deseas detener la simulación o detenerla y eliminar los leads de prueba?</p>
    </mat-dialog-content>
  
    <mat-dialog-actions class="dialog-actions">
      <button
        mat-raised-button
        color="primary"
        class="stop-only-btn"
        (click)="stopOnly()"
      >
        <mat-icon>pause</mat-icon>
        <span>Detener Solo</span>
      </button>
      <button
        mat-raised-button
        color="warn"
        class="stop-delete-btn"
        (click)="stopAndDelete()"
      >
        <mat-icon>delete</mat-icon>
        <span>Detener y Eliminar</span>
      </button>
      <button
        mat-raised-button
        color="accent"
        class="cancel-btn"
        (click)="cancel()"
      >
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </button>
    </mat-dialog-actions>
  </div>  