import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-date-time-selector',
  templateUrl: './date-time-selector.component.html',
  styleUrls: ['./date-time-selector.component.scss']
})
export class DateTimeSelectorComponent {
  date: Date | undefined;
  @ViewChild('hourInput') hourInput!: ElementRef;
  @ViewChild('minuteInput') minuteInput!: ElementRef;
  period: 'AM' | 'PM' = 'AM';

  constructor(public dialogRef: MatDialogRef<DateTimeSelectorComponent>) {
    this.date = new Date();
  }
  onHourChange() {
    if (this.hourInput) {
      const newHour = Math.max(1, Math.min(12, parseInt(this.hourInput.nativeElement.value)));
      this.hourInput.nativeElement.value = newHour.toString().padStart(2, '0');
    }
  }

  onMinuteChange() {
    if (this.minuteInput) {
      const newMinute = Math.max(0, Math.min(59, parseInt(this.minuteInput.nativeElement.value)));
      this.minuteInput.nativeElement.value = newMinute.toString().padStart(2, '0');
    }
  }

  onMinuteClick(event: MouseEvent) {
  event.preventDefault();
  event.stopPropagation();
  setTimeout(() => {
    this.minuteInput.nativeElement.focus();
  }, 0);
}


  confirmDateTime() {
    if (!this.date) return; // Ensure we have a valid date selected
    const hoursOffset = this.period === 'PM' ? 12 : 0;
    let hours = parseInt(this.hourInput.nativeElement.value) % 12 + hoursOffset;
    let minutes = parseInt(this.minuteInput.nativeElement.value);
    const dateTime = new Date(this.date);
    dateTime.setHours(hours, minutes, 0);
    this.dialogRef.close(dateTime);
    
  }

}

