<section class="container">
    <div class="reply-title">
        <h4>Respuesta rápida</h4>
        <mat-icon (click)="cancelReplyMessage()" class="clear-icon">clear</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="reply-header">
        <div>
            <mat-form-field >
                <input matInput type="text" class="example-right-align" (input)="handleKeywordChange($event)" [value]="app.quickReplyState==='list'?app.searchKeyword:app.newReply.shortcut">
                <mat-icon matPrefix style="width: 1.5rem; font-size: 100%;">search</mat-icon>
            </mat-form-field>    
            <!-- <mat-form-field>
                <mat-select required value="one">
                    <mat-option value="one">First option</mat-option>
                    <mat-option value="two">Second option</mat-option>
                </mat-select>
            </mat-form-field>     -->
        </div>
        <button *ngIf="app.quickReplyState === 'list'"  mat-button color="primary" class="reply-add" (click)="createReplyMessage()">Nueva Respuesta Rápida</button>
        <button *ngIf="app.quickReplyState !== 'list'" mat-button color="accent" class="reply-add" (click)="cancelReplyMessage()">Cancelar</button>
    </div>
    <mat-divider></mat-divider>
    <div class="reply-body" *ngIf="app.quickReplyState === 'list'">
        <ng-container *ngIf="replies.length > 0">
            <div class="reply-item" *ngFor="let reply of replies | replyfilter: search">
                <div class="reply-item-shotcut">
                    <span [innerHTML]="reply.boldText || ('/' + reply.shortcut)"></span>
                </div>
                <div class="reply-item-body" (click)="addReplyMessage(reply.value)">
                    <span>{{reply.value}}</span>
                </div>
                
                <div class="reply-item-action">
                    <mat-icon (click)="deleteReply(reply.id)">delete</mat-icon>
                    <mat-icon (click)="editReply(reply)">edit</mat-icon>
                </div>
            </div>
        </ng-container>
        <div *ngIf="replies.length == 0">
            <p>No hay resultados.</p>
        </div>
    </div>
</section>