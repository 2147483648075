import { Component, OnInit, EventEmitter, Output} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { AppService } from '../../app.service';
import { ApiService } from '../api.service';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VideoModalComponent } from '../../video-modal/video-modal.component';



@Component({
  selector: 'app-init-connection-modal',
  templateUrl: './init-connection-modal.component.html',
  styleUrls: ['./init-connection-modal.component.scss']
})
export class InitConnectionModalComponent implements OnInit {

  showWhatsappAccordion = false;
  showInstagramAccordion = false;
  showWhatsappAPIAccordion = false;
  instaIsLoading = false;
  apiIsLoading = false;
  showError = false;
  showHint = false;
  showFacebookPages: boolean = false; // Default value is false
  showInstagramPage: boolean = false;
  qrdata: string = '';
  selectedPageId: string = "";
  pages :any;
  isWhatsappScanning = false;
  userId : string = "";
  constructor(
    private dialogRef: MatDialogRef<InitConnectionModalComponent>,
    private dialog: MatDialog,
    public app: AppService,
    public api: ApiService){}
  
  ngOnInit(): void {
    this.userId = this.api.userId;
    this.app.facebookConnectedEvent.subscribe((status: boolean) => {
      this.getUserPages();
      
    });
  }

  togglePageSelection(page:any) {
  }

  toggleWhatsAppAPIConnection(): void {
    this.showError = false;
    if (!this.app.isWhatsappAPIConnected) {
      this.apiIsLoading = true;
      this.api.sendPostRequest({
        action: 'Action/CreateBotAPI',
      }).subscribe({
        next: (res) => {
          if (res && !res.redirect) {
            this.app.isWhatsappAPIConnected = true;
            this.apiIsLoading = false;
          } else if (res && res.redirect && res.url) {
            const popupWidth = 600;
            const popupHeight = 800;
            const left = (window.innerWidth - popupWidth) / 2;
            const top = (window.innerHeight - popupHeight) / 2;
            const popupOptions = `width=${popupWidth},height=${popupHeight},top=${top},left=${left},resizable=yes,scrollbars=yes`;

            const popupWindow = window.open(res.url, 'popupWindow', popupOptions);
            const checkWindowClosed = setInterval(() => {
              if (popupWindow && popupWindow.closed) {
                this.apiIsLoading = false;
                clearInterval(checkWindowClosed);
              }
            }, 1000);
          }
          else
          {
            this.apiIsLoading = false;
          }
        },
        error: (err) => {
          console.error('Error connecting to WhatsApp API:', err);
          this.showError = true;
          this.apiIsLoading = false;
        }
      });
      setTimeout(() => {
        this.apiIsLoading = false;
      }, 300000);
    }
  }
  toggleWhatsAppConnection() {
    this.showError = false;
    if(!this.app.isWhatsappConnected)
    {
    this.showWhatsappAccordion = !this.showWhatsappAccordion;
      if (this.showWhatsappAccordion) {
      this.getQRCode();
      }
    }
  }

  getQRCode() {
    this.api.sendGetRequest({ action: 'Action/GetQrCode' }).subscribe(
      res => {
        if (res) {
          this.qrdata = 'data:image/png;base64,' + res;
          this.CheckSession();
        } else {
          this.app.checkIsConnectedEvent.emit();
        }
      },
      error => {
        
        this.getQRCode();
      }
    );
  }

  openVideoModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true; // Makes sure clicking outside the modal does not close it
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%'; // Adjust width as needed

    this.dialog.open(VideoModalComponent, dialogConfig);
  }

  CheckSession(isInit = true) {
    this.api.sendGetRequest({ action: 'Action/SessionCheckAndCreate' })
      .subscribe({
        next: (session) => {
          
          if (session.status == "WORKING") {
            this.app.isWhatsappConnected = true;
            this.showWhatsappAccordion = false;
          } else {
            setTimeout(() => {
              this.CheckSession();
            }, 10000);
          }
        },
        error: (error) => {
          console.error('Failed to check connection status:', error);
        }
      });
  }

  changeQrCode()
  {
    this.qrdata = "";
    this.api.sendGetRequest({ action: 'Action/GetQrCode' }).subscribe(
      res => {
        if (res) {
          this.qrdata = 'data:image/png;base64,' + res;
        } 
      },
      error => {
        console.error('Error fetching QR code:', error);
        window.location.reload();
      }
    );
  }
/*
  waitForWhatsAppScanning() {
    this.api.sendGetRequest({ action: 'Action/IsConnecting' }).subscribe(
      res => {
        if (res === 'true') {
          this.isWhatsappScanning = true;
          this.showWhatsappAccordion = false;
          this.waitForWhatsAppConnection();
        } else {
          setTimeout(() => {
            this.waitForWhatsAppScanning();
          }, 1000);
        }
      },
      error => {
        console.error('Error waiting for WhatsApp connection:', error);
        this.showWhatsappAccordion = false;
      }
    );
  }
  */
 /*
  waitForWhatsAppConnection() {
      this.api.sendGetRequest({ action: 'Action/WaitUntilConnected' }).subscribe(
        res => {
          if (res === 'true') {
            this.isWhatsappScanning  = false;
            this.app.isWhatsappConnected = true;
          }
        },
        error => {
          window.location.reload();
          /* this.app.whatsappLogout();
          console.error('Error waiting for WhatsApp connection:', error);
          this.isWhatsappScanning  = false;
          this.showWhatsappAccordion = false;
        }
      );
      */
  handleQRCodeError() {
    this.showWhatsappAccordion = false;
  }

  getUserPages() {
    this.api.sendGetRequest({action: 'Action/GetUserPages', params: {}})
      .subscribe((res: any) => {
        this.pages = res;
        if (this.pages && this.pages.length > 0) {
          this.showInstagramAccordion = true;
          this.selectedPageId = this.pages[0].pageId;
          this.showHint = true;
          this.onPageChange({pageId: this.selectedPageId});
        }
      });
  }
  
  toggleInstagramConnection() {
    this.showError = false;
      this.api.sendGetRequest({action: 'Action/GetFacebookLoginUrl', params: {}})
      .subscribe(
          (res: any) => {
            const loginWindow = window.open(res.url, '_blank');
            this.instaIsLoading = true;
            const intervalId = setInterval(() => {
              if (loginWindow && loginWindow.closed) {
                clearInterval(intervalId);
                this.instaIsLoading = false;
              }
            }, 1000);
          }
      );
  }
  
  onPageChange(event: any) {
    this.selectedPageId  = event.value ? event.value : event.pageId;
    this.api
      .sendPostRequest({
        action: 'Action/SaveFacebookPage',
        
        params: { pageId: this.selectedPageId
                },
      }).subscribe((res) => {
        
      });
      this.pages.forEach((page:any) => {
        if(page.pageId==this.selectedPageId)
        {
          if(page.inclusInstagram) this.app.isInstagramConnected = true;
          else
          this.app.isInstagramConnected = false;
        }    
      });     
  }
  
  onConfirm() {
    if(this.showInstagramAccordion || this.app.isWhatsappConnected || this.app.isWhatsappAPIConnected)
      {
        setTimeout(() => {
          this.dialogRef.close();
      }, 2000);    
    }
  
    else
      this.showError = true;
  }
}