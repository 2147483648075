import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arrow-right-logo',
  templateUrl: './arrow-right-logo.component.html',
  styleUrls: ['./arrow-right-logo.component.scss']
})
export class ArrowRightLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
