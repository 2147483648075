import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {

  @Output() closeModalEvent = new EventEmitter<void>();

  constructor(
    private dialogRef: MatDialogRef<VideoModalComponent>,
  ) { }

  ngOnInit(): void { }

  closeModal() {
    this.dialogRef.close();  // Emit an event to close the modal
  }
}