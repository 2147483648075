import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../shared/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-custom-voice',
  templateUrl: './add-custom-voice.component.html',
  styleUrls: ['./add-custom-voice.component.scss']
})
export class AddCustomVoiceComponent {
  voiceName = '';
  selectedFile: File | null = null;
  localFileUrl: any;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<AddCustomVoiceComponent>,
    private api: ApiService,
    private snackBar: MatSnackBar
  ) {}

  onFileSelected(event: any) {
    const file = event.target.files[0];
    const allowedTypes = ['audio/mp3', 'audio/mpeg', 'audio/mp4', 'audio/x-m4a'];
    const maxSize = 50 * 1024 * 1024; // 50 MB

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        this.snackBar.open('Por favor, sube un archivo MP3 o M4A válido.', 'Cerrar', {
          duration: 3000,
          panelClass: ['custom-snackbar']
        });
        return;
      }
      
      if (file.size > maxSize) {
        this.snackBar.open('El archivo es demasiado grande. Por favor, sube un archivo menor a 50MB.', 'Cerrar', {
          duration: 3000,
          panelClass: ['custom-snackbar']
        });
        return;
      }

      this.localFileUrl = URL.createObjectURL(file);
      const audio = new Audio();
      audio.src = this.localFileUrl;
      audio.addEventListener('loadedmetadata', () => {
        if (audio.duration < 10) {
          this.snackBar.open('El archivo debe tener al menos 10 segundos de duración.', 'Cerrar', {
            duration: 3000,
            panelClass: ['custom-snackbar']
          });
          return;
        }
        // File passed all validations
        this.selectedFile = file;
      });
    }
  }

  uploadVoice() {
    if (!this.voiceName.trim() || !this.selectedFile) {
      this.snackBar.open('Debe proporcionar un nombre y un archivo MP3.', 'Cerrar', {
        duration: 3000,
        panelClass: ['custom-snackbar']
      });
      return;
    }

    this.loading = true;
    const formData = new FormData();
    formData.append('voiceName', this.voiceName);
    formData.append('file', this.selectedFile);

    this.api.sendFormDataRequest({
      action: 'Action/UploadCustomVoice',
      formData: formData
    }).subscribe({
      next: (res) => {
        this.loading = false;
        this.snackBar.open('Voz subida exitosamente.', 'Cerrar', {
          duration: 3000,
          panelClass: ['custom-snackbar']
        });
        const voice = {
          id: res.id,
          name: this.voiceName,
          voiceLink: res.voiceLink,
          localUrl: this.localFileUrl
        };
        this.dialogRef.close(voice);
      },
      error: (error) => {
        console.error('Error al subir la voz', error);
        this.loading = false;
        this.snackBar.open('Error al subir la voz. Inténtelo de nuevo.', 'Cerrar', {
          duration: 3000,
          panelClass: ['custom-snackbar']
        });
      }
    });
  }
}