<div class="prompt-menu-container" (click)="$event.stopPropagation()">
  <div class="prompt-menu-header">
    <div class="prompt-menu-icon">
      <mat-icon>assistant</mat-icon>
    </div>
    <span>Asistente de Prompt</span>
    <button mat-icon-button class="prompt-close-button" (click)="closeMenu()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="prompt-chat-container">
    <div class="prompt-chat-message" 
         *ngFor="let message of chatMessages" 
         [ngClass]="{'assistant': message.role === 'assistant', 'user': message.role === 'user'}">
      <div class="message-avatar" *ngIf="message.role === 'assistant'">
        <img [src]="assistantProfilePic" alt="Assistant" class="avatar-image">
      </div>
      <div class="message-content">
        <p>{{ message.message }}</p>
      </div>
    </div>
    
    <!-- Typing indicator when processing response -->
    <div class="typing-indicator" *ngIf="isProcessingResponse">
      <span class="typing-text">Analizando</span>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
  
  <div class="prompt-input-container">
    <mat-form-field appearance="outline" class="prompt-input-field">
      <textarea 
        matInput 
        [(ngModel)]="menuExtraDetails" 
        name="promptInput" 
        placeholder="Escribe tu respuesta..."
        rows="1"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="4"></textarea>
    </mat-form-field>
    <button mat-mini-fab color="primary" class="prompt-send-button" (click)="onEnhanceInstructions()">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div> 