<div class="group-form">
  <h3>{{ actionType === 'create' ? 'Crear Grupo' : 'Editar Grupo' }}</h3>
  <mat-divider></mat-divider>
  <form [formGroup]="groupBlock" fxLayout="column">
    <div>
      <mat-form-field class="full-width">
        <mat-label>Nombre:</mat-label>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width description-field">
        <mat-label>Descripción:</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div>
      <!-- Color Picker Input -->
      <mat-form-field class="full-width">
        <mat-label>Color:</mat-label>
        <input type="color" matInput formControlName="color" (input)="updateColor($event)" />
      </mat-form-field>

      <!-- Text Input for Copying and Pasting Color -->
      <mat-form-field class="full-width">
        <mat-label>Código de Color:</mat-label>
        <input type="text" matInput [value]="groupBlock.value.color" (input)="updateColor($event)" />
      </mat-form-field>
    </div>
  </form>
  <div *ngIf="error !== ''" style="color: red;" [innerHTML]="error" class="pl-50"></div>
  <div style="margin-top: 1rem">
    <button mat-raised-button color="primary" (click)="create()">
      {{ actionType === 'create' ? 'Crear' : 'Guardar' }}
    </button>
    <button style="margin-left: 1rem" mat-button color="primary" (click)="cancel()">Cancelar</button>
  </div>
</div>
