import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emoji-logo',
  templateUrl: './emoji-logo.component.html',
  styleUrls: ['./emoji-logo.component.scss']
})
export class EmojiLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
