import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edge-green-msg-logo',
  templateUrl: './edge-green-msg-logo.component.html',
  styleUrls: ['./edge-green-msg-logo.component.scss']
})
export class EdgeGreenMsgLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
