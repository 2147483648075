import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/api.service';
import { AppService } from '../app.service';
import { ColumnConfigModalComponent } from '../column-config-modal/column-config-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { ChangeDetectorRef } from '@angular/core';
import { AddCustomVoiceComponent } from '../add-custom-voice/add-custom-voice.component';
import { AudioPreloadService } from '../shared/audio-preload.service';
import { ChatMessage } from '../shared/components/prompt-helper-menu/prompt-helper-menu.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { YoutubeVideoModalComponent } from '../youtube-video-modal/youtube-video-modal.component';

@Component({
  selector: 'app-whatsapp-api-config',
  templateUrl: './whatsapp-api-config.component.html',
  styleUrls: ['./whatsapp-api-config.component.scss']
})
export class WhatsappApiConfigComponent implements OnInit, AfterViewInit, OnDestroy {
  form: any;
  intelligenceLevel = 0;
  loading = false;
  isDirty = false; // Track if there are unsaved changes
  menuExtraDetails = '';
  userId = '';
  audioElement!: HTMLAudioElement;
  isPlaying: boolean = false;
  playingVoiceId: number | null = null;
  customVoiceAudioUrl: string = '';
  isAssistantUsed = false;

  // Add this property to hold chat messages
  chatMessages: ChatMessage[] = [];

  // Add a property to track if we're waiting for the assistant's response
  isProcessingResponse: boolean = false;

  @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;

  constructor(
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    public app: AppService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<WhatsappApiConfigComponent>,
    private http: HttpClient,
    private audioPreloadService: AudioPreloadService,
    private snackBar: MatSnackBar)
    {this.dialogRef.disableClose = true;}

  ngOnInit(): void {
    this.userId = this.api.userId;
    this.initializeForm();
    this.updateIntelligenceScore();
    this.initializeChat(); // Add this line to initialize the chat

    // Intercept backdrop clicks (outside dialog clicks)
    this.dialogRef.backdropClick().subscribe(() => {
      this.attemptClose();
    });

    // Intercept keydown events (e.g., ESC)
    this.dialogRef.keydownEvents()
      .pipe(filter((event: any) => event.key === 'Escape'))
      .subscribe(() => {
        this.attemptClose();
      });
  }
  ngAfterViewInit(): void {
    this.audioElement = this.audioPlayer.nativeElement;
  }

  initializeForm() {
    this.form = {
      id: this.app.appConfig.id,
      name: this.app.appConfig.name || '',
      instructions: this.app.appConfig.instructions,
      randomAudios: this.app.appConfig.randomAudios,
      followUp: !!this.app.appConfig.followUpInstructions,
      followUpInstructions: this.app.appConfig.followUpInstructions || '',
      respondWithAudio: !!this.app.appConfig.voiceId,
      manual: !this.app.appConfig.manual,
      voiceId: this.app.appConfig.voiceId ? Number(this.app.appConfig.voiceId) : null,
      test: false 
    };
  }

  clearExtraDetails() {
    this.menuExtraDetails = '';
  }

  // Add this method to initialize chat with default messages
  initializeChat() {
    this.chatMessages = [];
  }
  onEnhance(): void {
    this.isAssistantUsed = true;
    if (this.chatMessages.length === 0) {
      this.isProcessingResponse = true;
      const enhanceRequest = {
        prompt: "",
        currentInstructions: this.form.instructions,
        chatList: []
      };
  
      this.api.sendPostRequest({
        action: 'Action/EnhanceInstructions',
        body: enhanceRequest 
      }).subscribe({
        next: (res) => {
          console.log("respons",res);
          // Update form with enhanced instructions
          this.form.instructions = res.instructions;
          this.onFormChange();
          this.updateIntelligenceScore();
          this.isProcessingResponse = false; // Hide typing indicator
        
          // Add assistant response to chat
          this.chatMessages = res.chatList;
          
          this.snackBar.open('¡Descripción mejorada con éxito!', 'Cerrar', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-success-snackbar']
          });
        },
        error: (error) => {
          console.error('Failed to enhance instructions', error);
          this.isProcessingResponse = false; // Hide typing indicator
          
          // Add error message to chat
          this.addMessage('assistant', 'Lo siento, ha ocurrido un error al procesar tu solicitud. Por favor, inténtalo de nuevo.');
          
          this.snackBar.open('Error al mejorar las instrucciones', 'Cerrar', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-error-snackbar']
          });
        }
      });
    }
  }

  // Method to handle user pressing Enter in the input
  onEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.onEnhanceInstructions(this.menuExtraDetails);
    }
  }

  selectVoice(voiceId: number, isCustom = false) {
    if (!this.form.respondWithAudio) {
      return;
    }
    this.form.voiceId = Number(voiceId);
    if (this.audioElement) {
      this.audioElement.pause();
    }
    if (!isCustom) {
      this.playAudio(voiceId);
    }
  }

  playAudio(voiceId: number) {
    this.audioElement.src = `assets/vocals/${voiceId}.mp3`;
    this.audioElement.volume = 0.3;
    this.audioElement.play();
  }
  
  onFormChange() {
    const normalize = (str: string) =>
      str.replace(/\s/g, '').toLowerCase();
    if (normalize(this.form.instructions) == normalize(this.app.appConfig.instructions)) {
      this.isDirty = false;
    } else {
      this.isDirty = true;
    }
  }
  updateIntelligenceScore() {
    let score = 0;
    const recommendedDescriptionLength = 650;
    const descriptionLength = this.form.instructions?.length;
    if (descriptionLength > 0) {
      score += Math.min(80, Math.floor((descriptionLength / recommendedDescriptionLength) * 80));
    }
    if (this.form.followUp) {
      score += 10;
    }
    if (this.form.randomAudios) {
      score += 5;
    }
    if (this.form.respondWithAudio) {
      score += 5;
    }
    this.intelligenceLevel = Math.min(score, 100);
  }
  
  handleSaveConfig()
  {
    if(this.isAssistantUsed)
    {
      this.showConfirmationDialog();
    }
    else
    {
      this.saveConfig();
    }

  }
  showConfirmationDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      panelClass: 'confirmation-dialog-panel',
      data: {
        message: 'Si has presionado el botón de "Asistente de Prompt", y ahora has presionado "Guardar". Si Aceptas se eliminará el prompt anterior y se reemplazará por la versión generada junto con el Asistente de Prompt. ¿Deseas continuar?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.saveConfig();
      }
      else
      {
        this.form.instructions= this.app.appConfig.instructions
      }
    });
  }

  saveConfig(){
    this.loading = true;
      if (this.form.followUp) {
      this.form.followUpInstructions = "Your follow-up instructions...";
    } else {
      this.form.followUpInstructions = "";
    }
  
    // Update voiceId and randomAudios
    if (!this.form.respondWithAudio) {
      this.form.voiceId = null;
      this.form.randomAudios = false;
    } else {
      this.form.voiceId = this.form.voiceId ? Number(this.form.voiceId) : null;
    }
  
    // Update appConfig
    this.app.appConfig.instructions = this.form.instructions;
    this.app.appConfig.randomAudios = this.form.randomAudios;
    this.app.appConfig.followUpInstructions = this.form.followUpInstructions;
    this.app.appConfig.voiceId = this.form.voiceId;
    this.app.appConfig.manual = !this.form.manual;
  
    // Build the request object
    const updateBotRequest = {
      id: this.form.id,
      name: this.form.name || '',
      instructions: this.form.instructions,
      followUpInstructions: this.form.followUpInstructions,
      voiceId: this.form.voiceId,
      manual: this.form.manual,
      randomAudios: this.form.randomAudios,
      test: this.form.test || false,
    };
  
    this.api.sendPostRequest({
      action: 'Action/UpdateBotAPI',
      body: updateBotRequest
    }).subscribe({
      next: (res) => {
        this.loading = false;
        this.isDirty = false; 
        this.snackBar.open('Configuración guardada correctamente', 'Cerrar', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-snackbar']
        });
      },
      error: (error) => {
        console.error('Failed to update', error);
        console.error('Validation Errors:', error.error.errors);
        this.loading = false;
      }
    });
  }  

  updateBotAPI() {
    this.loading = true;

    // Update follow-up instructions
    if (this.form.followUp) {
      this.form.followUpInstructions = "Your follow-up instructions...";
    } else {
      this.form.followUpInstructions = "";
    }

    // Update voiceId and randomAudios
    if (!this.form.respondWithAudio) {
      this.form.voiceId = null;
      this.form.randomAudios = false;
    } else {
      this.form.voiceId = this.form.voiceId ? Number(this.form.voiceId) : null;
    }

    // Update appConfig
    this.app.appConfig.instructions = this.form.instructions;
    this.app.appConfig.randomAudios = this.form.randomAudios;
    this.app.appConfig.followUpInstructions = this.form.followUpInstructions;
    this.app.appConfig.voiceId = this.form.voiceId;
    this.app.appConfig.manual = !this.form.manual;

    // Build the request object
    const updateBotRequest = {
      id: this.form.id,
      name: this.form.name || '',
      instructions: this.form.instructions,
      followUpInstructions: this.form.followUpInstructions,
      voiceId: this.form.voiceId,
      manual: this.form.manual,
      randomAudios: this.form.randomAudios,
      test: this.form.test || false,
    };

    this.api.sendPostRequest({
      action: 'Action/UpdateBotAPI',
      body: updateBotRequest
    }).subscribe({
      next: (res) => {
        this.isDirty = false; 
        this.getColumnSuggestionsAndOpenModal();
      },
      error: (error) => {
        console.error('Failed to update', error);
        console.error('Validation Errors:', error.error.errors);
        this.loading = false;
        alert('Error al actualizar la configuración');
      }
    });
  }

  getColumnSuggestionsAndOpenModal() {
    this.api.sendPostRequest({
      action: 'Action/GetColumnSuggestions',
      body: { description: this.form.instructions }
    }).subscribe({
      next: (res) => {
        this.dialogRef.close();
        setTimeout(() => {
          this.openColumnConfigModal(res);
        }, 300);
      },
      error: (error) => {
        console.error('Failed to update', error);
        this.loading = false;
        alert('Error al actualizar la configuración');
      }
    });
  }

  openColumnConfigModal(columnSuggestions: any) {
    this.dialog.open(ColumnConfigModalComponent, {
      width: '400px',
      data: { columnSuggestions: columnSuggestions }
    });
  }

  onManualCheckboxChange() {
    // Update follow-up instructions
    if (this.form.followUp) {
      this.form.followUpInstructions = "Your follow-up instructions...";
    } else {
      this.form.followUpInstructions = "";
    }

    // Update voiceId and randomAudios
    if (!this.form.respondWithAudio) {
      this.form.voiceId = null;
      this.form.randomAudios = false;
    } else {
      this.form.voiceId = this.form.voiceId ? Number(this.form.voiceId) : null;
    }

    // Update appConfig
    this.app.appConfig.instructions = this.form.instructions;
    this.app.appConfig.randomAudios = this.form.randomAudios;
    this.app.appConfig.followUpInstructions = this.form.followUpInstructions;
    this.app.appConfig.voiceId = this.form.voiceId;
    this.app.appConfig.manual = !this.form.manual;

    // Build the request object
    const updateBotRequest = {
      id: this.form.id,
      name: this.form.name || '',
      instructions: this.form.instructions,
      followUpInstructions: this.form.followUpInstructions,
      voiceId: this.form.voiceId,
      manual: this.form.manual,
      randomAudios: this.form.randomAudios,
      test: this.form.test || false,
    };

    this.api.sendPostRequest({
      action: 'Action/UpdateBotAPI',
      body: updateBotRequest
    }).subscribe({
      next: (res) => {
        this.isDirty = false;
        console.log('Updated bot successfully', res);
      },
      error: (error) => {
        console.error('Failed to update', error);
        console.error('Validation Errors:', error.error.errors);
      }
    });
  }

  attemptClose() {
    console.log("try ",this.isDirty)
    if (this.isDirty && !this.loading) {
      // Open confirmation dialog
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: {
          title: '', // No title
          message: 'Tienes cambios sin guardar. ¿Deseas salir sin guardar?'
        }
      });
  
      // Wait for user response
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // User confirms: Close WhatsappApiConfigComponent
          this.dialogRef.close();
        }
        this.isDirty = false;         
      });
    } else {
      this.dialogRef.close();
    }
  }  
  
  cancel() {
    this.attemptClose();
  }

  openAddCustomVoiceDialog() {
    const dialogRef = this.dialog.open(AddCustomVoiceComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("ha xno rja3",result);
      if (result && result.id && result.name) {
        this.app.customVoicesId = [];
        this.app.customVoicesId.push(result);
        this.cdr.detectChanges();
      }
    });
  }

  attemptDeleteVoice(voiceId: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: 'Eliminar voz personalizada',
        message: '¿Estás seguro de que deseas eliminar esta voz personalizada?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.api.sendPostRequest({
          action: 'Action/Dele      teCustomVoice'
        }).subscribe({
          next: (res) => {
            this.app.customVoicesId = [];
            this.loading = false;
            this.snackBar.open('Voz personalizada eliminada correctamente', 'Cerrar', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-success-snackbar']
            });
            this.cdr.detectChanges();
          },
          error: (error) => {
            console.error('Failed to delete custom voice', error);
            this.loading = false;
            this.snackBar.open('Error al eliminar la voz personalizada', 'Cerrar', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-error-snackbar']
            });
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    // Make sure to stop audio playback when component is destroyed
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.src = '';
    }
  }

  togglePlayCustomVoice(voiceId: number) {
    const voice = this.app.customVoicesId.find(v => v.id === voiceId);
    if (!voice) {
      this.snackBar.open('No se encontró la voz personalizada', 'Cerrar', {
        duration: 3000,
        verticalPosition: 'top'
      });
      return;
    }
      const audioUrl = voice.localUrl ? voice.localUrl 
                                    : 'https://glpipeline8b0b.blob.core.windows.net/audios/' + voice.voiceLink;
  
    // If the user is playing the same voice, toggle
    if (this.isPlaying && this.playingVoiceId === voiceId) {
      this.audioElement.pause();
      this.isPlaying = false;
      this.playingVoiceId = null;
      return;
    }
  
    // Pause any currently playing audio
    if (this.isPlaying && this.playingVoiceId !== voiceId) {
      this.audioElement.pause();
    }
  
    // Update playing state
    this.playingVoiceId = voiceId;
    this.isPlaying = true;
  
    // Point the <audio> element to the chosen URL
    this.audioElement.src = audioUrl;
    this.audioElement.volume = 0.5;
  
    this.audioElement.onended = () => {
      this.isPlaying = false;
      this.playingVoiceId = null;
    };
  
    this.audioElement.onerror = () => {
      this.isPlaying = false;
      this.playingVoiceId = null;
    };
  
    this.audioElement.play().catch(error => {
      console.error('Error reproduciendo audio', error);
    });
  }  

  // Method to process messages from prompt helper component
  onEnhanceInstructions(userInput: string): void {
    this.isAssistantUsed = true;
    if (!userInput || !userInput.trim()) {
      return;
    }

    // Add user message to chat
    this.addMessage('user', userInput);
    
    // Show loading state for typing indicator
    this.isProcessingResponse = true;
    
    // Prepare API request
    const enhanceRequest = {
      prompt: userInput,
      currentInstructions: this.form.instructions,
      chatList: this.chatMessages
    };

    // Send request to API
    this.api.sendPostRequest({
      action: 'Action/EnhanceInstructions',
      body: enhanceRequest 
    }).subscribe({
      next: (res) => {
        console.log("response", res);
        // Update form with enhanced instructions
        this.form.instructions = res.instructions;
        this.onFormChange();
        this.updateIntelligenceScore();
        this.isProcessingResponse = false;
      
        // Update chat with server response
        this.chatMessages = res.chatList;
        
        this.snackBar.open('¡Descripción mejorada con éxito!', 'Cerrar', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-success-snackbar']
        });
      },
      error: (error) => {
        console.error('Failed to enhance instructions', error);
        this.isProcessingResponse = false;
        
        // Add error message to chat
        this.addMessage('assistant', 'Lo siento, ha ocurrido un error al procesar tu solicitud. Por favor, inténtalo de nuevo.');
        
        this.snackBar.open('Error al mejorar las instrucciones', 'Cerrar', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-error-snackbar']
        });
      }
    });
  }

  // Helper method to add messages to the chat
  addMessage(role: 'user' | 'assistant', message: string) {
    this.chatMessages.push({
      role,
      message
    });
    // Force view update
    this.cdr.detectChanges();
    
    // Auto-scroll to the bottom of chat container
    setTimeout(() => {
      const chatContainer = document.querySelector('.prompt-chat-container');
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }, 100);
  }

  openYoutubeVideoModal(): void {
    this.dialog.open(YoutubeVideoModalComponent, {
      width: '800px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: 'youtube-video-modal',
      data: {
        videoUrl: 'https://player.vimeo.com/video/1071365493'
      }
    });
  }
}