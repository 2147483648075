import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/api.service';
import { AppService } from '../app.service';
import { ColumnConfigModalComponent } from '../column-config-modal/column-config-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-whatsapp-api-config',
  templateUrl: './whatsapp-api-config.component.html',
  styleUrls: ['./whatsapp-api-config.component.scss']
})
export class WhatsappApiConfigComponent implements OnInit {
  form: any;
  intelligenceLevel = 0;
  loading = false;

  @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;

  constructor(
    private api: ApiService,
    public app: AppService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<WhatsappApiConfigComponent>,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.updateIntelligenceScore();
  }

  initializeForm() {
    this.form = {
      id: this.app.appConfig.id,
      name: this.app.appConfig.name || '',
      instructions: this.app.appConfig.instructions,
      randomAudios: this.app.appConfig.randomAudios,
      followUp: !!this.app.appConfig.followUpInstructions,
      followUpInstructions: this.app.appConfig.followUpInstructions || '',
      respondWithAudio: !!this.app.appConfig.voiceId,
      manual: !this.app.appConfig.manual,
      voiceId: this.app.appConfig.voiceId ? Number(this.app.appConfig.voiceId) : null,
      test: false // Include test if applicable
    };
  }

  selectVoice(voiceId: string) {
    if (!this.form.respondWithAudio) {
      return;
    }
    this.form.voiceId = Number(voiceId);
    this.playAudio(voiceId);
  }

  playAudio(voiceId: string) {
    const audioElement = this.audioPlayer.nativeElement;
    audioElement.src = `assets/vocals/${voiceId}.mp3`;
    audioElement.volume = 0.3;
    audioElement.play();
  }

  updateIntelligenceScore() {
    let score = 0;
    const recommendedDescriptionLength = 650;
    const descriptionLength = this.form.instructions?.length;
    if (descriptionLength > 0) {
      score += Math.min(80, Math.floor((descriptionLength / recommendedDescriptionLength) * 80));
    }
    if (this.form.followUp) {
      score += 10;
    }
    if (this.form.randomAudios) {
      score += 5;
    }
    if (this.form.respondWithAudio) {
      score += 5;
    }

    this.intelligenceLevel = Math.min(score, 100);
  }

  saveConfig() {
    this.loading = true;
  
    // Update follow-up instructions
    if (this.form.followUp) {
      this.form.followUpInstructions = "Your follow-up instructions...";
    } else {
      this.form.followUpInstructions = "";
    }
  
    // Update voiceId and randomAudios
    if (!this.form.respondWithAudio) {
      this.form.voiceId = null;
      this.form.randomAudios = false;
    } else {
      this.form.voiceId = this.form.voiceId ? Number(this.form.voiceId) : null;
    }
  
    // Update appConfig
    this.app.appConfig.instructions = this.form.instructions;
    this.app.appConfig.randomAudios = this.form.randomAudios;
    this.app.appConfig.followUpInstructions = this.form.followUpInstructions;
    this.app.appConfig.voiceId = this.form.voiceId;
    this.app.appConfig.manual = !this.form.manual;
  
    // Build the request object
    const updateBotRequest = {
      id: this.form.id,
      name: this.form.name || '',
      instructions: this.form.instructions,
      followUpInstructions: this.form.followUpInstructions,
      voiceId: this.form.voiceId,
      manual: this.form.manual,
      randomAudios: this.form.randomAudios,
      test: this.form.test || false,
    };
  
    this.api.sendPostRequest({
      action: 'Action/UpdateBotAPI',
      body: updateBotRequest
    }).subscribe({
      next: (res) => {
        this.loading = false;
        this.snackBar.open('Configuración guardada correctamente', 'Cerrar', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-snackbar']
        });
      },
      error: (error) => {
        console.error('Failed to update', error);
        console.error('Validation Errors:', error.error.errors);
        this.loading = false;
      }
    });
  }  

  updateBotAPI() {
    this.loading = true;

    // Update follow-up instructions
    if (this.form.followUp) {
      this.form.followUpInstructions = "Your follow-up instructions...";
    } else {
      this.form.followUpInstructions = "";
    }

    // Update voiceId and randomAudios
    if (!this.form.respondWithAudio) {
      this.form.voiceId = null;
      this.form.randomAudios = false;
    } else {
      this.form.voiceId = this.form.voiceId ? Number(this.form.voiceId) : null;
    }

    // Update appConfig
    this.app.appConfig.instructions = this.form.instructions;
    this.app.appConfig.randomAudios = this.form.randomAudios;
    this.app.appConfig.followUpInstructions = this.form.followUpInstructions;
    this.app.appConfig.voiceId = this.form.voiceId;
    this.app.appConfig.manual = !this.form.manual;

    // Build the request object
    const updateBotRequest = {
      id: this.form.id,
      name: this.form.name || '',
      instructions: this.form.instructions,
      followUpInstructions: this.form.followUpInstructions,
      voiceId: this.form.voiceId,
      manual: this.form.manual,
      randomAudios: this.form.randomAudios,
      test: this.form.test || false,
    };

    this.api.sendPostRequest({
      action: 'Action/UpdateBotAPI',
      body: updateBotRequest
    }).subscribe({
      next: (res) => {
        this.getColumnSuggestionsAndOpenModal();
      },
      error: (error) => {
        console.error('Failed to update', error);
        console.error('Validation Errors:', error.error.errors);
        this.loading = false;
        alert('Error al actualizar la configuración');
      }
    });
  }

  getColumnSuggestionsAndOpenModal() {
    this.api.sendPostRequest({
      action: 'Action/GetColumnSuggestions',
      body: { description: this.form.instructions }
    }).subscribe({
      next: (res) => {
        this.dialogRef.close();
        setTimeout(() => {
          this.openColumnConfigModal(res);
        }, 300);
      },
      error: (error) => {
        console.error('Failed to update', error);
        this.loading = false;
        alert('Error al actualizar la configuración');
      }
    });
  }

  openColumnConfigModal(columnSuggestions: any) {
    this.dialog.open(ColumnConfigModalComponent, {
      width: '400px',
      data: { columnSuggestions: columnSuggestions }
    });
  }

  onManualCheckboxChange() {
    // Update follow-up instructions
    if (this.form.followUp) {
      this.form.followUpInstructions = "Your follow-up instructions...";
    } else {
      this.form.followUpInstructions = "";
    }

    // Update voiceId and randomAudios
    if (!this.form.respondWithAudio) {
      this.form.voiceId = null;
      this.form.randomAudios = false;
    } else {
      this.form.voiceId = this.form.voiceId ? Number(this.form.voiceId) : null;
    }

    // Update appConfig
    this.app.appConfig.instructions = this.form.instructions;
    this.app.appConfig.randomAudios = this.form.randomAudios;
    this.app.appConfig.followUpInstructions = this.form.followUpInstructions;
    this.app.appConfig.voiceId = this.form.voiceId;
    this.app.appConfig.manual = !this.form.manual;

    // Build the request object
    const updateBotRequest = {
      id: this.form.id,
      name: this.form.name || '',
      instructions: this.form.instructions,
      followUpInstructions: this.form.followUpInstructions,
      voiceId: this.form.voiceId,
      manual: this.form.manual,
      randomAudios: this.form.randomAudios,
      test: this.form.test || false,
    };

    this.api.sendPostRequest({
      action: 'Action/UpdateBotAPI',
      body: updateBotRequest
    }).subscribe({
      next: (res) => {
        console.log('Updated bot successfully', res);
      },
      error: (error) => {
        console.error('Failed to update', error);
        console.error('Validation Errors:', error.error.errors);
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}